import React, { useEffect } from 'react';
import { Container, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import { InvoiceInfo } from './InvoiceInfo';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { VendorInvoice } from './VendorInvoice';
import { loadInvoice } from "./invoicesSlice";

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    text: {
        color: theme.typography.color
    },
    whiteText: {
        color: theme.colors.white
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold
    },
    cardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white,
    }
}))

function VendorInvoiceDetail() {
    const { invoiceId } = useParams();
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(loadInvoice({ invoiceId }));
    }, [dispatch, invoiceId]);

    const classes = useStyles();
    const invoice = useSelector(state => state.invoices.invoiceDetail.invoice);
    const details = useSelector(state => state.invoices.invoiceDetail.details);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <InvoiceInfo invoice={invoice}/>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Card variant="outlined">
                        <CardHeader title="Invoice Details" className={classes.header}></CardHeader>
                        <CardContent>
                            <VendorInvoice invoiceId={invoiceId} invoiceDetails={details} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export { VendorInvoiceDetail };