import React, {useState} from 'react';
import {Grid, Typography, Link, Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    text: {
        color: theme.typography.color
    },
    label: {
        marginTop: '10px'
    }
}));

function PanelItem({ label, subLabel, value, value2, fullWidth, isPhone, children, hasToolTip }) {
    const [isToolTipOpen, setIsToolTipOpen] = useState(false);
    const classes = useStyles();
    const outerGridProps = fullWidth
        ? { xs: 12 }
        : { xs: 6, sm: 6, md: 12, lg: 6 };

    let content;
    if (value) {
        content = isPhone ? <Link to={`tel:${value}`}>{value}</Link> : value;
    }
    else if (children) {
        content = children;
    }
    else {
        content = <span>&nbsp;</span>;
    }

    const handleToolTipOpen = () => {
        setIsToolTipOpen(true);
    }

    const handleToolTipClose = () => {
        setIsToolTipOpen(false);
    }

    return (
        <Grid item {...outerGridProps}>
            <Grid item className={classes.label}>
                <Typography variant="body1">
                    {label}
                    { subLabel && <em>&nbsp;{subLabel}</em> }
                </Typography>
            </Grid>
            <Grid item>
                {hasToolTip ?
                    <>
                    <Tooltip open={isToolTipOpen} title={content} onClick={handleToolTipOpen} onClose={handleToolTipClose}>
                        <Typography className={classes.text} variant="body1" noWrap={hasToolTip}>
                            {content}
                        </Typography>
                    </Tooltip>
                    {value2 ?
                        <Typography className={classes.text} variant="body1">
                            {value2}
                        </Typography>
                        :
                        null
                    }
                    </>
                    :
                    <>
                    <Typography className={classes.text} variant="body1" noWrap={hasToolTip}>
                        {content}
                    </Typography>
                        {value2 ?
                            <Typography className={classes.text} variant="body1">
                                {value2}
                            </Typography>
                            :
                            null
                        }
                    </>
                }
            </Grid>
        </Grid>
    );
}

export {
    PanelItem
};