import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails, 
    CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { DataTable } from 'common';
import { Attachments } from 'attachment/Attachments';
import { loadReferralDetails, actions as detailActions } from 'referral/referralDetailsSlice';
import { toSentenceCase } from 'common/utilities';
const { setPage, setLimit, setSort } = detailActions;

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    text: {
        color: theme.typography.color
    },
    attachment: {
        color: theme.typography.color
    },
    loading: {
        margin: 'auto'
    },
    cardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white,
    },
    upload: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '80px',
        color: '#505050',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderStyle: 'dashed'
    },
}));

const DETAIL_COLUMNS = [
    { Header: 'Status', accessor: 'status', width: '10%', Cell: cell => {
        switch (cell.value) {
            case 'Intake':
                return 'Received';
            case 'Confirmation':
                return 'Staffed';
            default:
                return cell.value;
        }
    }},
    { Header: 'Authorization', accessor: 'authStatus', width: '10%', Cell: cell => {
        const { authStatus } = cell.row.original;
        return toSentenceCase(authStatus);
    }},
    { Header: 'Qty', accessor: 'quantity', width: '10%' },
    { Header: 'Price Ea', accessor: 'priceEach', width: '15%', Cell: cell => cell.row.original.priceEach ? `$${cell.row.original.priceEach.toFixed(2)}` : '' },
    { Header: 'Description', accessor: 'description', width: '45%', Cell: cell => {
        const { hcpcs, description } = cell.row.original;
        return !!hcpcs ? `${hcpcs} ${description}` : description;
    }},
    { Header: 'Date', accessor: 'sortDate', width: '10%', Cell: cell => {
        const { authStartDate, authEndDate, dateOfService, anticipatedDeliveryDate } = cell.row.original;
        if (authStartDate && authEndDate) return `${moment(authStartDate).format('L')}-${moment(authEndDate).format('L')}`;
        if (dateOfService) return moment(dateOfService).format('L');
        if (anticipatedDeliveryDate) return moment(anticipatedDeliveryDate).format('L');
        return '';
    }}
];



export const ClaimReferrals = ({ referrals }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const detailsByReferral = useSelector(state => state.referralDetails.detailsByReferral);
    const referralsLoading = useSelector(state => state.claims.claimDetail.referralsLoading);
    
    const maybeLoadDetails = (referral) => (e, expanded) => {
        if (expanded) {
            const { id } = referral;
            if (!detailsByReferral[id].details) {
                dispatch(loadReferralDetails(id));
            }
        }
    }

    const buildDetails = (referralId) => {

        if (detailsByReferral[referralId]) {
            const { 
                details, loading, total, page, limit, sort 
            } = detailsByReferral[referralId];
    
            const onPageChange = (page) => {        
                dispatch(setPage({ referralId, page: page + 1 }));
                dispatch(loadReferralDetails(referralId));
            }
    
            const onRowsPerPageChange = (event) => {
                const rowsPerPage = parseInt(event.target.value);
                dispatch(setLimit({ referralId, limit: rowsPerPage }));
                dispatch(setPage({ referralId, page: 1 }));
                dispatch(loadReferralDetails(referralId));
            };
        
            const onSortChange = (property) => {
                const direction = property === sort.property 
                    ? sort.direction === 'asc' 
                        ? 'desc'
                        : 'asc' 
                    :'asc';
                const newSort = { property, direction };
                dispatch(setSort({ referralId, sort: newSort }));
                dispatch(setPage({ referralId, page: 1 }));
                dispatch(loadReferralDetails(referralId));
            };
    
            return (
                <Grid item xs={12}>
                    <DataTable
                        loading = {loading}
                        hideLoadingIndicator = {true}
                        columns = {DETAIL_COLUMNS}
                        data = {details}
                        totalCount = {total}
                        currentPage={page}
                        onPageChange={onPageChange}
                        rowsPerPage = {limit}
                        rowsPerPageOptions = {[10,20,50]}
                        sort = {sort}
                        onSortChange = {onSortChange}
                        sortBy = {sort.property}
                        sortDirection = {sort.direction}
                        labelRowsPerPage = "Details per page"
                        onRowsPerPageChange = {onRowsPerPageChange}
                    />
                </Grid>
            );
        } else {
            return <></>;
        }
        
    }

    useEffect(() => {
        if (referrals && referrals.length) {
            let i = 0; // load details for up to 2 referrals on load
            for (i = 0; i < referrals.length && i < 2; ++i) {
                dispatch(loadReferralDetails(referrals[i].id));
            }
        }
    }, [referrals, dispatch]);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));           

    // auto-expand up to 2 referrals
    let expandedCount = 0;

    return (
        <>
        { referralsLoading && <Grid container style={{justifyContent: 'center'}}>
            <Grid item>
                <CircularProgress style={{marginTop:'25px'}}/>
            </Grid>
        </Grid> }
        {!referralsLoading && referrals && referrals.map(referral => {
            return (
                <Accordion key={`Referral.${referral.id}`} item={referral} defaultExpanded={++expandedCount <= 2} onChange={maybeLoadDetails(referral)} style={{backgroundColor: '#f7f7f7'}}>
                    <AccordionSummary 
                            tabIndex = "-1"
                            expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                            className = {classes.text}>
                        <Typography variant="h6" style={{width:'100%', display:'flex', flexWrap: 'wrap', justifyContent:'space-between'}}>
                            <div style={{fontWeight: 'bold'}} >R{referral.id}</div>
                            <div>{ isXs && referral.service === 'Home Health' ? 'HH' : referral.service}</div>
                            <div>{moment(referral.maxModifiedDate).format(isXs ? "M/DD/YY" : "M/DD/YYYY h:mma")}</div>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            { buildDetails(referral.id) }
                            
                            <Attachments entityName='Referral' entityId={referral.id} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>);
        })}
        </>
    )
}