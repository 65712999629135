import store from 'store';
import { actions } from 'appSlice';
import { appConfig } from 'config.js';
let jwt = false;

async function doFetch({ path, method, params, doAuthRedirect = true, showErrorMsg = true }) {
    const headers = {
        'Accept': 'application/json'
    };
    if (jwt) {
        headers['Authorization'] = `Bearer ${jwt}`;
    }
    if (params) {
        headers['Content-Type'] =  'application/json';
    }
    path = method === 'POST' ? path : `${path}?${buildQueryString(params)}`;
    
    const requestConfig = { method, credentials: 'same-origin', headers };
    if (method === 'POST') {
        requestConfig.body = params ? JSON.stringify(params) : '';
    };

    let response
    if(!window.location.hostname.includes('homecareconnect')&&!window.location.hostname.includes('localhost')) {
        const url = appConfig.externalURL + `/carelink/${path}`
        response = await fetch(url, requestConfig);
    }else{
        response = await fetch(`/carelink/${path}`, requestConfig);
    }

    if (response.status === 401 && doAuthRedirect) {
        window.location.href = '/auth/login';
        return;
    }

    let data = false;
    const contentType = response.headers.get('content-type') || '';
    if (contentType.startsWith('application/json')) {
        try {
            data = await response.json();

            if (!response.ok && showErrorMsg) {
                if (Math.floor(response.status / 100) === 4) {
                    store.dispatch(actions.clientErrorMessage(data.message));
                }
                else {
                    store.dispatch(actions.serverErrorMessage());
                }
            }
        } catch (err) {
            console.error('Error parsing response', err);
            if (showErrorMsg)
                store.dispatch(actions.serverErrorMessage());
        }
    } 
    return {
        response,
        data
    };
}

export async function doGet(props) {
    return doFetch({ method: 'GET', ...props });
}

export async function doPost(props) {
    return doFetch({ method: 'POST', ...props });
}

export function getAuthHeaders() {
    if (jwt) {
        return {
            'Authorization': `Bearer ${jwt}`
        };
    }
    
    return {};
}

export function setToken(token) {
    jwt = token;
}

function buildQueryString(params) {
    if (!params) {
        return '';
    }
    return Object.keys(params).reduce((query, field) => {
        if (params[field]) {
            query.push(`${field}=${encodeURIComponent(params[field])}`);
        }
        
        return query;
    }, []).join('&');
}