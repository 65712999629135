import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { doPost, doGet, setToken} from '../common/fetch';

function getPayload(token) {
    return JSON.parse(atob(token.split('.')[1]));
}

function refreshBefore(exp) {
    exp = exp * 1000; // convert to ms
    const now = new Date().getTime();
    const msToExpire = (exp - now);
    const msToRefresh = Math.floor(msToExpire * 0.8);
    setTimeout(async () => {
        const { data, response } = await doPost({
            path: 'auth/refresh',
            params: {
                username: localStorage.getItem('username')
            }
        });
        if (response.ok) {
            const { token } = data;
            const payload = getPayload(token);
            refreshBefore(payload.exp);
            setToken(token);
        } 
    }, msToRefresh);
}

export const reload = createAsyncThunk(
    'auth/reload',
    async (_, { rejectWithValue }) => {
        const { data, response } = await doPost({
            path: 'auth/refresh',
            params: {
                username: localStorage.getItem('username')
            },
            doAuthRedirect: false
        });
        if (response.ok) {
            const { token } = data;
            const { exp, sub, userType, permissions, requirePrivacyPolicy } = getPayload(token);
            refreshBefore(exp);
            setToken(token);
            return { userType, contactEntityId: sub, permissions, requirePrivacyPolicy };
        } 
        else {
            window.location.href = `/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`;
        }
        return rejectWithValue(false);
    }
);

export const ssoCheck = createAsyncThunk(
    'auth/ssoCheck',
    async ( { username }, { rejectWithValue } ) => {
        const { data, response } = await doPost({
            path: 'auth/ssoCheck',
            params: {
                username
            },
            doAuthRedirect: false
        });
        if (response.ok) {
            localStorage.setItem('username', username);
            const { redirect } = data;
            if (redirect) {
                window.location.href = redirect;
                return { passwordRequired: false, pending: true }
            } else {
                return { passwordRequired: true, pending: false };
            }
        } 
        
        return rejectWithValue('Login error');
    }
);

export const login = createAsyncThunk(
    'auth/login',
    async ( { username, password, verificationCode, vctoken }, { rejectWithValue } ) => {
        const { data, response } = await doPost({
            path: 'auth/login',
            params: {
                username,
                password,
                verificationCode,
                vctoken
            },
            doAuthRedirect: false
        });
        
        if (response.ok) {
            const { token } = data;
            localStorage.setItem('username', username);
            const { exp, sub, userType, permissions, requirePrivacyPolicy } = getPayload(token);
            refreshBefore(exp);
            setToken(token);
            return { userType, contactEntityId: sub, permissions, requirePrivacyPolicy };
        } else if (data && data.message) 
            return rejectWithValue(data.message);
        else if(data && data.verificationCodeRequired)
            return rejectWithValue({verificationCodeRequired: data.verificationCodeRequired, vctoken: data.vctoken });
        
        return rejectWithValue('Invalid credentials');
    }
);

export const signup = createAsyncThunk(
    'auth/signup',
    async (values, {rejectWithValue}) => {
        const {data, response} = await doPost({
            path: 'auth/signup',
            params: values,
            doAuthRedirect: false
        });

        if (response.ok) {
            const { success, message } = data;
            return success ? message : rejectWithValue(message);
        } else {
            return rejectWithValue("An error occurred");
        }
    }
)

export const reset = createAsyncThunk(
    'auth/reset',
    async ( {username, ...rest}, { rejectWithValue } ) => {
        const { data, response } = await doPost({
            path: 'auth/reset',
            params: {
                username,
                ...rest
            },
            doAuthRedirect: false,
            showErrorMsg: false
        });
      
        if (response.ok) {
            const { token } = data;
            localStorage.setItem('username', username);
            const { exp, sub, userType, permissions, requirePrivacyPolicy } = getPayload(token);
            refreshBefore(exp);
            setToken(token);
            return { userType, contactEntityId: sub, permissions, requirePrivacyPolicy };
        } else if (data && data.message) {
            return rejectWithValue(data.message);
        } 
        
        setToken(false);
        return rejectWithValue('Invalid credentials');
    }
);

export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async username => {
        await doPost({
            path: 'auth/forgotPassword',
            params: {
                username
            }
        });
        return true;
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        const { response } = await doPost({
            path: 'auth/logout',
            params: {
                username: localStorage.getItem('username')
            }
        });
        if (response.ok) {
            localStorage.removeItem('username');
            setToken(false);
            window.location.href = '/auth/login';
        }
    }
);

export const loadVendorUserRoles = createAsyncThunk(
    'auth/loadVendorUserRoles',
    async () => {
        const { response, data } = await doGet({
            path: 'auth/loadVendorUserRoles'
        });

        if (response.ok) {
            return data;
        }
    }
);

export const loadCustomerUserRoles = createAsyncThunk(
    'auth/loadCustomerUserRoles',
    async () => {
        const { response, data } = await doGet({
            path: 'auth/loadCustomerUserRoles'
        });

        if (response.ok) {
            return data;
        }
    }
)

export const loadPrivacyPolicy = createAsyncThunk(
    'privacyPolicy/loadPrivacyPolicy',
    async () => {
        const {data, response} = await doGet({
            path: 'auth/loadPrivacyPolicy'
        });
        if (response.ok) {
            const {content, createdDate} = data;
            return {content, createdDate};
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        pending: true,
        authorized: false,
        permissions: [],
        error: false,
        forms: {
            forgot: {
                pending: false,
                complete: false
            }
        },
        loadingVendorUserRoles: false,
        vendorUserRoles: [],
        loadingCustomerUserRoles: false,
        customerUserRoles: [],
        verificationCodeRequired: false,
        verificationCodeToken: null,
        passwordRequired: false,
        privacyPolicyLoading: true,
        privacyPolicyContent: null,
        privacyPolicyDate: null,
        requirePrivacyPolicy: false
    },
    reducers: {
        resetForgotForm: state => {
            state.forms.forgot = {
                pending: false,
                complete: false
            }
        },
        clearSignUpMessage: state => {
            state.signUpSuccessMsg = undefined;
            state.signUpFailMsg = undefined;
        },
        setAuthToken: (state, action) => {
            const token = action.payload;
            setToken(token);
            const { sub, userType, permissions, requirePrivacyPolicy } = getPayload(token);
            state.pending = false;
            state.authorized = true;
            state.userType = userType;
            state.contactEntityId = sub;
            state.permissions = permissions;
            state.requirePrivacyPolicy = requirePrivacyPolicy;
        },
        setAuthPending: (state, action) => {
            state.pending = action.payload;
        },
        setAuthError: (state, action) => {
            state.error = action.payload;
        },
        setRequirePrivacyPolicy: (state, action) => {
            state.requirePrivacyPolicy = action.payload;
        }
    },
    extraReducers: {
        [reload.fulfilled]: (state, action) => {
            state.pending = false;
            state.authorized = true;
            state.userType = action.payload.userType;
            state.permissions = action.payload.permissions;
            state.contactEntityId = action.payload.contactEntityId;
            state.requirePrivacyPolicy = action.payload.requirePrivacyPolicy;
        },
        [reload.rejected]: state => {
            console.log('reload rejected');
            state.pending = false;
            state.authorized = false;
        },
        [ssoCheck.pending]: state => {
            state.pending = true;
            state.error = false;
        },
        [ssoCheck.fulfilled]: (state, action) => {
            state.authorized = false;
            state.pending = action.payload.pending;
            state.passwordRequired = action.payload.passwordRequired;
        },
        [ssoCheck.rejected]: (state, action) => {
            state.pending = false;
            state.authorized = false;
            state.error = action.payload;
        },
        [login.pending]: state => {
            state.pending = true;
            state.error = false;
        },
        [login.fulfilled]: (state, action) => {
            state.pending = false;
            state.authorized = true;
            state.userType = action.payload.userType
            state.permissions = action.payload.permissions;
            state.contactEntityId = action.payload.contactEntityId;
            state.requirePrivacyPolicy = action.payload.requirePrivacyPolicy;
            state.verificationCodeRequired = false; 
            state.verificationCodeToken = null;
        },
        [login.rejected]: (state, action) => {
            state.pending = false;
            state.authorized = false;
            if (action.payload.verificationCodeRequired){
                state.verificationCodeRequired = true;                 
                state.error = action.payload.verificationCodeRequired;
                state.verificationCodeToken = action.payload.vctoken;
            } else {
                state.error = action.payload;
                state.passwordRequired = false;
            }
        },
        [signup.pending]: state => {
            state.pending = true;
            state.signUpSuccessMsg = undefined;
            state.signUpFailMsg = undefined;
            state.error = false;
        },
        [signup.fulfilled]: (state, action) => {
            state.pending = false;
            state.signUpSuccessMsg = action.payload;
        },
        [signup.rejected]: (state, action) => {
            state.pending = false;
            state.signUpFailMsg = action.payload;
        },
        [logout.pending]: state => {
            state.pending = true;
            state.authorized = false;
            state.userType = null;
            state.permissions = [];
        },
        [logout.fulfilled]: state => {
            state.pending = false;
        },
        [logout.rejected]: state => {
            state.pending = false;
        },
        [reset.pending]: state => {
            state.pending = true;
            state.error = false;
        },
        [reset.fulfilled]: (state, action) => {
            state.pending = false;
            state.authorized = true;
            state.userType = action.payload.userType;
            state.permissions = action.payload.permissions;
            state.contactEntityId = action.payload.contactEntityId;
            state.requirePrivacyPolicy = action.payload.requirePrivacyPolicy;
        },
        [reset.rejected]: (state, action) => {
            state.pending = false;
            state.authorized = false;
            state.error = action.payload;
        },
        [forgotPassword.pending]: state => {
            state.error = false;
            state.forms.forgot.pending = true;
            state.forms.forgot.complete = false;
        },
        [forgotPassword.fulfilled]: state => {
            state.forms.forgot.pending = false;
            state.forms.forgot.complete = true;
        },
        [loadVendorUserRoles.pending]: state => {
            state.loadingVendorUserRoles = true;
            state.vendorUserRoles = [];
        },
        [loadVendorUserRoles.fulfilled]: (state, action) => {
            state.loadingVendorUserRoles = false;
            state.vendorUserRoles = action.payload;
        },
        [loadVendorUserRoles.rejected]: state => {
            state.loadingVendorUserRoles = false;
        },
        [loadCustomerUserRoles.pending]: state => {
            state.loadingCustomerUserRoles = true;
            state.customerUserRoles = [];
        },
        [loadCustomerUserRoles.fulfilled]: (state, action) => {
            state.loadingCustomerUserRoles = false;
            state.customerUserRoles = action.payload;
        },
        [loadCustomerUserRoles.rejected]: state => {
            state.loadingCustomerUserRoles = false;
        },
        [loadPrivacyPolicy.pending]: state => {
            state.privacyPolicyLoading = true;
        },
        [loadPrivacyPolicy.fulfilled]: (state, action) => {
            state.privacyPolicyLoading = false;
            state.privacyPolicyContent = action.payload.content;
            const date = new Date(action.payload.createdDate);
            state.privacyPolicyDate = date.toDateString().substring(3);
        },
        [loadPrivacyPolicy.rejected]: state => {
            state.privacyPolicyLoading = false;
        }
    }
});

export const { actions, reducer } = authSlice;
export default reducer;