import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Button, RadioGroup, Radio, 
    FormControlLabel, Typography, Container, Backdrop, 
    CircularProgress, Paper, RootRef, Accordion, AccordionSummary, AccordionDetails, 
    Checkbox, List, ListItem, ListItemIcon, ListItemText, InputAdornment, IconButton, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/styles';
import InputMask from 'react-input-mask';
import { useDropzone } from 'react-dropzone'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import { getAuthHeaders } from 'common/fetch';
import { saveACH, loadACH, uploadCheckImage, getCheckImageURL, signACH, actions as achActions } from 'vendor/achSlice';
import { stripMask, isLength, isName, isEmail } from 'common/validate';
import { CenterCircularProgress, PanelItem } from 'common';
import { actions as appActions } from 'appSlice';

const { setACHForm, setStepOneExpanded, setStepTwoExpanded, setStepThreeExpanded } = achActions;
const { infoMessage } = appActions;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles(theme => ({
    radioGroup: {
        color: '#76787A',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    textField: {
        width: '100%'
    },
    btn: {
        backgroundColor: '#3892d3',
        '&:hover': {
            backgroundColor: '#91c2e6'
        }
    },
    cardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    expandIcon: {
        color: theme.colors.white
    },
    accordion: {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px'
    },
    card: {
        padding: '15px',
        marginBottom: '20px'
    },
    upload: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '80px',
        color: '#505050',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderStyle: 'dashed'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100%',
        minHeight: '85px'
    },
    image: {
        maxWidth: '75%'
    },
    accordionDetails: {
        justifyContent: 'center'
    }
}));

function FileDropZone() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        error, 
        read: { pending: readPending, file }, 
        upload: { pending: uploadPending }} 
            = useSelector(state => state.ach.checkImage);

    const accept = [
        'image/tiff',
        'image/png',
        'image/jpeg',
        'image/bmp',
        'image/gif',
        'application/pdf'
    ];

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept,
        maxFiles: 1,
        onDrop: dropFiles => {
            dropFiles.forEach(file => dispatch(uploadCheckImage(file)));
        }
    });
    const {ref, ...rootProps} = getRootProps();

    const imageComponent = (
        <>
            <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}} key={file.id}>
                <Typography variant="body1">
                    {file.name} - {file.size}
                </Typography>
            </div>
            {file.contentType === 'application/pdf' &&
                <div style={{display: 'flex', justifyContent: 'center' }}>
                    <Document  file={file.data} loading={<CircularProgress />}>
                        <Page width={400} pageNumber={1} />
                    </Document>
                </div>}
            {file.contentType !== 'application/pdf' && 
                <div className={classes.imageContainer} >
                    <img className={classes.image} src={file.data} alt="Cancelled Check" />
                </div>}
        </>
    );

    const uploadArea = (
        <Paper {...rootProps} variant="outlined" className={classes.upload}>
            {(readPending && !file.data ? 
                <CircularProgress /> : 
                <div>
                    {uploadPending && 
                        <Typography variant="h6" style={{padding: '10px'}}>
                            Please wait while your image is uploaded
                        </Typography>
                    }
                    {!uploadPending && !file.data && (!isDragActive || !isDragReject) && 
                        <Typography variant="h6" style={{padding: '10px'}}>
                            Drag image or PDF here, or press to select
                        </Typography>}
                    {isDragActive && isDragReject && 
                        <Typography variant="h6" style={{padding: '10px'}}>
                            Only PDF files and Image files accepted
                        </Typography>}
                </div>
            )}
        </Paper>
    );

    return (
        <>
            <RootRef rootRef={ref}>
                <Grid container>
                    <input {...getInputProps()} />
                    {file.data && 
                        <Grid item xs={12}>
                            {imageComponent}
                        </Grid>}
                    {!file.data && 
                        <Grid item xs={12}>
                            {uploadArea}
                        </Grid>}
                </Grid>
            </RootRef>
            {error && 
                <div style={{width: '100%', marginBottom: '5px'}} key={error}>
                    <Alert severity="warning">{error}</Alert>
                </div>}
        </>
    );
};

function CheckImage() {
    const { url, pending, isImage, isPDF, attachmentId, error } = useSelector(state => state.ach.checkImage.url);
    const { success } = useSelector(state => state.ach.checkImage.upload);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (success) {
            dispatch(infoMessage('Upload successful'));
        }
    }, [success, dispatch]);

    const classes = useStyles();
    if (error) {
        return (
            <div className={classes.imageContainer}>    
                <ErrorIcon />
                <Typography variant="body1">
                    {error}
                </Typography>
            </div>
        );
    }
    if (pending) {
        return <CenterCircularProgress />;
    }
    if (!pending && url) {
        
        if (isImage) {
            return (
                <div className={classes.imageContainer} >
                    <img className={classes.image} alt="Cancelled Check" src={url} />
                </div>
            );
        }
        else if (isPDF) {
            return (
                <div className={classes.imageContainer} >
                    <Document file={{ url: `/carelink/attachment/streamFile?attachmentId=${attachmentId}`, httpHeaders: getAuthHeaders() }} loading={<CircularProgress />}>
                        <Page width={400} pageNumber={1} />
                    </Document>
                </div>
            );
        }
    }

    return <FileDropZone />;
}

function ACHInfo({ ach: { contactName, contactPhone, contactEmail, federalTaxId, remittanceEmail, remittanceFax, bankName, accountType, routingNumber, accountNumber }, isProcessing }) {
    if (contactPhone && contactPhone.length === 10) contactPhone = `(${contactPhone.substring(0, 3)}) ${contactPhone.substring(3, 6)}-${contactPhone.substring(6)}`;
    if (remittanceFax && remittanceFax.length === 10) remittanceFax = `(${remittanceFax.substring(0, 3)}) ${remittanceFax.substring(3, 6)}-${remittanceFax.substring(6)}`;
    if (federalTaxId && federalTaxId.length === 9) federalTaxId = `${federalTaxId.substring(0, 2)}-${federalTaxId.substring(2)}`;
    
    return (
        <Grid container spacing={2}>
            <PanelItem label="Contact Name" value={contactName} />
            <PanelItem label="Federal Tax ID" value={federalTaxId} />
            <PanelItem label="Contact Email" value={contactEmail} />
            <PanelItem label="Contact Phone"  value={contactPhone} />
            <PanelItem label="Remittance Email" value={remittanceEmail} />
            <PanelItem label="Remittance Fax"  value={remittanceFax} />
            <PanelItem label="Bank Name" value={bankName} />
            <PanelItem label="Account Type" value={accountType} />
            <PanelItem label="Routing Number" value={routingNumber} />
            <PanelItem label="Account Number" value={accountNumber} />
        </Grid>
    );
}

function ACHForm() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showCheckExample, setShowCheckExample] = useState(false);
    const toggleCheckExample = () => setShowCheckExample(!showCheckExample);
    
    const { ach } = useSelector(state => state.ach.forms);

    const {
        handleSubmit, register, control, errors, getValues  
    } = useForm({
        defaultValues: ach,
    });

    const { pending: savePending } = useSelector(state => state.ach.save);
    
    const onSubmit = (data, e) => {
        data.federalTaxId = data.federalTaxId.replace(/\D/g, '');
        data.contactPhone = data.contactPhone.replace(/\D/g, '');
        data.remittanceFax = data.remittanceFax ? data.remittanceFax.replace(/\D/g, '') : '';
        dispatch(setACHForm(data))
        dispatch(saveACH())

    };

    const hasRemittance = () => {
        const { remittanceFax, remittanceEmail } = getValues();
        if (!remittanceFax && !remittanceEmail) {
            return 'remittance email or fax required';
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className = {classes.textField}
                        name = "contactName"
                        label = "Contact Name"
                        error = {!!errors.contactName}
                        helperText = {errors.contactName ? errors.contactName.message : ''}
                        inputRef = {register({
                            required: 'required',
                            validate: isName
                        })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller 
                        control = {control}
                        name = "contactPhone"
                        as = {
                            <InputMask mask="(999) 999-9999" maskChar=" " >
                                {props => (
                                    <TextField
                                        className = {classes.textField}
                                        name = "contactPhone"
                                        label = "Contact Phone"
                                        error = { !!errors.contactPhone }
                                        helperText = { errors.contactPhone ? errors.contactPhone.message : '' }
                                        {...props} />
                                )}
                            </InputMask>
                        }
                        rules = {{
                            required: 'required',
                            validate: stripMask(/[ ()-]/g)(isLength(10))
                        }}
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        className = {classes.textField} 
                        name = "contactEmail"
                        label = "Contact Email"
                        error = { !!errors.contactEmail }
                        helperText = { errors.contactEmail && errors.contactEmail.message }
                        inputRef = {register({
                            required: 'required',
                            validate: isEmail
                        })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller 
                        control = {control}
                        name = "federalTaxId"
                        as = {
                            <InputMask mask="99-9999999" maskChar=" ">
                                {props => (
                                    <TextField
                                    className = {classes.textField}
                                    name = "federalTaxId"
                                    label = "Federal Tax Id"
                                    error = { !!errors.federalTaxId }
                                    helperText = { errors.federalTaxId ? errors.federalTaxId.message : '' }
                                    {...props} />
                                    )}
                            </InputMask>
                        }
                        rules = {{
                            required: 'required',
                            validate: stripMask(/\D/g)(isLength(9))
                        }}
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        className = {classes.textField} 
                        name = "remittanceEmail"
                        label = "Remittance Email" 
                        error = { !!errors.remittanceEmail }
                        helperText = { errors.remittanceEmail ? errors.remittanceEmail.message : '' }
                        inputRef = {register({
                            validate: { 
                                isEmail,
                                hasRemittance
                            }
                        })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller 
                        control = {control}
                        name = "remittanceFax"
                        defaultValue=""
                        rules = {{
                            validate: hasRemittance
                        }}
                        as = {
                            <InputMask mask="(999) 999-9999" maskChar=" " >
                                {props => (
                                    <TextField
                                        className = {classes.textField}
                                        name = "remittanceFax"
                                        label = "Remittance Fax"
                                        error = { !!errors.remittanceFax }
                                        helperText = { errors.remittanceFax ? errors.remittanceFax.message : '' }
                                        {...props} />
                                )}
                            </InputMask>
                        } />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        className = {classes.textField} 
                        name = "bankName"
                        label = "Bank Name"
                        error = { !!errors.bankName }
                        helperText = { errors.bankName && errors.bankName.message }
                        inputRef = {register({
                            required: 'required'
                        })}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        control = {control}
                        name = "accountType"
                        as = {
                            <RadioGroup row className={classes.radioGroup} aria-label="account type" name="accountType">
                                <FormControlLabel value="Checking" control={<Radio />} label="Checking" />
                                <FormControlLabel value="Savings" control={<Radio />} label="Saving" />
                            </RadioGroup>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <Controller 
                        control = {control}
                        name = "routingNumber"
                        as = {
                            <InputMask mask="999999999" maskChar=" ">
                                {props => (

                                    <TextField
                                        className = {classes.textField}
                                        name = "routingNumber"
                                        label = "Routing Number"
                                        error = { !!errors.routingNumber }
                                        InputProps = {{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={toggleCheckExample}>
                                                        <HelpIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        helperText = { errors.routingNumber ? errors.routingNumber.message : '' }
                                        {...props} />
                                )}
                            </InputMask>
                        } 
                        rules = {{
                            required: 'required',
                            validate: isLength(9)
                        }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller 
                        control = {control}
                        name = "accountNumber"
                        as = {
                            <InputMask mask="99999999999999999" maskChar=" ">
                                {props => (
                                    <TextField
                                        className = {classes.textField}
                                        name = "accountNumber"
                                        label = "Account Number"
                                        error = { !!errors.accountNumber }
                                        helperText = { errors.accountNumber ? errors.accountNumber.message : '' }
                                        InputProps = {{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={toggleCheckExample}>
                                                        <HelpIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        {...props} />
                                )}
                            </InputMask>
                        } 
                        rules = {{
                            required: 'required'
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={showCheckExample}>
                        <img alt="check example" src="//cdn.homecareconnect.com/images/check_sample.png" style={{maxWidth: '400px'}} />
                    </Collapse>
                </Grid>
                <Grid container item direction="row" justify="flex-end" xs={12}>
                    <Button
                        disabled = {savePending}
                        type = "submit"
                        className = {classes.btn} 
                        variant = "contained" 
                        color = "primary">
                        Save ACH
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

function Agreement() {
    const classes = useStyles();
    const { handleSubmit, register, errors } = useForm();
    const dispatch = useDispatch();
    const { profile, getProfilePending } = useSelector(state => state.user);
    const { firstName, lastName } = profile;

    const {
        load: { pending: loadPending, hasACH, ach },
        checkImage: { imageUploaded },
        agreement: { pending: signaturePending, success: signatureSuccess }
    } = useSelector(state => state.ach);

    const { signedBy, signed, signedDate } = !!ach ? ach : {};
    const [agreeChecked, setAgreeChecked] = useState(false);


    if (getProfilePending || loadPending) {
        return (
            <CenterCircularProgress />
        )
    }

    const readyForSignature = hasACH && imageUploaded;

    const onSubmit = () => dispatch(signACH());

    const eSignDisclaimer = `
        I acknowledge that electronic signatures appearing on this agreement are the same as 
        handwritten signatures for purposes of validity, enforceability, and admissibility.
    `;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography style={{marginTop: '10px'}} variant="body1">
                    By signing below, I agree to that the information above is true and correct.
                    I also authorize Home Care Connect to deposit money, or if necessary,
                    debit entries or adjustments for any credit entries in error into
                    the bank account provided above. This authority will remain in effect until
                    I have canceled in writing.
                </Typography>
                {signed && signedBy && signedDate &&
                    <Typography style={{marginTop: '10px', fontStyle: 'italic', display: 'flex'}} variant="body1">
                        <CheckCircleIcon style={{ color: 'green', marginRight: '3px' }} />
                        Signed by {signedBy} on {moment(signedDate).format('L')}
                    </Typography>
                }
                {signatureSuccess && 
                    <Typography style={{marginTop: '10px', display: 'flex'}} variant="body1">
                        <CheckCircleIcon style={{ color: 'green', marginRight: '3px' }} />
                        Successfully signed
                    </Typography>
                }
            </Grid>
            {!signed && !signatureSuccess && 
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    style={{marginTop: '20px'}}
                                    fullWidth
                                    label = {firstName + ' ' + lastName}
                                    name = "name"
                                    error = {errors.name}
                                    helperText = {errors.name && errors.name.message}
                                    inputRef = {register({
                                        required: 'required',
                                        validate: input => input.toLowerCase() !== `${firstName.toLowerCase()} ${lastName.toLowerCase()}` ? 'your name must be typed exactly as it appears above' : true
                                    })}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel 
                                    label={`I agree to electronically sign this document. ${eSignDisclaimer}`}
                                    className={classes.checkbox}
                                    control={
                                        <Checkbox name="agree" checked={agreeChecked} onChange={() => setAgreeChecked(!agreeChecked)} />
                                    }/>
                            </Grid>
                            <Grid item xs={12}>
                            <Button 
                                disabled = {!agreeChecked || !readyForSignature}
                                type = "submit" 
                                className = {classes.btn} 
                                variant = "contained" 
                                color = "secondary">
                                    {signaturePending ? 'Signing...' : 'Click to Sign'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>}
        </Grid>
    )
}

function Progress({hasACH, imageUploaded, achSigned, isProcessing, loading, isImported}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const checkCircleIcon = <CheckCircleIcon style={{ color: 'green' }} />;
    const todoIcon = <RadioButtonUncheckedIcon />;

    const stepOneIcon = isImported || hasACH ? checkCircleIcon : todoIcon;
    const stepTwoIcon = isImported || imageUploaded ? checkCircleIcon : todoIcon;
    const stepThreeIcon = isImported || achSigned ? checkCircleIcon : todoIcon;

    const getScroller = (id, expander) => () => { 
        dispatch(expander(true));
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }

    let statusText = '';
    if (isImported) {
        statusText = 'Complete';
    }
    else if (achSigned) {
        if (isProcessing) {
            statusText = 'Submitted';
        }
        else { 
            statusText = 'Complete';
        }
    }
    else {
        statusText = 'In Progress';
    }

    const statusIcon = statusText === 'Complete' ? 
        checkCircleIcon :
        <AccessTimeIcon />;

    return (
        <Accordion className={classes.accordion} expanded>
            <AccordionSummary
                style={{ cursor: 'default' }}
                tabIndex = "-1"
                className = {classes.cardTitle}>
                <Typography variant="h6">
                    Progress
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{justifyContent: 'center'}}>
                {loading && <CenterCircularProgress />}
                {!loading && 
                    <List component="nav">
                        <ListItem button onClick={getScroller('achInfo', setStepOneExpanded)} >
                            <ListItemIcon>
                                {stepOneIcon}
                            </ListItemIcon>
                            <ListItemText primary="Enter ACH Information" />
                        </ListItem>
                        <ListItem button onClick={getScroller('checkImage', setStepTwoExpanded)} >
                            <ListItemIcon>
                                {stepTwoIcon}
                            </ListItemIcon>
                            <ListItemText primary="Upload Check Image" />
                        </ListItem>
                        <ListItem button onClick={getScroller('agreement', setStepThreeExpanded)} >
                            <ListItemIcon>
                                {stepThreeIcon}
                            </ListItemIcon>
                            <ListItemText primary="E-Sign Document" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {statusIcon}
                            </ListItemIcon>
                            <ListItemText primary={statusText} />
                        </ListItem>
                    </List>}
            </AccordionDetails>
        </Accordion>
    );
}

export default function ACHPanel() {
    const dispatch = useDispatch();

    const {
        load: { pending: loadPending, ach, isProcessing, hasLoaded, hasACH, isImported },
        save: { pending: savePending },
        checkImage: { imageUploaded, 
            upload: { pending: uploadPending },
            url: { pending: urlPending }},
        agreement: { error: signatureError, success: signatureSuccess, pending: signaturePending, isSigned },
        expands: { stepOne: stepOneExpanded, stepTwo: stepTwoExpanded, stepThree: stepThreeExpanded }
    } = useSelector(state => state.ach);

    const classes = useStyles();

    useEffect(() => {
        if (!hasLoaded) {
            dispatch(loadACH());
            dispatch(getCheckImageURL());
        }
    }, [dispatch, hasLoaded]);

    useEffect(() => {
        if (signatureSuccess) {
            dispatch(infoMessage('ACH Successfully Signed'));
        }
    }, [dispatch, signatureError, signatureSuccess]);

    const achComponent = loadPending ?
        <CenterCircularProgress /> :
            isSigned ?
                <ACHInfo ach={ach} isProcessing={isProcessing} /> :
                <ACHForm />;

    const checkComponent =  <CheckImage />;
    const agreementComponent = <Agreement />;
    const showBackdrop = uploadPending || signaturePending || savePending;

    return (
        <>
        <Backdrop open={showBackdrop} style={{zIndex: 999, backgroundColor: 'rgba(0,0,0,0.3)'}}>
            <CircularProgress color="primary"/>
        </Backdrop>
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item md={3} sm={12} xs={12}>
                    <Progress loading={loadPending || urlPending} hasACH={hasACH} imageUploaded={imageUploaded} achSigned={signatureSuccess || (ach && ach.signed)} isProcessing={isProcessing} isImported={isImported} />
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                        <Accordion className={classes.accordion} expanded={stepOneExpanded} id="achInfo">
                            <AccordionSummary
                                tabIndex = "-1"
                                onClick = {() => dispatch(setStepOneExpanded(!stepOneExpanded))}
                                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>}
                                className = {classes.cardTitle}>
                                <Typography variant="h6">
                                    Information
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                {achComponent}
                            </AccordionDetails>
                        </Accordion>
                        { !loadPending && 
                            <>
                            {!isImported && 
                                <Accordion className={classes.accordion} expanded={stepTwoExpanded} id="checkImage">
                                    <AccordionSummary
                                        tabIndex = "-1"
                                        onClick = {() => dispatch(setStepTwoExpanded(!stepTwoExpanded))}
                                        expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>}
                                        className = {classes.cardTitle}>
                                        <Typography variant="h6">
                                            Check Image
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        {checkComponent}
                                    </AccordionDetails>
                                </Accordion>}
                            {!isImported && 
                                <Accordion className={classes.accordion} expanded={stepThreeExpanded} id="agreement">
                                    <AccordionSummary
                                        tabIndex = "-1"
                                        onClick = {() => dispatch(setStepThreeExpanded(!stepThreeExpanded))}
                                        expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>}
                                        className = {classes.cardTitle}>
                                        <Typography variant="h6">
                                            Agreement
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        {agreementComponent}
                                    </AccordionDetails>
                                </Accordion>}
                            </>}
                    </Grid>
                </Grid>
        </Container>
        </>);
}