import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery
} from "@material-ui/core";
import React, {useCallback, useState} from "react";

const useStyles = makeStyles(theme => ({
    content: {
        whiteSpace: 'pre-wrap'
    }
}))

function AgreePrivacyPolicyModal({handleClickAgree, handleClickDisagree, handleCloseModal, openModal, content, version, isLoading}) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [disableAgreeBtn, setDisableAgreeBtn] = useState(true);
    const dialog = useCallback(node => {
        if (node !== null) {
            setDisableAgreeBtn(node.clientHeight < node.scrollHeight);
        }
    }, []);

    const handleScrollEvent = (event) => {
        if (event.target.scrollTop / (event.target.scrollHeight - event.target.offsetHeight) >= 0.9) {
            setDisableAgreeBtn(false);
        }
    }

    return (
        <Dialog open={openModal} fullScreen={fullScreen} maxWidth={"md"} onClose={handleCloseModal} onScroll={handleScrollEvent}>
            {isLoading ? <DialogTitle>Loading...</DialogTitle> :
                <>
                    <DialogTitle>Privacy Policy, {version}</DialogTitle>
                    <DialogContent ref={dialog}>
                        <DialogContentText className={classes.content}>
                            {content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickDisagree} color="secondary">
                            DISAGREE
                        </Button>
                        <Button disabled={disableAgreeBtn} onClick={handleClickAgree} color="primary">
                            AGREE
                        </Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}

export { AgreePrivacyPolicyModal };