import React, {useEffect} from 'react';
import {
    Card,
    CardContent,
    Container,
    TextField,
    Typography,
    CardActions,
    Grid,
    Button,
    RadioGroup, FormControlLabel, Radio, InputLabel
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useForm, Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import InputMask from 'react-input-mask';
import {Alert, Autocomplete} from "@material-ui/lab";

import { actions } from '../auth/authSlice';
import { signup } from './authSlice';
import { 
    isName, isEmail, isLength, stripMask
} from '../common/validate';
import { loadVendorUserRoles } from "../auth/authSlice";
import { loadCustomerUserRoles } from "../auth/authSlice";

const useStyles = makeStyles(theme => ({
    logoDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    logo: {
        width: '300px',
        marginTop: '80px',
        marginBottom: '80px'
    },
    card: {
        width: '100%'
    },
    content: {
        padding: '5px',
        backgroundColor: theme.palette.primary.main,
        paddingBottom: '5px !important'
    },
    header: {
        color: theme.colors.white,
        marginLeft: '5px',
        paddingTop: '5px',
        paddingBottom: '10px'
    },
    form: {
        backgroundColor: theme.colors.white,
        padding: '10px'
    },
    textField: {
        width: '100%',
        marginBottom: '10px'
    },
    actions: {
        marginTop: '5px'
    },
    alert: {
        marginTop: '0px'
    },
    radioGroup: {
        color: '#76787A',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    radio: {
        padding: '5px'
    }
}))

function SignUpPage() {
    const { handleSubmit, register, errors, control, watch, setValue, reset } = useForm();
    const loading = useSelector(state => state.auth.pending);
    const signUpSuccessMsg = useSelector(state => state.auth.signUpSuccessMsg);
    const signUpFailMsg = useSelector(state => state.auth.signUpFailMsg);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const isLoadingVendorUserRoles = useSelector(state => state.auth.loadingVendorUserRoles);
    const vendorUserRoles = useSelector(state => state.auth.vendorUserRoles);
    const isLoadingCustomerUserRoles = useSelector(state => state.auth.loadingCustomerUserRoles);
    const customerUserRoles = useSelector(state => state.auth.customerUserRoles);

    const userType = watch('userType');

    useEffect(() => {
       if (signUpSuccessMsg) {
           reset();
       }
    }, [signUpSuccessMsg, reset]);

    const onSubmit = values => {
        dispatch(signup(values));
    }

    const handleCancel = () => {
        history.push({
            pathname: '/auth/login'
        })
        dispatch(actions.clearSignUpMessage());
    }

    const handleChangeUserType = (event) => {
        setValue('role', '')
        if (event.target.value === 'Customer' && customerUserRoles.length === 0) {
            dispatch(loadCustomerUserRoles());
        }

        if (event.target.value === 'Vendor' && vendorUserRoles.length === 0) {
            dispatch(loadVendorUserRoles());
        }
    }

    const getRoleOptions = () => {
        if (userType === 'Vendor') {
            return vendorUserRoles;
        } else if (userType === 'Customer') {
            return customerUserRoles;
        } else {
            return [];
        }
    }

    const getLoadingStatus = () => {
        if (userType === 'Vendor') {
            return isLoadingVendorUserRoles;
        } else if (userType === 'Customer') {
            return isLoadingCustomerUserRoles;
        } else {
            return false;
        }
    }

    return (
        <Container maxWidth="xs" className={classes.container}>
            <div className={classes.logoDiv}>
                <img alt="Logo" className={classes.logo} src="https://cdn.homecareconnect.com/images/paradigm_orange_logo.svg" />
            </div>
            <Card className={classes.card} style={{marginBottom:'50px'}}>
                <CardContent className={classes.content}>
                    <Typography className={classes.header} variant="h5">
                        Request Account
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    name="email"
                                    label="Email"
                                    inputRef={register({
                                        required: true,
                                        validate: isEmail
                                    })}
                                    error = { !!errors.email }
                                    helperText={errors.email && errors.email.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className={classes.textField}
                                    name="firstName"
                                    label="First Name"
                                    inputRef={register({
                                        required: true,
                                        validate: isName
                                    })}
                                    error={ !!errors.firstName }
                                    helperText={errors.firstName && errors.firstName.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className={classes.textField}
                                    name="lastName"
                                    label="Last Name"
                                    inputRef={register({
                                        required: true,
                                        validate: isName
                                    })}
                                    error={ !!errors.lastName }
                                    helperText={errors.lastName && errors.lastName.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="userType"
                                    defaultValue=""
                                    as = {
                                        <RadioGroup row className={classes.radioGroup} aria-label="userType" name="userType">
                                            <InputLabel error={ !!errors.userType } style={{paddingRight: '20px'}}>Type</InputLabel>
                                            <FormControlLabel value="Customer" control={<Radio className={classes.radio} onChange={event => handleChangeUserType(event)}/>} label="Customer"/>
                                            <FormControlLabel value="Vendor" control={<Radio className={classes.radio} onChange={event => handleChangeUserType(event)}/>} label="Vendor"/>
                                        </RadioGroup>
                                    }
                                    rules={{required: true}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    defaultValue={null}
                                    render={props => (
                                        <Autocomplete
                                            {...props}
                                            options={getRoleOptions()}
                                            autoComplete={true}
                                            autoHighlight={true}
                                            autoSelect={true}
                                            getOptionLabel={option => option}
                                            getOptionSelected={(option, value) => option === value}
                                            renderInput={params => (
                                                <TextField error={ !!errors.role } helperText={errors.role && errors.role.message} {...params} label="Role"/>
                                            )}
                                            onChange={(_, data) => props.onChange(data)}
                                            loading={getLoadingStatus()}
                                        />
                                    )}
                                    name = "role"
                                    rules={{required: true}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    name="companyName"
                                    label="Company Name"
                                    inputRef={register({
                                        required: true
                                    })}
                                    error={ !!errors.companyName }
                                    helperText={errors.companyName && errors.companyName.message}
                                />
                            </Grid>
                            { userType === 'Vendor' && 
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        name="taxId"
                                        label="Tax ID"
                                        inputRef={register({
                                            required: true,
                                            validate: isLength(9)
                                        })}
                                        error={ !!errors.taxId }
                                        helperText={errors.taxId && errors.taxId.message}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    name="address"
                                    label="Address"
                                    inputRef={register({
                                        required: true
                                    })}
                                    error={ !!errors.address }
                                    helperText={errors.address && errors.address.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control = {control}
                                    name = "phone"
                                    defaultValue = ""
                                    as = {
                                        <InputMask mask="(999) 999-9999" maskChar=" ">
                                            {(props) => (
                                                <TextField
                                                    className = {classes.textField}
                                                    label = "Phone"
                                                    error = {!!errors.phone}
                                                    helperText = {errors.phone && errors.phone.message}
                                                    {...props}
                                                />
                                            )}
                                        </InputMask>
                                    }
                                    rules = {{
                                        validate: stripMask(/[ ()-]/g)(isLength(10)),
                                        required: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        { signUpFailMsg && <Alert className={classes.alert} severity="error">{signUpFailMsg}</Alert> }
                        { signUpSuccessMsg && <Alert className={classes.alert} severity="success">{signUpSuccessMsg}</Alert> }
                        <CardActions className={classes.actions}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Button
                                    disabled={ loading }
                                    onClick={handleCancel}
                                    variant="contained"
                                    color="default"
                                >
                                    Back
                                </Button>
                                <Button
                                    disabled={ loading }
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </Button>
                            </Grid>
                        </CardActions>
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}

export { SignUpPage }