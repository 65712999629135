import { createSlice, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { doGet } from 'common/fetch';

export const searchClaims = createAsyncThunk(
    'claims/search',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { claims: { criteria, page, limit, sort }, auth: { userType } } = getState();
            const { data, response, error } = await doGet({
                path: `claim/${userType.toLowerCase()}/search`,
                params: {
                    ...criteria,
                    page, 
                    limit,
                    sortDirection: sort.direction,
                    sortProperty: sort.property
                }
            });

            if (response.ok) {
                return {
                    claims: data.data,
                    totalClaims: data.total
                };
            }

            return rejectWithValue(error);
        }
        catch (error) {
            rejectWithValue(error);
        }
    }
);

export const loadClaim = createAsyncThunk(
    'claims/loadClaim',
    async ({ claimId, userType }, { dispatch, rejectWithValue }) => {
        try {
            const { data, response, error } = await doGet({ path: `claim/loadClaim`, params: { claimId } });
            
            if (response.ok) {

                const { patientAddress, claim, patientPhones, referralFilterDate } = data;

                if (userType === 'Customer') {
                    dispatch(loadReferrals({ claimId, fromDate: referralFilterDate, toDate: null }));
                }
                
                if (patientAddress) {
                    claim.patientStreet1 = patientAddress.street1;
                    claim.patientStreet2 = patientAddress.street2;
                    claim.patientCity = patientAddress.city;
                    claim.patientState = patientAddress.state;
                    claim.patientZip = patientAddress.zip;
                }
                
                return { claim, patientPhones, referralFilterDate };
            }

            return rejectWithValue(error);
        }
        catch (error) {
            rejectWithValue(error);
        }
    }
);

export const clearClaim = createReducer([], {

})

export const loadReferrals = createAsyncThunk(
    'claims/loadReferrals',
    async ({ claimId, fromDate, toDate }, { rejectWithValue }) => {
        try {
            const { data, response, error } = await doGet({ path: `referral/loadReferrals`, params: { claimId, fromDate, toDate }});

            if (response.ok) {
                return {
                    referrals: data.data
                }
            }

            return rejectWithValue(error);
        }
        catch (error) {
            rejectWithValue(error);
        }
    }
)

export const claimsSlice = createSlice({
    name: 'claims',
    initialState: {
        loaded: false,
        loading: false,
        claimDetail: {
            referrals: [],
            errors: {},
            referralFilterDate: null,
            referralsLoading: true
        },
        claims: [],
        totalClaims: 0,
        error: false,
        criteria: {},
        page: 1,
        limit: 25,
        sort: { 
            property: 'injuryDate', 
            direction: 'desc' 
        }
    },
    reducers: {
        clearClaim: state => {
            state.claimDetail.claim = undefined;
        },
        setCriteria: (state, action) => {
            state.criteria = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload;
        },
        setSort: (state, action) => {
            state.sort = action.payload;
        }
    },
    extraReducers: {
        [searchClaims.pending]:  state => { 
            state.loaded = true;
            state.loading = true;
        },
        [searchClaims.fulfilled] : (state, action) => {
            state.loading = false;
            state.claims = action.payload.claims;
            state.totalClaims = action.payload.totalClaims;
        },
        [searchClaims.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
        [loadClaim.pending]: state => {
            state.claimDetail.claimLoading = true;
            state.claimDetail.claim = undefined;
            state.claimDetail.referralFilterDate = null;
        },
        [loadClaim.fulfilled] : (state, action) => {
            const { claim, patientPhones, referralFilterDate } = action.payload
            state.claimDetail.claimLoading = false;
            state.claimDetail.claim = claim;
            state.claimDetail.patientPhones = patientPhones && action.payload.patientPhones.sort((a, b) => a.preferred ? -1 : 1);
            state.claimDetail.referralFilterDate = referralFilterDate;
        },
        [loadClaim.rejected]: (state, action) => {
            state.claimDetail.claimLoading = false;
            state.claimDetail.claim = undefined;
            state.error = action.error;
        },
        [loadReferrals.pending]: state => {
            state.claimDetail.referralsLoading = true;
            state.claimDetail.referrals = [];
        },
        [loadReferrals.fulfilled] : (state, action) => {
            state.claimDetail.referralsLoading = false;
            state.claimDetail.referrals = action.payload.referrals;
        },
        [loadReferrals.rejected]: (state, action) => {
            state.claimDetail.referralsLoading = false;
            state.claimDetail.referrals = undefined;
            state.claimDetail = action.error;
            state.claimDetail.loadReferralErrorMsg = action.payload;
        }
    }
});

export const { actions, reducer } = claimsSlice;

export default reducer;
