import {useTheme} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import React from "react";

const useStyles = makeStyles(theme => ({
    content: {
        whiteSpace: 'pre-wrap'
    }
}))

function DialogWindow({handleClick, open, title, content, buttonText = 'OK', isLoading = false}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    return (
        <Dialog open={open} fullScreen={fullScreen} maxWidth={"md"}>
            {isLoading ? <DialogTitle>Loading...</DialogTitle> :
                <>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.content}>
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClick} color="primary">
                    {buttonText}
                </Button>
                </DialogActions>
                </>
            }
        </Dialog>
    )
}

export { DialogWindow };