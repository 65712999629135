const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const name = /^[A-Z.' -]+$/i;

export const stripMask = (regex) => (validator) => (value) => {
    return validator(value ? value.replace(regex, '') : null);
};

export const areEqual = (v1, v2) => {
    if ((!!v1 && !v2) || (!v1 && !!v2) || (!!v1 && v1 !== v2)) {
        return false;
    }
    return true;
}

export const firstError = (validators) => (value) => {
    for (const validator of validators) {
        const error = validator(value);
        if (error) return error;
    }
};

export const notEmpty = (value) => {
    if (!value || value.trim() === '') 
        return 'Required';
};

export const isEmail = (value) => {
    if (value && !email.test(value)) 
        return 'Invalid email address';
};

export const isName = (value) => {
    if (value && !name.test(value)) {
        return "Please use letters, spaces and - ' or .";
    }
};

export const isDigits = (value) => {
    if (value && !/[0-9]{1,}/.test(value)) {
        return 'Digits only';
    }
};

export const isLength = (length) => (value) => {
    if (value) {
        if (value.length !== length) {
            return `Req length ${length}`;
        } 
    } 
};

export const isEitherLength = (length1, length2) => (value) => {
    if (value) {
        if (value.length !== length1 && value.length !== length2) {
            return `Req length ${length1} or ${length2}`;
        } 
    } 
};

export const isLengthBetween = (min, max) => (value) => {
    if (value) {
        if (value.length < min) 
            return `Min length ${min}`;
        if (value.length > max) 
            return `Max length ${max}`;
    } 
};

export const isValueBetween = (min, max) => (value) => {
    if (value) {
        if (value < min)
            return `Min value ${min}`;
        if (value > max)
            return `Max value ${max}`;
    }
};

export const isDate = (value) => {
    if (value) {
        if (value.length !== 8) 
            return 'MM/DD/YYYY format';
        const month = value.substr(0,2);
        const day = value.substr(2,2);
        const year = value.substr(4);
        if (month < 1 || month > 12) 
            return 'Invalid month';
        if (day < 1 || day > 31)
            return 'Invalid day';
        if (year < 1900 || year > 2100)
            return 'Invalid year';
    }
};