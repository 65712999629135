import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { getDynamicRoutes } from 'routes';
import { Header, ParadigmNavbar, ParadigmFooter } from 'common';
import { reload, loadPrivacyPolicy, logout, actions as authActions } from 'auth/authSlice';
import { agreeToPrivacyPolicy } from 'user/userSlice';
import { actions as appActions } from 'appSlice';
import { AgreePrivacyPolicyModal } from 'privacyPolicy/AgreePrivacyPolicyModal';

const { setAuthToken, setAuthPending, setAuthError } = authActions;

function shouldShowParadigmUI(path) {
    if (path && path.includes('web/referral')) {
        return true;
    } else {
        return false;
    }
}

function App(props) {
    const dispatch = useDispatch();
    const { 
        pending, 
        authorized, 
        userType,
        privacyPolicyLoading,
        privacyPolicyDate,
        privacyPolicyContent,
        requirePrivacyPolicy
    } = useSelector(state => state.auth);
    const { message, messageType } = useSelector(state => state.app);

    useEffect(() => {
        const query = window.location.search;
        const params = new URLSearchParams(query);
        const path = window.location.pathname;
        dispatch(loadPrivacyPolicy());
        if (params.has("authError")) 
            dispatch(setAuthError(params.get("authError")));
        if (params.has('token')) {
            dispatch(setAuthToken(params.get('token')));
        } else if (path.includes('/auth/') || path.includes('/web/')|| path.includes('/external')) {
            dispatch(setAuthPending(false));
        } else {
            dispatch(reload()); 
        }
    }, [dispatch]);

    const userRoutes = getDynamicRoutes(userType, authorized, pending);

    const showParadigmUI = shouldShowParadigmUI(window.location.pathname);

    const handleClickDisagree = () => {
        dispatch(logout());
    }

    const handleClickAgree = () => {
        dispatch(agreeToPrivacyPolicy());
    }
    
    return (
        <BrowserRouter>
            { authorized && <Header /> }
            { showParadigmUI && <ParadigmNavbar />}
            <Container style={{ padding: '0px' }} maxWidth={false}>
                <AgreePrivacyPolicyModal 
                        content={privacyPolicyContent} 
                        version={privacyPolicyDate} 
                        handleClickAgree={handleClickAgree} 
                        handleClickDisagree={handleClickDisagree} 
                        handleCloseModal={handleClickDisagree} 
                        openModal={requirePrivacyPolicy}
                        isLoading={privacyPolicyLoading}/>
                {userRoutes}
                <Snackbar open={!!message} autoHideDuration={messageType === 'error' ? null : 5000} onClose={() => dispatch(appActions.clearMessage())}>
                    <Alert onClose={() => dispatch(appActions.clearMessage())} severity={messageType}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
            { showParadigmUI && <ParadigmFooter />}
        </BrowserRouter>
    );
}

export default App;
