import React from 'react';

import './ParadigmNavbar.css';


function ParadigmWideDetailedMenu({showFlag, hideDetails}) {
    const headerLinkClasses = 'navbar-nav mr-auto division-links';

    const handleMouseLeaveDivisionMenu = (e) => {
        hideDetails();
    }
 
    return (
        <div id="divisionDescriptions" onMouseLeave={handleMouseLeaveDivisionMenu}
                className={(showFlag && showFlag.length > 1) ? 'container-fluid' : 'container-fluid d-none'} >
            <div className="container">
                <div id="description" className="ml-auto">
                    <ul id="catastrophic-division-header-links" 
                            className={(showFlag && showFlag ==='cat') ? headerLinkClasses : `${headerLinkClasses} d-none`} >
                        <li className="menu-item-quote">
                            Care management for a wide range of injuries, delivering guaranteed outcomes at a fixed price.
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/catastrophic-care-management/#the-paradigm-difference" className="nav-link">HERO Catastrophic<sup>SM</sup></a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/catastrophic-care-management/hero-severe/" className="nav-link">HERO Severe<sup>SM</sup></a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/catastrophic-care-management/hero-episodic/" className="nav-link">HERO Episodic<sup>SM</sup></a>
                        </li>
                    </ul>
                    <ul id="complex-division-header-links" 
                            className={(showFlag && showFlag ==='case') ? headerLinkClasses : `${headerLinkClasses} d-none`} >
                        <li className="menu-item-quote">
                            Nationwide quality case management solutions.
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/case-management-solutions/field-case-management/" className="nav-link">Field Case Management</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/case-management-solutions/telephonic-case-management/" className="nav-link">Telephonic Case Management</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/case-management-solutions/vocational-services/" className="nav-link">Vocational Services</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/case-management-solutions/nurse-triage/" className="nav-link">24/7 Nurse Triage &amp; Telemedicine</a>
                        </li>
                    </ul>
                    <ul id="specialty-division-header-links" 
                            className={(showFlag && showFlag ==='specialty') ? headerLinkClasses : `${headerLinkClasses} d-none`} >
                        <li className="menu-item-quote">
                            Specialty-focused network and cost containment solutions for high-acuity injuries.
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/specialty-networks/orthopedic-and-spine-specialist-network/" className="nav-link">Orthopedic &amp; Spine</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/specialty-networks/comprehensive-pain-network/" className="nav-link">Comprehensive Pain</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/specialty-networks/post-acute-care-network/" className="nav-link">Post-Acute Care</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/specialty-networks/behavioral-health-network/" className="nav-link">Behavioral Health</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/specialty-networks/surgical-implant-cost-management/" className="nav-link">Surgical Implant Cost Management</a>
                        </li>
                    </ul>
                    <ul id="care-at-home-division-header-links" 
                            className={(showFlag && showFlag ==='care') ? headerLinkClasses : `${headerLinkClasses} d-none`} >
                        <li className="menu-item-quote">
                            Clinically driven home health services to ensure continuity of care and achieve better outcomes.
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/care-at-home/home-health-care/" className="nav-link">Home Health Care</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/care-at-home/dme-med-supplies/" className="nav-link">DME &amp; Medical Supplies</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/care-at-home/home-modifications/%20" className="nav-link">Home Modifications</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.paradigmcorp.com/care-at-home/prosthetics/%20" className="nav-link">Prosthetics</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export { ParadigmWideDetailedMenu };
