import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, CircularProgress, Button, RootRef, Paper, 
    IconButton, TextField, Backdrop, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useDropzone} from 'react-dropzone'
import { Autocomplete, Alert } from '@material-ui/lab';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';

import { DataTable } from 'common'; 
import { loadAttachmentTypes, getAttachments, addPendingAttachment, uploadAttachment, actions as attachmentActions } from './attachmentSlice';

const { removePendingAttachment, setAttachmentType, setUploadDirty, setPageData } = attachmentActions;

const ATTACHMENT_COLUMNS = [
    { Header: 'Type', accessor: 'type', width: '15%' },

    { Header: 'Date', accessor: 'createdDate' , width: '15%', Cell: cell => {
        const { date } = cell.row.original;
        return moment(date).format("M/DD/YYYY h:mma");
    }},
    { Header: 'Size', accessor: 'fileSize', width: '10%', Cell: cell => {
        const { readableSize } = cell.row.original;
        return readableSize;
    }},
    { Header: 'Note', accessor: 'note' }
];


const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    text: {
        color: theme.typography.color
    },
    attachment: {
        color: theme.typography.color,
        marginTop: '15px',
        marginBottom: '30px',
        fontWeight: 'bold'
    },
    loading: {
        margin: 'auto'
    },
    cardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white,
    },
    upload: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '80px',
        color: '#505050',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderStyle: 'dashed'
    },
}));

function FileDropZone({ entityId, handleFileSelect }) {
    const classes = useStyles();
    const { fileErrors = [], filesPending = 0, uploadError } = useSelector(state => state.attachment.pendingAttachments[entityId] || {});
    const { uploadPending } = useSelector(state => state.attachment);
    const {getRootProps, getInputProps} = useDropzone({ onDrop: handleFileSelect });
    const {ref, ...rootProps} = getRootProps();

    return (
        <>
            <Backdrop open={uploadPending && !uploadError} style={{zIndex: 999, backgroundColor: 'rgba(0,0,0,0.3)'}}>
                <CircularProgress color="primary"/>
            </Backdrop>
            <RootRef rootRef={ref}>
                <Paper {...rootProps} variant="outlined" className={classes.upload}>
                    { filesPending > 0 ? 
                        <CircularProgress/> 
                    : 
                        <div>
                            <input {...getInputProps()} />
                            <Typography variant="h6" style={{padding: '10px'}}>
                                Drag files here, or click to select
                            </Typography>
                        </div>
                    }
                </Paper>
            </RootRef>
            {fileErrors.map((error) => (
                <div style={{width: '100%', marginBottom: '5px'}} key={error}>
                    <Alert severity="warning">{error}</Alert>
                </div>
            ))}
        </>
    );
};

function PendingAttachments({ attachmentTypes = [], pendingAttachments = [], entityId }) {
    const dispatch = useDispatch();
    const { uploadDirty } = useSelector(state => state.attachment);

    const setType = attachmentId => (e, type) => {
        dispatch(setAttachmentType({
            entityId,
            attachmentId,
            type
        }));
    };

    const onPendingAttachmentRemoved = attachmentId => () => {
        dispatch(removePendingAttachment({ attachmentId, entityId }));
    }

    const typeMissing = (attachmentId) => {
        const attachment = pendingAttachments.find(a => a.id === attachmentId);
        return !(attachment && attachment.type);
    }

    return pendingAttachments.map((attachment) => (
        <div style={{marginTop: '20px'}} key={attachment.id}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <IconButton tabIndex="-1" onClick={onPendingAttachmentRemoved(attachment.id)}>
                    <RemoveCircleIcon style={{color:'rgb(200,0,0)'}} />
                </IconButton>
                <Typography variant="subtitle1">
                    {attachment.name} - {attachment.size}
                </Typography>
            </div>
            <div style={{display: 'flex'}}>
                <div style={{width: '50px'}}/>
                <Autocomplete
                    openOnFocus
                    options = {attachmentTypes}
                    autoComplete = {true}
                    autoHighlight = {true}
                    autoSelect = {true}
                    name = {`fileType-${attachment.id}`}
                    disableClearable = {true}
                    style = {{width: '80%', maxWidth: '300px'}}
                    defaultValue = {attachmentTypes.length === 1 ? attachmentTypes[0] : null}
                    renderInput = {params => {
                        return (
                            <TextField label="Type" {...params} 
                                error = { uploadDirty && typeMissing(attachment.id) }                                
                                helperText = "required"
                                inputProps={{ ...params.inputProps }} {...params}/>
                        )}}
                    onChange = {setType(attachment.id)}
                    getOptionLabel={option => option.name}
                />
            </div>
        </div>
    ))
}

function AttachmentList({ entityId, entityName, hidePagingToolbar }) {
    const dispatch = useDispatch();

    const loadPending = useSelector(state => state.attachment.loadPending[entityId]);
    const attachments = useSelector(state => state.attachment.attachments[entityId]);
    const metadata = useSelector(state => state.attachment.metadata[entityId]);
    const { totalCount, page, limit, sortProperty, sortDirection } = metadata || {};

    const onRowClick = selectedIndex => {
        if (selectedIndex >= 0) {
            const attachment = attachments[selectedIndex];
            window.open(attachment.url, '_blank');
        }
    }

    const onPageChange = newPage => {
        dispatch(setPageData({ entityId, page: newPage + 1, limit, sortProperty, sortDirection }));
        dispatch(getAttachments({ entityName, entityId }));
    }

    const onRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value);
        dispatch(setPageData({ entityId, page: 1, limit: newLimit, sortProperty, sortDirection }));
        dispatch(getAttachments({ entityName, entityId }));
    };

    const onSortChange = (newProperty) => {
        const newDirection = newProperty === sortProperty 
            ? sortDirection === 'asc' 
                ? 'desc'
                : 'asc' 
            :'asc';
        
        dispatch(setPageData({ entityId, page, limit, sortProperty: newProperty, sortDirection: newDirection }));
        dispatch(getAttachments({ entityId, entityName }))
    };

    return (
        <Grid item xs={12}>
            <DataTable
                hideLoadingIndicator = {false} 
                loading = {!!loadPending}
                columns = {ATTACHMENT_COLUMNS}
                data = {attachments}
                totalCount = {totalCount}
                currentPage={page}
                onPageChange={onPageChange}
                rowsPerPage = {limit}
                rowsPerPageOptions = {[10,25,50]}
                sort = {{ sortProperty, sortDirection }}
                onSortChange = {onSortChange}
                sortBy = {sortProperty}
                sortDirection = {sortDirection}
                labelRowsPerPage = "Details per page"
                onRowsPerPageChange = {onRowsPerPageChange}
                hidePagingBar = {hidePagingToolbar} 
                onRowClick = {onRowClick} />
        </Grid>
    )
}


function Attachments({ entityName, entityId, hidePagingToolbar = false }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { attachmentTypes, uploadPending } = useSelector(state => state.attachment);
    const pendingAttachments = useSelector(state => state.attachment.pendingAttachments[entityId]);
    const loadPending = useSelector(state => state.attachment.loadPending[entityId]);

    const theme = useTheme();
    const fullSizeSubmit = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        dispatch(getAttachments({ entityName, entityId }));
        dispatch(loadAttachmentTypes({ entityName, entityId }));
    }, [dispatch, entityName, entityId]);

    const handleFileSelect = files => {
        if (files && files.length) {
            dispatch(addPendingAttachment({ entityId, entityName, newAttachments: files }));
        }
    }

    const handleUpload = () => {
        dispatch(setUploadDirty());

        if (!uploadPending && pendingAttachments.filter(a => !a.type).length === 0) {
            dispatch(uploadAttachment({ entityName, entityId, attachments: pendingAttachments}));
        }
    }

    return (<>
        <Grid container item alignItems="center" justify="space-between">
            <Typography variant="h6" className={classes.attachment}>
                Attachments
            </Typography>
            {loadPending &&
                <Grid container item style={{justifyContent: 'center'}}>                
                    <CircularProgress style={{marginTop:'25px'}}/>
                </Grid>
                
            }
            {!loadPending && <>
                <Grid item xs={12}>
                    <AttachmentList entityId={entityId} entityName={entityName} hidePagingToolbar={hidePagingToolbar} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '25px' }}>
                    <FileDropZone entityId={ entityId } handleFileSelect={handleFileSelect} />
                </Grid></>}

        </Grid>
        {!loadPending && 
            <>
                <Grid item xs={12}>
                    <PendingAttachments 
                        attachmentTypes={attachmentTypes[entityName]} 
                        entityId={entityId} 
                        pendingAttachments={pendingAttachments} />
                </Grid>
                <Grid container style={{ marginTop: '20px', justifyContent:'flex-end' }} alignItems="center">
                    <Button onClick={handleUpload}
                            fullWidth={fullSizeSubmit}
                            disabled={!pendingAttachments || !pendingAttachments.length}
                            className={classes.button} 
                            startIcon={<CloudUploadIcon/>} 
                            variant="contained" color="secondary">
                        Upload Files
                    </Button>
                </Grid>
            </>}
        </>);
}


export { Attachments };