import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { doGet } from '../common/fetch';

export const loadChangeNotes = createAsyncThunk(
    'changeNotes/loadChangeNotes',
    async () => {
        const {data, response} = await doGet({
            path: 'changeNote/loadChangeNotes'
        });
        if (response.ok) {
            return data;
        }
    }
)

export const changeNotesSlice = createSlice({
    name: 'changeNote',
    initialState: {
        loading: false,
        changeNotes: [],
        error: false
    },
    extraReducers: {
        [loadChangeNotes.pending]: state => {
            state.loading = true;
            state.changeNotes = [];
            state.error = false;
        },
        [loadChangeNotes.fulfilled]: (state, action) => {
            state.loading = false;
            state.changeNotes = [...state.changeNotes, ...action.payload];
            state.error = false;
        },
        [loadChangeNotes.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        }
    }
});

export const { actions, reducer } = changeNotesSlice;

export default reducer;