import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { readFileAsync, getReadableSize } from 'common/fileUtilities';
import { doGet, doPost } from '../common/fetch';

export const loadACH = createAsyncThunk(
    'vendor/loadACH',
    async (_, { rejectWithValue }) => {
        const { response, data } = await doGet({
            path: 'vendor/loadACH'
        });
        if (response.ok) {
            return data;
        }
        else {
            return rejectWithValue(data.message);
        }
    }
);


export const saveACH = createAsyncThunk(
    'vendor/saveACH',
    async (_, { getState, rejectWithValue }) => {
        const { ach: { forms }} = getState();
        const { response, data } = await doPost({
            path: 'vendor/saveACH',
            params: { ...forms.ach }
        });
        if (response.ok) {
            return data;
        }
        else {
            return rejectWithValue('Error saving ACH');
        }
    }
);

export const uploadCheckImage = createAsyncThunk(
    'vendor/uploadCheckImage',
    async (file, { rejectWithValue }) => {
        const { name, size, type } = file;
        if (size > (1024*1024*20)) {
            return rejectWithValue({
                error: 'Check image over max size (20 MB)'
            });
        }

        const { response, data } = await doPost({
            path: 'vendor/uploadCheckImage',
            params: {
                name,
                length: size,
                contentType: type,
                size: getReadableSize(size),
                data: await readFileAsync(file)
            }
        });

        if (response.ok) {
            return data;
        }
        return rejectWithValue({ error: response.message });
    });

export const getCheckImageURL = createAsyncThunk(
    'vendor/getCheckImageURL',
    async (_, { rejectWithValue }) => {
        const { response, data } = await doGet({
            path: 'vendor/getCheckImageURL',
        });
        if (response.ok) {
            return data;
        }
        return rejectWithValue('There was an error loading the check image');
    }
);

export const signACH = createAsyncThunk(
    'vendor/signACH',
    async (_, { rejectWithValue }) => {
        const { response } = await doPost({
            path: 'vendor/signACH'
        });
        if (response.ok) {
            return true;
        }
        return rejectWithValue('There was an error signing the ACH');
    }
)

export const achSlice = createSlice({
    name: 'ach',
    initialState: {
        save: {
            pending: false,
            success: false,
            error: false
        },
        load: {
            pending: true,
            success: false,
            hasLoaded: false,
            hasACH: false,
            isProcessing: true
        },
        checkImage: {
            error: false,
            imageUploaded: false,
            url: {
                pending: false,
                error: false,
                url: false
            },
            read: {
                pending: false,
                file: {}
            },
            upload: {
                pending: false
            },
            pdf: {
                pending: false,
                error: false
            }
        },
        agreement: {
            pending: false,
            success: false,
            error: false,
            isSigned: false,
        },
        expands: {
            stepOne: true,
            stepTwo: true,
            stepThree: true
        },
        forms: {
            ach: {
                contactName: '',
                contactEmail: '',
                federalTaxId: '',
                contactPhone: '',
                remittanceEmail: '',
                remittanceFax: '',
                bankName: '',
                accountType: 'Checking',
                routingNumber: '',
                accountNumber: ''
            }
        },
    },
    reducers: {
        setStepOneExpanded: (state, action) => { state.expands.stepOne = action.payload },
        setStepTwoExpanded: (state, action) => { state.expands.stepTwo = action.payload },
        setStepThreeExpanded: (state, action) => { state.expands.stepThree = action.payload },
        setACHForm: (state, action) => { state.forms.ach = action.payload }
    },
    extraReducers: {
        [saveACH.pending]: state => {
            state.save.pending = true;
            state.save.error = false;
        },
        [saveACH.fulfilled]: (state, action) => {
            state.save.pending = false;
            state.save.success = true;
            state.load.ach = action.payload;
            state.load.hasACH = true;
        },
        [saveACH.rejected]: (state, action) => {
            state.save.pending = false;
            state.save.error = action.payload;
        },
        [loadACH.pending]: state => {
            state.load.pending = true;
        },
        [loadACH.fulfilled]: (state, action) => {
            state.load.pending = false;
            state.load.hasLoaded = true;
            state.load.ach = action.payload.ach;
            state.load.hasACH = !!action.payload.ach;
            state.load.isProcessing = action.payload.isProcessing;
            state.load.isImported = action.payload.isImported;
            if (action.payload.ach) {
                state.forms.ach = action.payload.ach;
            }

            state.agreement.isSigned = action.payload.ach && action.payload.ach.signed;
        },
        [loadACH.rejected]: (state, action) => {
            state.load.pending = false;
            state.load.error = action.error;
        },
        [uploadCheckImage.pending]: state => {
            state.checkImage.upload.pending = true;
        },
        [uploadCheckImage.fulfilled]: (state, action) => {
            state.checkImage.upload.pending = false;
            state.checkImage.upload.success = true;
            state.checkImage.imageUploaded = true;
            state.checkImage.url.url = action.payload.signedURL;
            state.checkImage.url.isImage = action.payload.isImage;
            state.checkImage.url.isPDF = action.payload.isPDF;
            state.checkImage.url.attachmentId = action.payload.attachmentId;
        },
        [uploadCheckImage.rejected]: (state, action) => {
            state.checkImage.upload.pending = false;
            state.checkImage.error = action.payload;
        },
        [getCheckImageURL.pending]: state => {
            state.checkImage.url.pending = true;
        },
        [getCheckImageURL.fulfilled]: (state, action) => {
            state.checkImage.url.pending = false;
            state.checkImage.url.url = action.payload.signedURL;
            state.checkImage.imageUploaded = !!action.payload.signedURL;
            state.checkImage.url.isImage = action.payload.isImage;
            state.checkImage.url.isPDF = action.payload.isPDF;
            state.checkImage.url.attachmentId = action.payload.attachmentId;
        },
        [getCheckImageURL.rejected]: (state, action) => {
            state.checkImage.url.pending = false;
            state.checkImage.url.error = action.payload;
        },
        [signACH.pending]: state => {
            state.agreement.pending = true;
        },
        [signACH.fulfilled]: state => {
            state.agreement.pending = false;
            state.agreement.success = true;
            state.agreement.isSigned = true;
        },
        [signACH.rejected]: state => {
            state.agreement.pending = false;
            state.agreement.error = 'Error signing ACH';
        },
    }
});

export const { actions, reducer } = achSlice;
export default reducer;