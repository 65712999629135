import React, { useState } from 'react';
import {
    useTheme,
    useMediaQuery,
    Backdrop,
    Grid,
    IconButton
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { ParadigmSideMenu } from './ParadigmSideMenu';
import { ParadigmTopSearch } from './ParadigmTopSearch';
import { ParadigmWideDetailedMenu } from './ParadigmWideDetailedMenu';

import './ParadigmNavbar.css';


const useStyles = makeStyles(theme => ({
    navbar: {
        position: 'relative', 
        display: 'flex', 
        flexWrap: 'wrap', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        textAlign: 'start',
        minHeight: '60px',
        paddingTop: '.5rem', 
        paddingBottom: '.5rem',     
        backgroundColor: 'white',
        boxShadow: '0 4px 7px #8888887a',
    },
    navbarFixed: {
        position: 'fixed',
        width: '100%',
        zIndex: '2',
        top: '0',
        left: '0',
    },
    navContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 auto',
        padding: '0',
        maxWidth: '1000px',
        '@media only screen and (max-width: 1199px)': {
            width: '89%',
        },
        '@media only screen and (max-width: 757px)': {
            width: '85%',
        },
    },
    logo: {
        height: '40px',
        width: '170px',
        margin: '0',
    },
    logoLink: {
        padding: '0',
    },
    topSearchContainer: {
        textAlign: 'right',
        fontSize: '17px',
    },
    mainMenu: {
        '@media (max-width: 1199px)': {
            height: '46px',
            width: '46px',
            color: '#0000008c',
            borderColor: '0000001a',
            backgroundColor: 'transparent !important',
            backgroundImage: 'url(/hamburger-icon.svg)',
            opacity: '0.5',
            backgroundSize: '46px 46px',
            backgroundPosition: 'center 0px',
        },
        '@media (min-width: 1200px)': {
            display: 'none',
        },
    },
    mainMenuContainer: {
        '@media (min-width: 1200px)': {
            display: 'none',
        },
    },
    mainMenuClose: {
        '@media (max-width: 1199px)': {
            height: '16px',
            margin: '0 0 0 16px',
            padding: '0',
            backgroundImage: 'url(/close-icon.svg)',
            backgroundPosition: 'right',
        },
    },
    backdrop: {
        zIndex: '1',
    }
}));


function ParadigmNavbar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMediumAndDown = useMediaQuery(theme.breakpoints.down(1199));
    const is768to1200 = useMediaQuery(theme.breakpoints.between(768,1200));
    const is1200Up = useMediaQuery(theme.breakpoints.up(1200));
    const [showSideMenu, SetShowSideMenu] = useState(false);
    const [showWideDetailMenu, SetShowWideDetailMenu] = useState('');

    const onClickShowMenu = () => { SetShowSideMenu(!showSideMenu); } ;
    const SetHideWideDetailMenu = () => { SetShowWideDetailMenu(''); };

    return (
        <>
            <nav className="navbar navbar-expand-xl navbar-light bg-transparent tracked-nav-links" >
                <Grid container spacing={0} className="container no-padding">
                    <Grid item className="navbar-brand" xs={10} sm={3} >
                        <a href="https://www.paradigmcorp.com/" className={classes.logoLink}>
                            <img alt="Logo" className={classes.logo} 
                                src="https://cdn.homecareconnect.com/images/paradigm_orange_logo.svg"/>
                        </a>                
                    </Grid>
                    {is768to1200 
                        ? <Grid item sm={7} className={classes.topSearchContainer}><ParadigmTopSearch /></Grid>
                        : null
                    }
                    <Grid item xs={2} sm={1} className={classes.mainMenuContainer} >
                        <IconButton className={showSideMenu ? `${classes.mainMenu} ${classes.mainMenuClose}` : classes.mainMenu} onClick={onClickShowMenu}></IconButton>
                    </Grid>
                    <ParadigmSideMenu show={showSideMenu} showDetailsFor={SetShowWideDetailMenu} />
                </Grid>
            </nav>
            {is1200Up ?
                <ParadigmWideDetailedMenu showFlag={showWideDetailMenu} hideDetails={SetHideWideDetailMenu} />
                : null
            }
            <Backdrop className={classes.backdrop} open={showSideMenu && isMediumAndDown} ></Backdrop>
        </>
    );
}

export { ParadigmNavbar };
