

export const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

export const getReadableSize = (size) => {
    let sizeType = 'KB';
    let readableSize = size / 1024;
    if (readableSize > 1024) {
        sizeType = 'MB';
        readableSize = readableSize / 1024;
    }
    return `${Math.round(readableSize * 10)/10} ${sizeType}`;
};