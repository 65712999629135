import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { doGet } from '../../common/fetch';

export const loadOrders = createAsyncThunk(
    'vendor/loadOrders',
    async (claimId, { rejectWithValue }) => {
        const { data, response } = await doGet({
            path: 'vendor/loadOrders',
            params: { claimId }
        });
        if (response.ok) {
            return { 
                orders: data
            };
        }
        return rejectWithValue(true);
    }
);

export const loadOrderDetails = createAsyncThunk(
    'vendor/loadOrderDetails',
    async ({ purchaseOrderId }, { rejectWithValue }) => {
        const { data, response } = await doGet({
            path: 'vendor/loadOrderDetails',
            params: { purchaseOrderId }
        });
        if (response.ok) {
            return {
                orderDetails: data,
                purchaseOrderId
            };
        }
        return rejectWithValue(true);
    }
);

export const vendorSlice = createSlice({
    name: 'order',
    initialState: {
        orders: [],
        detailsByOrder: {},
        detailsPending: false,
        errors: {},
        forms: {
            upload: {}
        }
    },
    reducers: {
    },
    extraReducers: {
        [loadOrders.pending]: state => {
            state.ordersPending = true;
        },
        [loadOrders.fulfilled]: (state, action) => {
            state.ordersPending = false;
            state.orders = action.payload.orders;
        },
        [loadOrders.rejected]: (state, action) => {
            state.ordersPending = false;
            state.orders = [];
            state.orders.error = action.error;
        },
        [loadOrderDetails.pending]: (state, action) => {
            const { purchaseOrderId } = action.meta.arg;
            state.detailsPending = true;
            state.detailsByOrder = {
                ...state.detailsByOrder,
                [purchaseOrderId]: { loading: true }
            };

        },
        [loadOrderDetails.fulfilled]: (state, action) => {
            const { purchaseOrderId, orderDetails } = action.payload;
            state.detailsByOrder[purchaseOrderId] = orderDetails;
            state.detailsPending = false;
        },
        [loadOrderDetails.rejected]: (state, action) => {
            const { purchaseOrderId } = action.meta.arg;
            state.detailsPending = false;
            state.detailsByOrder[purchaseOrderId] = { 
                loading: false,
                error: action.error 
            };
        }
    }
});

export const { actions, reducer } = vendorSlice;
export default reducer;