import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { DataTable } from 'common';
import { Attachments } from 'attachment/Attachments';
import { loadOrderDetails, loadOrders } from './orderSlice';

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    text: {
        color: theme.typography.color
    },
    attachment: {
        color: theme.typography.color
    },
    loading: {
        margin: 'auto'
    },
    cardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white,
    },
    upload: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '80px',
        color: '#505050',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderStyle: 'dashed'
    },
    accordionSummary: {
        marginTop: '10px'
    }
}));

const DETAIL_COLUMNS = [
    { Header: 'Qty', accessor: 'quantity', width: '10%' },
    { Header: 'Cost Ea', width: '15%', Cell: cell => cell.row.original.costEach ? `$${cell.row.original.costEach.toFixed(2)}` : '' },
    { Header: 'Total', width: '15%', Cell: cell => cell.row.original.costTotal ? `$${cell.row.original.costTotal.toFixed(2)}`: '' },
    { Header: 'Description', accessor: 'description', width: '45%', Cell: cell => {
        const { hcpcs, description } = cell.row.original;
        return !!hcpcs ? `${hcpcs} ${description}` : description;
    }},
    { Header: 'Date', width: '15%', Cell: cell => {
        const { authStartDate, authEndDate, dateOfService, anticipatedDeliveryDate } = cell.row.original;
        if (authStartDate && authEndDate) return `${moment(authStartDate).format('L')} - ${moment(authEndDate).format('L')}`;
        if (dateOfService) return moment(dateOfService).format('L');
        if (anticipatedDeliveryDate) return moment(anticipatedDeliveryDate).format('L');
        return '';
    }}
];

const OrderDetails = ({ purchaseOrderId }) => {
    const { detailsByOrder } = useSelector(state => state.order);

    const detailLoading = detailsByOrder[purchaseOrderId] && detailsByOrder[purchaseOrderId].loading;

    if (detailLoading) {
        return (
            <Grid container style={{justifyContent: 'center'}}>
                <Grid item>
                    <CircularProgress style={{marginTop:'25px'}}/>
                </Grid>
            </Grid>
        );
    }
    
    if (detailsByOrder[purchaseOrderId] && detailsByOrder[purchaseOrderId].length) {
        return (
            <Grid item xs={12}>
                <DataTable
                    loading={false}
                    columns={DETAIL_COLUMNS}
                    data={detailsByOrder[purchaseOrderId]} />
            </Grid>
        );
    } else {
        return <></>;
    }
}

export const ClaimOrders = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const dispatch = useDispatch();
    const { claimId } = useParams();
    const { orders, detailsByOrder } = useSelector(state => state.order);

    useEffect(() => {
        dispatch(loadOrders(claimId));
    }, [dispatch, claimId]);

    useEffect(() => {
        if (orders && orders.length) {
            dispatch(loadOrderDetails({ purchaseOrderId: orders[0].purchaseOrderId }));
        }
    }, [dispatch, orders]);
    
    const detailLoading = (purchaseOrderId) => {
        return detailsByOrder[purchaseOrderId] && detailsByOrder[purchaseOrderId].loading;
    }

    const detailLoaded = purchaseOrderId => {
        return detailsByOrder[purchaseOrderId] && !detailsByOrder[purchaseOrderId].loading;
    }

    const onAccordionExpand = (order) => (event, isExpanded) => {
        if (isExpanded) {
            if (!detailLoading(order.purchaseOrderId) && !detailLoaded(order.purchaseOrderId)) {
                dispatch(loadOrderDetails({purchaseOrderId: order.purchaseOrderId}));
            }
        }
    };

    if (!orders || !orders.length) return <></>;

    return orders.map((order, i) => {
        return (
            <Accordion key={`PurchaseOrderDetail.${order.purchaseOrderId}`} item={order}
                        defaultExpanded={i === 0} style={{backgroundColor: '#f7f7f7'}}
                        onChange={onAccordionExpand(order)}>
                <AccordionSummary
                    tabIndex="-1"
                    expandIcon={<ExpandMoreIcon className={classes.expandIcon}/>}
                    className={classes.text}>
                    <Typography variant="h6" style={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between' }}>
                        <div className={classes.accordionSummary}>
                            <span style={{
                                fontWeight: 'bold',
                                display: 'inline-block'
                            }}>Authorization:&nbsp;</span>{isXs ? <br></br> : null} {order.authorization}
                        </div>
                        <div className={classes.accordionSummary}>
                            <span style={{
                                fontWeight: 'bold',
                                marginLeft: 'auto'
                            }}>Location:&nbsp;</span>{isXs ? <br></br> : null} {order.street1} {order.street2} {order.city}, {order.state}
                        </div>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <OrderDetails purchaseOrderId={order.purchaseOrderId} />
                        { detailLoaded(order.purchaseOrderId) && <Attachments entityName='PurchaseOrder' entityId={order.purchaseOrderId} /> }
                    </Grid>
                </AccordionDetails>
            </Accordion>);
    });
}