import React from 'react'
import {Switch, Route, Redirect, HashRouter} from 'react-router-dom';

import { PrivateRoute, CenterCircularProgress } from 'common';
import { LoginPage } from 'auth/LoginPage';
import { ForgotPassword } from 'auth/ForgotPassword';
import { ResetPage } from 'auth/ResetPage';
import { Claims } from 'claim';
import { Invoices } from 'invoice';
import { CustomerClaimDetail, VendorClaimDetail } from 'claim';
import { NewReferral } from 'referral/NewReferral';
import { ClientHome } from 'home';
import { VendorInvoiceDetail } from 'invoice/VendorInvoiceDetail';
import { ProfilePage } from 'user/ProfilePage';
import ACHPanel from 'vendor/ACHPanel';
import permissions from 'auth/permissions';
import {ChangeNote} from "./changeNote/ChangeNote";
import {SignUpPage} from "./auth/SignUpPage";

const customerRoutes = (authorized, pending) => (
    <Switch>
        <Route exact path="/" render={() => {
            if (authorized) {
                return <Redirect to='/home'/>;
            }
            else if (!pending) {
                return <Redirect to="/auth/login" />;
            }
            else {
                return <CenterCircularProgress fullScreen />;
            }
        }} />
        <Route path="/auth/login">
            <LoginPage />
        </Route>
        <Route path="/auth/forgot">
            <ForgotPassword />
        </Route>
        <Route path="/auth/reset/:token">
            <ResetPage />
        </Route>
        <PrivateRoute path="/profile">
            <ProfilePage />
        </PrivateRoute>
        <PrivateRoute path="/changeNote">
            <ChangeNote />
        </PrivateRoute>
        <PrivateRoute path="/home">
            <ClientHome />
        </PrivateRoute>
        <PrivateRoute exact path="/claim">
            <Claims />
        </PrivateRoute>
        <PrivateRoute path="/claim/:claimId">
            <CustomerClaimDetail />
        </PrivateRoute>
        <PrivateRoute path="/referral/create">
            <NewReferral />
        </PrivateRoute>
        <Route> {/*  This route must be last to redirect any bad urls */}
            <Redirect to="/home" />
        </Route>
    </Switch>);

const vendorRoutes = (authorized, pending) => (
    <Switch>
        <Route exact path="/" render={() => {
            if (authorized) {
                return <Redirect to='/home'/>;
            }
            else if (!pending) {
                return <Redirect to="/auth/login" />;
            }
            else {
                return <CenterCircularProgress fullScreen />;
            }
        }} />
        <Route path="/auth/login">
            <LoginPage />
        </Route>
        <Route path="/auth/forgot">
            <ForgotPassword />
        </Route>
        <Route path="/auth/reset/:token">
            <ResetPage />
        </Route>
        <PrivateRoute path="/profile">
            <ProfilePage />
        </PrivateRoute>
        <PrivateRoute path="/changeNote">
            <ChangeNote />
        </PrivateRoute>
        <PrivateRoute path="/home">
            <Invoices />
        </PrivateRoute>
        <PrivateRoute exact path="/claim">
            <Claims />
        </PrivateRoute>
        <PrivateRoute exact path="/invoice">
            <Invoices />
        </PrivateRoute>
        <PrivateRoute path="/invoice/:invoiceId">
            <VendorInvoiceDetail />
        </PrivateRoute>
        <PrivateRoute path="/claim/:claimId">
            <VendorClaimDetail />
        </PrivateRoute>
        <PrivateRoute path="/referral/create">
            <NewReferral />
        </PrivateRoute>
        <PrivateRoute requiredPermission={permissions.ManageVendorACH} path="/ach">
            <ACHPanel />
        </PrivateRoute>
        <Route> {/*  This route must be last to redirect any bad urls */}
            <Redirect to="/home" />
        </Route>
    </Switch>);

const unauthorizedRoutes = (authorized, pending) => (
    <Switch>
        <Route exact path="/external">
            <HashRouter>
                <Route path="/web/referral">
                    <NewReferral />
                </Route>
            </HashRouter>
        </Route>
        <Route exact path="/" render={() => {
            if (authorized) {
                return <Redirect to='/home'/>;
            }
            else if (!pending) {
                return <Redirect to="/auth/login" />;
            }
            else {
                return <></>;
            }
        }} />
        <Route path="/auth/login">
            <LoginPage />
        </Route>
        <Route path="/auth/forgot">
            <ForgotPassword />
        </Route>
        <Route path="/auth/reset/:token">
            <ResetPage />
        </Route>
        <Route path="/auth/signup">
            <SignUpPage />
        </Route>
        <Route path="/web/referral">
            <NewReferral />
        </Route>
    </Switch>);

export const getDynamicRoutes = (userType, authorized, pending) => {
    if (userType === 'Customer') {
        return customerRoutes(authorized, pending);
    }
    else if (userType === 'Vendor') {
        return vendorRoutes(authorized, pending);
    }
    else {
        return unauthorizedRoutes(authorized, pending);
    }
}