import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, Card, CardContent, CardActions, Typography, TextField, Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { forgotPassword, actions } from './authSlice';
import { actions as appActions } from 'appSlice';

const { resetForgotForm } = actions;
const { infoMessage } = appActions;

const useStyles = makeStyles(theme => ({
    alert: {
        marginTop: '40px'
    },
    card: {
        width: '100%'
    },
    content: {
        padding: '5px',
        backgroundColor: theme.palette.primary.main,
        paddingBottom: '5px !important'
    },
    actions: {
        marginTop: '5px'
    },
    header: {
        color: theme.colors.white,
        marginLeft: '5px',
        paddingTop: '5px',
        paddingBottom: '10px'
    },
    textField: {
        width: '100%',
        marginBottom: '10px'
    },
    logo: {
        width: '300px',
        marginTop: '80px',
        marginBottom: '80px'
    },
    logoDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    form: {
        backgroundColor: theme.colors.white,
        padding: '10px'
    }
}));

function ForgotPassword() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { handleSubmit, register, watch, setValue, errors } = useForm();
    const { pending, complete } = useSelector(state => state.auth.forms.forgot);
    const history = useHistory();
    const location = useLocation();
    
    const username = watch('username');
    const onSubmit = ({username}) => dispatch(forgotPassword(username));

    const handleCancel = () => {
        history.push({
            pathname: 'login'
        })
    }

    useEffect(() => {
        const { username } = location.state || {};
        setValue('username', username);
    }, [location, setValue]);

    useEffect(() => {
        if (complete) {
            dispatch(infoMessage('Check email for reset link'));
            dispatch(resetForgotForm());
            history.push('/auth/login');
        }
    }, [complete, dispatch, history]);

    return (
        <Container maxWidth="xs" className={classes.container}>
            <div className={classes.logoDiv}>
                <img alt="Logo" className={classes.logo} src="https://cdn.homecareconnect.com/images/paradigm_orange_logo.svg"/>
            </div>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Typography className={classes.header} variant="h5">
                        CareLink
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <TextField 
                            className = {classes.textField} 
                            name = "username" 
                            label = "Email" 
                            inputRef = {register({
                                required: "required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            error = { !!errors.username }
                            helperText = { errors.username && errors.username.message }
                        />
                        <br/>
                        <TextField 
                            className = {classes.textField} 
                            name = "confirmUsername" 
                            label = "Confirm Email" 
                            inputRef = {register({
                                required: "required",
                                validate: value => value !== username ? 'Does not match' : undefined
                            })}
                            error = { !!errors.confirmUsername }
                            helperText = { errors.confirmUsername && errors.confirmUsername.message }
                        />
                        <CardActions className={classes.actions}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Button
                                    disabled={ pending }
                                    onClick={handleCancel}
                                    variant="contained"
                                    color="default"
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    disabled = { pending }
                                    type = "submit" 
                                    variant = "contained" 
                                    color = "secondary"
                                >
                                    {pending ? 'Resetting...' : 'Reset'}
                                </Button>
                            </Grid>
                        </CardActions>
                    </form>
                </CardContent>
            </Card>
        </Container>
    );
}


export { ForgotPassword }