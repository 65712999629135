export const toSentenceCase = (string) => {
    if (string && string.length >= 1) {
        return string.substring(0, 1).toUpperCase() + string.substring(1).toLowerCase();
    }

    return string;
}

export const formatPhone = (string) => {
    let digits = string.replace(/\D/g, ''); // strip non-digits
    if (digits.length === 10) {
        return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6)}`;
    }
    return string;
}