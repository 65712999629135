import React, { useState } from 'react'
import { 
    Paper,
    Checkbox,
    Table, 
    TableHead,
    TableCell,
    TableBody, 
    TableRow, 
    TableContainer,
    TablePagination,
    TableSortLabel,
    Typography,
    useMediaQuery, 
    Backdrop} from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useTable, useSortBy } from 'react-table';

import PaginationActions from './PaginationActions';
import { CenterCircularProgress } from 'common/CenterCircularProgress';

const useStyles = makeStyles(theme => {
    return {
        table: {
            minWidth: 650,
            '& tr': {
                '& th': { 
                    fontWeight: 'bold',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.colors.white
                }
            },
            '& tbody tr:hover': {
                backgroundColor: "#DCDCDD !important"
            },
            '& span': { 
                color: 'white !important'
            },
            '& td': { 
                color: theme.typography.color
            },
            '& svg': {
                color: 'white !important'
            }
        },
        loading: {
            opacity: '0.5'
        },
        hidden: {
            display: 'none'
        }
    };
});

const StripedTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#F5F5F6'
        }
    }
}))(TableRow);

function DataTable({ 
            columns,
            data = [],
            loading,
            totalCount,
            sort,
            currentPage,
            onPageChange,
            rowsPerPage,
            rowsPerPageOptions = [25, 50, 100],
            onRowsPerPageChange,
            onSortChange,
            onRowClick,
            labelRowsPerPage,
            boldRowIndexes = [],
            noResultsText = 'No results',
            selectable = false,
            hideHeader = false,
            hideLoadingIndicator = false,
            hidePagingBar = false }) {

    const tableInstance = useTable({
        columns,
        data },
        useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    const [selected, setSelected] = useState([]);
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    const toggleSelectAll = (event) => {        
        if (selected.length < data.length) {
            setSelected(data.map(d => d.id));
        }
        else {
            setSelected([]);
        }
    }

    const toggleSelect = (event) => {
        const newSelected = selected.slice();
        const id = event.target.id && parseInt(event.target.id);
        const selectedIndex = newSelected.indexOf(id);

        if (selectedIndex === -1) {
            newSelected.push(id);
        }
        else {
            newSelected.splice(selectedIndex, 1);
        }

        setSelected(newSelected);
    }

    const getSortHandler = (property) => (event) => {
        onSortChange(property);
    };

    const handleRowClick = (event) => {
        return onRowClick(event.target.closest('tr').rowIndex - 1);
    }

    const rowClickProps = onRowClick
        ? { onClick: handleRowClick, style: { cursor: 'pointer' }}
        : {};
    
    const maybeComponent = hideHeader ? {} : { component: Paper };

    const getCellStyle = (column) => {
        const style = {};
        if (column.width) {
            style.width = column.width;
        }
        if (column.minWidth) {
            style.minWidth = column.minWidth;
        }
        return { style };
    }

    return (
        <>
        {!hideLoadingIndicator && 
            <Backdrop open={loading} style={{zIndex: 999, backgroundColor: 'rgba(0,0,0,0.1)'}}>
                <CenterCircularProgress fullScreen />
            </Backdrop>}
        <TableContainer {...maybeComponent} className={loading ? classes.loading : ''}>
            <Table className={classes.table} {...getTableProps()}>
                <TableHead className={hideHeader ? classes.hidden : ''}>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            { selectable && 
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length < data.length && selected.length > 0}
                                        checked={!loading && selected.length === data.length}
                                        onChange={toggleSelectAll} />
                                </TableCell> }
                            {headerGroup.headers.map(column => {
                                const columnProps = !!sort 
                                    ? column.getHeaderProps(column.getSortByToggleProps())
                                    : column.getHeaderProps();
                                
                                return (
                                    <TableCell {...columnProps} {...getCellStyle(column)} >
                                        {sort &&
                                            <TableSortLabel
                                                    icon={ArrowDownwardIcon}
                                                    active={sort.property === column.id}
                                                    direction={sort.direction}
                                                    onClick={getSortHandler(column.id)}>
                                                <Typography>
                                                    {column.render('Header')}
                                                </Typography>
                                            </TableSortLabel> }                                                
                                        {!sort && 
                                            <Typography>
                                                {column.render('Header') }
                                            </Typography>}
                                    </TableCell>
                            )})}
                        </TableRow>
                    ))}
                </TableHead>
                {data.length > 0 && <TableBody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        const style = boldRowIndexes.indexOf(index) >= 0 ? { fontWeight: 'bold' } : {};
                        prepareRow(row)
                        return (
                            <StripedTableRow key={row.values.id} id={row.values.id} {...rowClickProps} hover {...row.getRowProps()}>
                                {selectable && 
                                    <TableCell padding="checkbox" size="small">
                                        <Checkbox
                                            id={""+row.values.id}
                                            checked={selected.indexOf(row.values.id) !== -1}
                                            onChange={toggleSelect} />
                                    </TableCell>}
                                {row.cells.map(cell => {
                                    return (
                                        <TableCell style={style} align="left" {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                    );
                                })}
                            </StripedTableRow>
                        )
                    })}
                </TableBody> }
            </Table>
            {!hidePagingBar && data.length > 0 && !!currentPage && 
                <TablePagination 
                    component="div"
                    rowsPerPageOptions={rowsPerPageOptions}
                    colSpan={3}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={totalCount ? currentPage - 1 : 0}
                    labelRowsPerPage={isXs ? 'Showing' : `${labelRowsPerPage || 'Rows per page'}:`}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true
                    }}
                    onChangePage={onPageChange}
                    onChangeRowsPerPage={onRowsPerPageChange}
                    ActionsComponent={PaginationActions}
                /> }
        </TableContainer>
        {!loading && !data.length && 
            <Typography variant="body1" style ={{ margin: '1rem'}}>
                {noResultsText}
            </Typography>
        }
        </>
    );
}


export { DataTable };