import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Notes from '@material-ui/icons/Notes';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SendIcon from '@material-ui/icons/Send';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ListIcon from '@material-ui/icons/ListAlt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import { logout } from 'auth/authSlice';
import { getProfile } from 'user/userSlice';
import permissions, { usePermissions } from 'auth/permissions';
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles(theme => ({
    logo: {
        height: '36px',
        paddingRight: '15px'
    },
    header: {
        marginBottom: '20px',
        backgroundColor: theme.color
    },
    logoLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit'
    },
    button: {
        marginRight: '10px'
    },
    grow: {
        flexGrow: 1
    },
    toolbar: {
        paddingLeft: '15px'
    },
    menubar: {
        display: 'flex',
        width: '100%',
        marginLeft: '60px'
    },
    menu: {
        width: 250,
        color: '#404040',
        backgroundColor: '#fafafa'
    },
    menuHeader: {
        marginTop: '15px',
        marginLeft: '15px',
        marginBottom: '15px'
    }
}));

export function Header() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { profile } = useSelector(state => state.user);
    const [menuOpen, setMenuOpen] = useState(false);
    const [profileAnchor, setProfileAnchor] = useState(null);
    const { userType } = useSelector(state => state.auth);
    const hasPermission = usePermissions();

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const doLogout = () => {
        setProfileAnchor(null);
        setMenuOpen(false);
        dispatch(logout());
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const openMenu = () => {
        setMenuOpen(true);
    };
;
    const openProfileMenu = (event) => {
        setProfileAnchor(event.currentTarget);
    };

    const closeProfileMenu = () => {
        setProfileAnchor(null);
    };

    let name = '';
    const { firstName, lastName } = profile;
    if (firstName && lastName) {
        name = `${firstName} ${lastName.substr(0,1)}`;
    }

    const renderProfileMenu = (
        <Menu
            anchorEl={profileAnchor}
            open={!!profileAnchor}
            onClose={closeProfileMenu}
        >
            <MenuItem component={Link} to={'/profile'} onClick={closeProfileMenu}>
                <ListItemIcon>
                    <AccountCircle />
                </ListItemIcon>
                My Profile
            </MenuItem>
            <MenuItem component={Link} to={'/changeNote'} onClick={closeProfileMenu}>
                <ListItemIcon>
                    <Notes />
                </ListItemIcon>
                Update History
            </MenuItem>
            <MenuItem onClick={doLogout}>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    );

    const showReferralsLink = userType === 'Customer';
    const showClaimsLink = userType === 'Customer' || userType === 'Vendor';
    const showInvoiceLink = userType === 'Vendor';
    const showACHLink = hasPermission(permissions.ManageVendorACH);

    return (
        <AppBar position="static" className={classes.header}>
            <Toolbar className={classes.toolbar}>
                <Hidden mdUp>
                    <IconButton edge="start" className={classes.button} color="inherit" onClick={openMenu}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer anchor="left" open={menuOpen} onClose={closeMenu}>
                        <div className={classes.menu} role="presentation">
                            <Typography variant="h4" className={classes.menuHeader} color="primary">
                                <Link className={classes.logoLink} to="/home" onClick={closeMenu}>
                                    <img alt="Logo" className={classes.logo} src="https://cdn.homecareconnect.com/images/paradigm_orange_p.svg"/>
                                    CareLink
                                </Link>
                            </Typography>
                            <Divider />
                            <List>
                                {showInvoiceLink && <ListItem button onClick={closeMenu} component={Link} to="/invoice">
                                    <ListItemIcon><MoneyIcon/></ListItemIcon>
                                    <ListItemText primary="Invoices" />
                                </ListItem>}
                                {showReferralsLink && <ListItem button onClick={closeMenu} component={Link} to="/home">
                                    <ListItemIcon><AccessTimeIcon/></ListItemIcon>
                                    <ListItemText primary="Referrals" />
                                </ListItem>}
                                {showClaimsLink && <ListItem button onClick={closeMenu} component={Link} to="/claim">
                                    <ListItemIcon><ListIcon/></ListItemIcon>
                                    <ListItemText primary={userType === 'Vendor' ? 'Orders' : 'Claims'} />
                                </ListItem>}
                                <ListItem button onClick={closeMenu} component={Link} to="/referral/create">
                                    <ListItemIcon><SendIcon/></ListItemIcon>
                                    <ListItemText primary="New Referral" />
                                </ListItem>
                                {showACHLink && <ListItem button onClick={closeMenu} component={Link} to="/ach">
                                    <ListItemIcon><AccountBalanceIcon/></ListItemIcon>
                                    <ListItemText primary="ACH" />
                                </ListItem>}
                                
                                <ListItem button onClick={closeMenu} component={Link} to="/profile">
                                    <ListItemIcon><AccountCircle /></ListItemIcon>
                                    <ListItemText primary="My Profile" />
                                </ListItem>
                                <ListItem button onClick={closeMenu} component={Link} to="/changeNote">
                                    <ListItemIcon><Notes /></ListItemIcon>
                                    <ListItemText primary="Update History" />
                                </ListItem>
                                <ListItem button onClick={doLogout}>
                                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItem>
                            </List>
                        </div>
                    </Drawer>
                </Hidden>
                <Typography variant="h5">
                    <Link className={classes.logoLink} to="/home">
                        <Hidden smDown>
                            <img className={classes.logo} alt="Logo" src="https://cdn.homecareconnect.com/images/paradigm_orange_p.svg"/>
                        </Hidden>
                        CareLink
                    </Link>
                </Typography>
                <div className={classes.menubar}>
                    <Hidden smDown>     
                        {showInvoiceLink && <Button className={classes.button} startIcon={<MoneyIcon/>} color="inherit" component={Link} to="/invoice">
                            Invoices
                        </Button>}
                        {showReferralsLink && <Button className={classes.button} startIcon={<AccessTimeIcon/>} color="inherit" component={Link} to="/home">
                            Referrals
                        </Button>}
                        {showClaimsLink && <Button className={classes.button} startIcon={<ListIcon/>} color="inherit" component={Link} to="/claim">
                            {userType === 'Vendor' ? 'Orders' : 'Claims'}
                        </Button>}
                        <Button className={classes.button} startIcon={<SendIcon/>} color="inherit" component={Link} to="/referral/create">
                            New Referral
                        </Button>
                        {showACHLink && <Button className={classes.button} startIcon={<AccountBalanceIcon/>} color="inherit" component={Link} to="/ach">
                            ACH
                        </Button>}
                    </Hidden>
                    <div className={classes.grow} />
                    <Button endIcon={<AccountCircle/>} color="inherit" onClick={openProfileMenu}>
                        {name}
                    </Button>
                </div>
            </Toolbar>
            {renderProfileMenu}
        </AppBar>
    );
}