import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {CircularProgress, Grid, Card, CardHeader, CardContent} from '@material-ui/core';
import moment from 'moment';

import { PanelItem } from 'common';

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: '-10px',
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    loading: {
        margin: 'auto',
        display: 'block',
        marginTop: '10px'
    }
}));

function InvoiceCard({invoice}) {
    const classes = useStyles();

    return (
        !invoice ? <CircularProgress className={classes.loading}/> :
            <>
                <PanelItem fullWidth label="Invoice Number" value={invoice.invoiceNumber}/>
                <PanelItem fullWidth label="Invoice Total" value={'$ ' + invoice.invoiceTotal.toFixed(2)}/>
                <PanelItem fullWidth label="Invoice Date"
                      value={!invoice.invoiceDate ? '' : moment(invoice.invoiceDate).format('L')}/>
                <PanelItem fullWidth label="Pay To" value={invoice.invoicePayTo ? invoice.invoicePayTo : invoice.vendorName}/>
                <PanelItem fullWidth label="Pay To Address" value={`${invoice.payToAddressLine1}`} value2={`${invoice.payToAddressLine2}`} hasToolTip={false}/>
                <PanelItem fullWidth label="Payment Terms" value={invoice.invoicePaymentTerms}/>
                <PanelItem fullWidth label={invoice.paymentDateLabel} value={moment(invoice.paymentDate).format('L')}/>
            </>
    )
};

function PatientCard({invoice}) {

    const classes = useStyles();

    return (
        !invoice ? <CircularProgress className={classes.loading}/> :
            <>
                <PanelItem fullWidth label="Insured ID" value={invoice.claimNumber}/>
                <PanelItem fullWidth label="Patient Name" value={invoice.patientName}/>
                <PanelItem fullWidth label="Patient DOB" value={!invoice.patientBirthdate ? '' : moment(invoice.patientBirthdate).format('L')}/>
            </>
    )
}

function InvoiceInfo({invoice}) {

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Invoice" className={classes.header}/>
                    <CardContent>
                        <Grid container>
                            <InvoiceCard invoice={invoice}/>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Injured Worker" className={classes.header}/>
                    <CardContent>
                        <Grid container>
                            <PatientCard invoice={invoice}/>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export {InvoiceInfo};