import React from 'react'

import { Container, Typography } from '@material-ui/core';
import Referrals from 'referral/Referrals';

function ClientHome() {
    return (
        <Container maxWidth="md">
            <Typography variant="h4" color="primary" style={{ marginBottom: '10px' }}>
                My Referrals
            </Typography>
            <Referrals />
        </Container>
        
    );
}

export { ClientHome };