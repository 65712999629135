import React from 'react' // importing React is required for using jsx in a file
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

export const appSlice = createSlice({
    name: 'app', 
    initialState: {
        message: false
    },
    reducers: {
        infoMessage: (state, action) => {
            state.message = action.payload;
            state.messageType = 'info';
        },
        clientErrorMessage: (state, action) => {
            state.message = action.payload;
            state.messageType = 'error';
        
        },
        serverErrorMessage: state => {
            state.message = (
                <p> An unexpected error has occurred. If this continues to happen please contact &nbsp;
                    <a href={`mailto:ITSupport@homecareconnect.com?subject=${encodeURI("CareLink Portal Error " + moment().format('YYMMDDHHmmss'))}`}>ITSupport@homecareconnect.com</a>
                </p>);
            state.messageType = 'error';
        },
        clearMessage: state => {
            state.message = false;
        }
    }
});

export const { actions, reducer } = appSlice;
export default reducer;