import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { DataTable } from 'common';
import { findReferrals } from './referralSlice';

const REFERRAL_COLUMNS = [
    { Header: 'Claim Number', accessor: 'claimNumber' },
    { Header: 'Referral Number', accessor: 'id', Cell: cell => !!cell.value ? `R${cell.value}` : (cell.row.original.cancelled ? `CANCELLED ${cell.row.original.cancelledReason}`: 'PENDING')},
    { Header: 'Patient Name', accessor: 'patientName', Cell: cell => {
        return `${cell.row.original.patientFirstName} ${cell.row.original.patientLastName}`; }
    },
    { Header: 'Service', accessor: 'service' },
    { Header: 'Created Date', accessor: 'createdDate', Cell: cell => cell.value && moment(cell.value).format('LL')  }
]

function Referrals() {
    const dispatch = useDispatch();
    const { referrals, loading } = useSelector(state => state.referral);
    const history = useHistory();

    useEffect(() => {
        dispatch(findReferrals());
    }, [dispatch]);

    const onRowClick = (selectedIndex) => {
        if (selectedIndex >= 0) {
            const referral = referrals[selectedIndex];
            if (!!referral.claimId) {
                history.push(`/claim/${referral.claimId}`);
            }
        }
    }

    return (
        <DataTable 
            loading = { loading }
            columns = { REFERRAL_COLUMNS }
            data = { referrals }
            onRowClick = { onRowClick }
        />
    )
}

export default Referrals;