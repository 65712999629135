import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import store from './store';

import App from './App';
const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(0,104,158)'
        },
        secondary: {
            main: '#00bed6',
           contrastText: 'white',
        }
    },
    typography: {
        color: '#243746',
        fontFamily: 'Scandia,Arial,sans-serif',
        fontWeightRegular: '500'
    },
    colors: {
        lightGrey: 'rgb(118,120,122)',
        white: 'white'
    }
});

document.body.style.backgroundColor = "#f4f4f4";
document.body.style.margin = '0';

ReactDOM.render(
    // <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme} >
                <App />
            </ThemeProvider>
        </Provider>    ,
    // </React.StrictMode>,
    document.getElementById('root')
);