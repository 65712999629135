import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Container,
    Grid,
    Paper,
    Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { HeroPanel } from "./HeroPanel";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'block',
        boxSizing: 'border-box',
        margin: '50px auto',
        maxWidth: '960px',
    },
    paper: {
        padding: '50px 80px 40px 80px',
        '@media print': {
            boxShadow: 'none',
            padding: '0 15px',
        }
    },
    topMessage: {

    },
    topWarning: {
        fontSize: '15px',
        fontFamily: 'Scandia,Arial,Helvetica,sans-serif !important',
        fontWeight: '500!important',
        color: '#e04403!important'    
    },
    sectionHeading: {
        color: '#243746',
        fontFamily: 'Scandia,Arial,Helvetica,sans-serif',
        fontWeight: '600',
        fontSize: '1.25rem',
        lineHeight: '1.5',
        letterSpacing: 'normal',
        marginTop: '20px',
        marginBottom: '7px',
    },
    sectionBreak: {
        backgroundColor: '#7f7f7f',
        border: 'none',
        height: '1px',
        opacity: '0.25',
    },
    printPageBreak: {
        '@media print': {
            pageBreakBefore: 'always',
        }
    },
    printHide: {
        '@media print': {
            display: 'none',
        }
    },
    detailHead: {
        marginBottom: '15px',
        '@media print': {
            marginBottom: '8px',
        }
    },
    detailRow: {
        marginTop: '3px',
        marginBottom: '10px'
    },
    detailLabel: {
        color: '#7f7f7f',
    },
    fileSection: {

    },
    fileItem: {
        padding: '0 0 4px 0',
    },
    alert: {
        marginTop: '20px',
        marginBottom: '20px'
    },
   btn: {
        fontSize: '20px',
        margin: '25px 0 0 12px',
        padding: '2px 40px',
        borderRadius: '25px',
        textTransform: 'none',
        '& span.MuiButton-label': {
            color: 'white',
            lineHeight: '1.75',
            fontWeight: '600'
        }
    },
    printBtn: {
        margin: '0',
        borderRadius: '15px',
        textTransform: 'none',
        lineHeight: 'normal',
    },
   logo: {
        width: '260px',
        marginTop: '40px',
        marginBottom: '20px'
    },
    logoDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    }
}));

function emptyMarker(field) {
    if (field && field !== '') {
        return field;
    } else {
        return '-';
    }
}

function formatHeight(heightFt, heightIn) {
    let formattedHeight = '-';

    if (heightFt && heightFt.length > 0) {
        formattedHeight = heightFt + ' ft';
    }
    if (heightIn && heightIn.length > 0) {
        if (formattedHeight.length > 1) {
            formattedHeight += ' ' + heightIn + ' in';
        } else {
            formattedHeight = heightIn + ' in';
        }
   }
    return formattedHeight ;
}

function formatWeight(wtLbs) {
    if (wtLbs && wtLbs.length > 0) {
        return wtLbs + ' lbs';
    } else {
        return '-';
    }
}

function handlePrint() {
    window.print();
}

function buildDetailArray(newReferral, detailHighCount) {
    let details = [];

    for ( let seq=1; (seq <= detailHighCount); seq++) {
        let service = 'service-' + seq;
        if (newReferral[service]) {
            let detail = {};
            detail['service'] = newReferral[service];
            detail['qty']  = newReferral['quantity-' + seq];
            detail['desc'] = newReferral['desc-' + seq];
            detail['date'] = newReferral['date-' + seq]
            details.push(detail);
        }
    };
    return details ;
}

function ConfirmNewReferral({newReferral, claim, showProvider, showRequestor, detailHighCount, files, handleConfirm, handleCancel}) {
    const classes = useStyles();
    const refDetails = buildDetailArray(newReferral, detailHighCount);
    const XS_LABEL=3;
    const XS_DATA=9;
    const XS_DETAIL=8;
    const MD_LABEL=2;
    const MD_DATA=4;
    const MD_DATA_FULLLINE=10;
    const DME_LABEL = 'DME, Prosthetics, Home & Vehicle Modifications';

    const [agreeChecked, setAgreeChecked] = useState(false);

    return (
        <Container maxWidth="md" className={classes.container} >
            <HeroPanel showMessages={false} />
            <Paper elevation={6} className={classes.paper} >
                <Grid container spacing={2} >
                    <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.topWarning} >
                            Please review the details below before submitting to Paradigm Care at Home.
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" 
                            className={`${classes.printBtn} ${classes.printHide}`}
                            color="secondary" size="small" onClick={handlePrint}
                        >Print</Button>
                    </Grid>
                </Grid>
                {showProvider && (
                    <>
                        <Typography variant="h6" className={classes.sectionHeading} >
                            Provider Service Location
                        </Typography>
                        <Grid container spacing={1} >                         
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Name:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA_FULLLINE} >
                                {emptyMarker(newReferral.vendorLocation?.name)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Street:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA_FULLLINE} >
                                {emptyMarker(newReferral.vendorLocation?.street1)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                City:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA_FULLLINE} >
                                {newReferral.vendorLocation?.city}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                State:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.vendorLocation?.state)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Zip:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.vendorLocation?.zip)}
                            </Grid>
                        </Grid>       
                        <hr className={classes.sectionBreak} />
                    </>)
                }
                {showRequestor && (
                    <>
                        <Typography variant="h6" className={classes.sectionHeading} >
                            Requestor
                        </Typography>
                        <Grid container spacing={1} >                         
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                First name:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {newReferral.requestorFirstName}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Last name:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {newReferral.requestorLastName}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Email:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.requestorEmail)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Phone:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.requestorPhone)}
                            </Grid>
                        </Grid>       
                        <hr className={classes.sectionBreak} />
                    </>)
                }
                <Typography variant="h6" className={classes.sectionHeading} >
                    Injured Worker
                </Typography>
                <Grid container spacing={1} >                         
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        First name:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {newReferral.patientFirstName}
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Last name:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {newReferral.patientLastName}
                    </Grid>
                    {!claim && (
                        <>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Birth Date:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientBirthDate)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                SSN:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientSSN)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Height:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {formatHeight(newReferral.patientHeightFt, newReferral.patientHeightIn)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Weight:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {formatWeight(newReferral.patientWeight)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Gender:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA_FULLLINE} >
                                {newReferral.patientGender === 'M' ? 'Male' 
                                    : newReferral.patientGender === 'F' ? 
                                        'Female' : 'Unspecified'
                                }
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Address:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientAddress)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                City:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientCity)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                State:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientState?.name)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Zip:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientZip)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Home Phone:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientHomePhone)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Cell Phone:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientCellPhone)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Email:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.patientEmail)}
                            </Grid>
                        </>)
                    }
                </Grid>
                <hr className={classes.sectionBreak} />
                {!claim ? (
                    <Typography variant="h6" 
                        className={`${classes.sectionHeading} ${classes.printPageBreak}`} >
                        Physician
                    </Typography>
                ) : (
                    <Typography variant="h6" className={classes.sectionHeading} >
                        Physician
                    </Typography>
                )}
                <Grid container spacing={1} >                         
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        First Name:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {emptyMarker(newReferral.physicianFirstName)}
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Last Name:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {emptyMarker(newReferral.physicianLastName)}
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Office Phone:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {emptyMarker(newReferral.physicianOfficePhone)}
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Fax:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {emptyMarker(newReferral.physicianFax)}
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Email:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {emptyMarker(newReferral.physicianEmail)}
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        NPI:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {emptyMarker(newReferral.physicianNPI)}
                    </Grid>
                </Grid>
                <hr className={classes.sectionBreak} />
                <Typography variant="h6" className={classes.sectionHeading} >
                    Claim
                </Typography>
                <Grid container spacing={1} >                         
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Claim Number:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        {newReferral.claimNumber}
                    </Grid>
                    {!claim && (
                        <>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Injury Date:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {newReferral.injuryDate}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Employer:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {newReferral.employer}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Phone:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.employerPhone)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Injury Details:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA_FULLLINE} >
                                {emptyMarker(newReferral.injury)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Surgery Date:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.surgeryDate)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Discharge Date:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.dischargeDate)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Hospital:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.hospital)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Phone:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.hospitalPhone)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Customer:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {newReferral.customerName}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Adjuster:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {newReferral.adjusterName}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Phone:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.adjusterPhone)}
                            </Grid>
                            <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                                Email:
                            </Grid>
                            <Grid item xs={XS_DATA} md={MD_DATA} >
                                {emptyMarker(newReferral.adjusterEmail)}
                            </Grid>
                        </>)
                    }
                </Grid>
                <hr className={classes.sectionBreak} />
                {(!claim && refDetails.length < 2) ? (
                    <Typography variant="h6" className={classes.sectionHeading} >
                        Referral
                    </Typography>
                ) : (
                    <Typography variant="h6" 
                        className={`${classes.sectionHeading} ${classes.printPageBreak}`} >
                        Referral
                    </Typography>
                )}
                <Grid container spacing={1} className={classes.detailHead}>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Instructions:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA_FULLLINE} >
                        {emptyMarker(newReferral.instructions)}
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Rush:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        { newReferral.rush  ? 'Yes' : '-' }
                    </Grid>
                    <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                        Price Quote:
                    </Grid>
                    <Grid item xs={XS_DATA} md={MD_DATA} >
                        { newReferral.quote  ? 'Yes' : '-' }
                    </Grid>
                </Grid>
                {(refDetails.map((det, idx)  => (
                    <Grid container spacing={1} item xs={12} md={10} key={idx} className={classes.detailRow}>
                        {( idx > 0 ? (
                            <Grid item xs={11} md={12}>
                                <hr className={classes.sectionBreak} />
                            </Grid>)
                            : ''
                        )}
                        <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                            Service:
                        </Grid>
                        <Grid item xs={XS_DETAIL} md={4}>
                            {det.service.type === 'DME' ? DME_LABEL : det.service.name}
                        </Grid>
                        <Grid item xs={XS_LABEL} md={1} className={classes.detailLabel} >
                            Date:
                        </Grid>
                        <Grid item xs={XS_DETAIL} md={2}>
                            {det.date}
                        </Grid>
                        <Grid item xs={XS_LABEL} md={2} className={classes.detailLabel} >
                            Quantity:
                        </Grid>
                        <Grid item xs={XS_DETAIL} md={1}>
                            {det.qty}
                        </Grid>
                        <Grid item xs={XS_LABEL} md={MD_LABEL} className={classes.detailLabel} >
                            Description:
                        </Grid>
                        <Grid item xs={XS_DETAIL} md={MD_DATA_FULLLINE} >
                            {det.desc}
                        </Grid>
                    </Grid>
                    ))
                )}
                <hr className={classes.sectionBreak} />
                {files.length > 0 && (
                    <>
                        <Typography variant="h6"
                            className={`${classes.sectionHeading} ${classes.printPageBreak}`} >
                            Attachments
                        </Typography>
                        <Grid container spacing={1} item xs={12} md={12} className={classes.fileSection} >
                            <ul>
                            {(files.map((file, idx) => (
                                <li key={idx} className={classes.fileItem} >
                                    {file.name}
                                </li>
                            )))}
                            </ul>
                        </Grid>
                        <hr className={classes.sectionBreak} />
                    </>
                )}
                <Grid container spacing={2} >
                    <Grid item xs={1}>
                        <Checkbox name="agree" checked={agreeChecked} onChange={() => setAgreeChecked(!agreeChecked)} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="subtitle1" className={classes.topWarning} >
                            By submitting this form, you agree that any information you submit with this referral form
                            may be disclosed by Paradigm to third parties for the purposes of providing Paradigm’s
                            services to you, and you represent that you have the necessary consents to provide such
                            information to Paradigm.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item direction="row" justify="flex-end" xs={12} className={classes.printHide}>
                    <Button onClick={handleCancel} className={classes.btn} variant="contained" color="secondary">
                        Make Changes</Button>
                    <Button onClick={handleConfirm} 
                            disabled = {!agreeChecked}
                            className={classes.btn} 
                            variant="contained" 
                            color="secondary"
                    >
                        Submit Form</Button>
                </Grid>
            </Paper>
        </Container>
    )
}

export { ConfirmNewReferral };


