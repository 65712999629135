import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import './ParadigmNavbar.css';

const useStyles = makeStyles(theme => ({
    topSearchMenu: {
        padding: '0',
        fontFamily: 'Scandia,Arial,Helvetica,sans-serif',
        
    },
    sideSearchMenu: {
        padding: '0',
        fontFamily: 'Scandia,Arial,Helvetica,sans-serif',
        display: 'flex',
        justifyContent: 'space-between',
    },
    searchToggle: {
        color: '#7f7f7f',
        fontFamily: 'Scandia,Arial,Helvetica,sans-serif',
        '@media (min-width: 768px)': {
            display: 'inline-block',
            margin: '0 10px 0 0'
        },
        '@media (max-width: 767px)': {

        },

        '&:hover': {
            color: '#e04403',
        },
    },
    searchToggleLink: {
        color: '#7f7f7f',
        fontFamily: 'Scandia,Arial,Helvetica,sans-serif',
        textDecoration: 'none',
        padding: '0 3px 0 0',
        '&:hover': {
            color: '#e04403',
        },
    },
    magnifyingGlass: {
        display: 'inline-block',
        width: '29px',
        height: '20px',
        backgroundImage: 'url(/magnifying_glass.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center 7px',
        '&:hover': {
            backgroundImage: 'url(/magnifying_glass_orange.svg)',
        }
    },
    referLink: {
        position: 'relative',
        right: '-15px !important',
        margin: '0',
        padding: '2px',
        lineHeight: '1.6rem',
        fontFamily: 'Scandia,Arial,Helvetica,sans-serif',
        fontSize: '0.95rem',
        fontWeight: '500',
        display: 'inline-block',
        width: '130px',
        borderRadius: '25px',
        textAlign: 'center',
        textTransform: 'none',
        textDecoration: 'none',
        backgroundColor: '#e04403',
        color: 'white',
        '@media (min-width: 1200px)': {
            right: '-30px !important',
        },
        '&:hover': {
            color: 'white !important',
            backgroundColor: '#e04403',
        },
    },
    sideReferLink: {
        margin: '0',
        padding: '1px',
        fontSize: '0.95rem',
        lineHeight: '2rem',
        fontWeight: '500',
        display: 'inline-block',
        height: '30px',
        width: '146px',
        borderRadius: '25px',
        textAlign: 'center',
        textTransform: 'none',
        textDecoration: 'none',
        backgroundColor: '#e04403',
        color: 'white',
        '&:hover': {
            backgroundColor: '#e04403',
        },
    },
}));

function SearchToggle() {
    const classes = useStyles();
 
    return (
        <div className={classes.searchToggle}>
            <a className={classes.searchToggleLink}
                href="https://referrals.paradigmcorp.com/#/" >Search</a>
            <span className={classes.magnifyingGlass}></span>
        </div>
    );
}

function ParadigmTopSearch({ orderFor }) {
    const classes = useStyles();
    
    return ( 
        <div className={(orderFor === 'side') ? classes.sideSearchMenu : classes.topSearchMenu} >
            {(orderFor === 'side') &&
                <>
                    <a className={classes.sideReferLink} href="https://referrals.paradigmcorp.com/" >
                        Refer a case</a>
                    <SearchToggle />
                </>
            }           
            {(orderFor !== 'side') &&
                <>
                    <SearchToggle />
                    <a className={classes.referLink} href="https://referrals.paradigmcorp.com/" >
                        Refer a case</a>
                </>
            }
        </div>
    );
}

export { ParadigmTopSearch };
