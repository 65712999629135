import React from 'react'
import { Grid, Card, CardHeader, CardContent, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { toSentenceCase, formatPhone } from 'common/utilities';
import { PanelItem } from 'common';

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: '-10px',
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    loading: {
        margin: 'auto',
        display: 'block',
        marginTop: '10px'
    }
}));

function getStreet(street1, street2) {
    if (street1 && street2) { return `${street1} ${street2}` }
    else if (street1) { return street1 }
    else { return '' }
}

function PatientCard({ loading, claim, patientPhones }) {

    const classes = useStyles();
    if (!loading && !claim) {
        return ''
    }
    else if (loading) {
        return <CircularProgress className={classes.loading} />;
    }
    else {
        const zip = claim.patientZip && claim.patientZip.length === 9 ? `${claim.patientZip.substring(0, 5)}-${claim.patientZip.substring(5)}` : claim.patientZip;
        return (
            <>
            <PanelItem fullWidth label="Claim Number" value={claim.claimNumber} />
            <PanelItem label="DOI" value={!claim.injuryDate ? '' : moment(claim.injuryDate).format('L')} />
            <PanelItem fullWidth label="Employer" value={claim.employerName} hasToolTip={true} />
            <PanelItem label="Jurisdiction" value={claim.jurisdiction} />
            <PanelItem label="Name" value={`${claim.patientFirstName} ${claim.patientLastName}`} hasToolTip={true} />
            <PanelItem label="DOB" value={!claim.patientBirthDate ? '' : moment(claim.patientBirthDate).format('L')} />
            <PanelItem label="Language" value={claim.patientLanguage} />
            <PanelItem label="Height" value={claim.patientHeight ? `${Math.floor(claim.patientHeight / 12)} ft ${Math.floor(claim.patientHeight % 12)} in` : ''} />
            <PanelItem label="Weight" value={claim.patientWeight ? `${claim.patientWeight} lbs` : ''} />
            <PanelItem label="Street" fullWidth value={getStreet(claim.patientStreet1, claim.patientStreet2)} />
            <PanelItem label="City, State" value={claim.patientCity && claim.patientState && `${claim.patientCity}, ${claim.patientState}`} hasToolTip={true} />
            <PanelItem label="Zip" value={zip} />
            {patientPhones && patientPhones.map(phone => {
                return <PanelItem key={`phone-${phone.id}`} fullWidth isPhone label={`Patient ${toSentenceCase(phone.type)}`} value={formatPhone(phone.contactNumber)} subLabel = {phone.preferred ? 'preferred' : false} />
            })}
            </>);
    }
}

function ClaimInfo({ claim, patientPhones, loading }) {

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Injured Worker" className={classes.header} />
                    <CardContent>
                        <Grid container>
                            <PatientCard loading={loading} claim={claim} patientPhones={patientPhones} />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export { ClaimInfo };