import { useSelector } from 'react-redux';

export default {
    ManageVendorACH: 'ManageVendorACH'
};

function usePermissions() {
    const { permissions } = useSelector(state => state.auth);

    return (permission) => {
        return permissions && permissions.includes(permission);
    }
}

export {
    usePermissions
};