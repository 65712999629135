import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';
import { doGet, doPost } from 'common/fetch';
import { getReadableSize, readFileAsync } from 'common/fileUtilities';

export const loadAttachmentTypes = createAsyncThunk(
    'attachment/loadAttachmentTypes',
    async ({ entityName, forceRefresh = false}, { getState, rejectWithValue }) => {
        
        const { attachment: { attachmentTypes }} = getState();
        if (!attachmentTypes[entityName] || forceRefresh) {
            const { data: types, response } = await doGet({
                path: 'attachment/loadAttachmentTypes',
                params: { attachmentEntityName: entityName }
            });

            if (response.ok) {
                return types;
            }
            return rejectWithValue(false);
        }
        return Promise.resolve(attachmentTypes[entityName]);
    }
);


export const getAttachments = createAsyncThunk(
    'attachment/getAttachments',
    async ({entityName, entityId}, { getState, rejectWithValue }) => {
        if (!entityName || !entityId) {
            return rejectWithValue('Invalid entity');
        }

        const { attachment: { metadata }} = getState();
        if (!metadata) { throw new Error("Missing metadata"); }
        const { limit, page, sortDirection, sortProperty } = metadata[entityId];
    
        const path = entityName === 'VendorBill' ? 
            'attachment/loadInvoiceAttachments' :
            'attachment/loadByEntity';
        
        const params = entityName === 'VendorBill' ? 
            { invoiceId: entityId } : 
            { entityId, entityName, limit, page, sortDirection, sortProperty };
        

        const { data, response } = await doGet({ path, params });
        
        if (response.ok) {
            return {
                attachments: data.data,
                totalCount: data.total 
            };
        }
        return rejectWithValue(entityId);
    }
);

export const addPendingAttachment = createAsyncThunk(
    'attachment/addPendingAttachment',
    async ({ newAttachments, entityId, entityName }, { rejectWithValue }) => {
        if (newAttachments.length) {
            const largeAttachments = newAttachments.filter(a => a.size > (1024*1024*20));
            if (largeAttachments.length > 0) {
                return rejectWithValue({
                    entityId,
                    error: `The following file${ largeAttachments.length === 1 ? 's are ' : ' is '} too big: ${largeAttachments.map(a => a.name).join(', ')}`
                })
            }

            const files = newAttachments.map(async a => {
                return {
                    entityId, 
                    id: uuidv4().replace(/-/g,''),
                    name: a.name,
                    length: a.size,
                    contentType: a.type,
                    size: getReadableSize(a.size),
                    data: await readFileAsync(a)
                }
            });

            return Promise.all(files);
        }

        return rejectWithValue({
            entityId,
            error: 'No attachments to add'
        });
    }
);

export const uploadAttachment = createAsyncThunk(
    'attachment/uploadAttachment',
    async ({ attachments, entityName, entityId }, { dispatch, rejectWithValue }) => {
        const { response, data } = await doPost({
            path: `attachment/uploadAttachment?attachmentEntityId=${entityId}&attachmentEntityName=${entityName}`,
            params: attachments
        });
        if (response.ok) {
            dispatch(getAttachments({ entityName, entityId }));
            return data;
        }

        return rejectWithValue(data);
    }
);

export const attachmentSlice = createSlice({
    name: 'attachment',
    initialState: {
        attachmentTypes: {},
        attachmentTypesPending: false,
        pendingAttachments: {},
        pendingErrors: {},
        uploadPending: false,
        uploadDirty: false,
        loadPending: {},
        attachments: {},
        uploadErrors: {},
        metadata: {}
    },
    reducers: {
        removePendingAttachment: (state, action) => {
            const { attachmentId, entityId } = action.payload;
            const pendingAttachments = state.pendingAttachments[entityId];
            state.pendingAttachments[entityId] = pendingAttachments.filter(a => a.id !== attachmentId);
        },
        setAttachmentType: (state, action) => {
            const { entityId, attachmentId, type } = action.payload;
            const attachment = state.pendingAttachments[entityId].find(a => a.id === attachmentId);
            if (attachment) {
                attachment.type = type;
            }

            state.uploadSubmitted = false;
        },
        setUploadDirty: state => {
            state.uploadDirty = true;
        },
        setPageData: (state, action) => {
            const { entityId, page, limit, sortProperty, sortDirection } = action.payload;
            state.metadata[entityId] = {
                ...state.metadata[entityId],
                page,
                limit,
                sortProperty,
                sortDirection
            };
        }
    },
    extraReducers: {
        [loadAttachmentTypes.pending]: state => {
            state.attachmentTypesPending = true;
        },
        [loadAttachmentTypes.fulfilled]: (state, action) => {
            const { entityName } = action.meta.arg;
            state.attachmentTypesPending = false;
            state.attachmentTypes[entityName] = action.payload;
        },
        [loadAttachmentTypes.rejected]: (state, action) => {
            const { entityName } = action.meta.arg;
            state.attachmentTypesPending = false;
            state.attachmentTypes[entityName] = [];
        },
        [getAttachments.pending]: (state, action) => {
            const { entityId } = action.meta.arg;
            state.loadPending[entityId] = true;
            if (!state.attachments[entityId]) state.attachments.entityId = [];
            
            if (!state.metadata[entityId]) {
                state.metadata[entityId] = {
                    page: 1,
                    limit: 10,
                    sortDirection: 'desc',
                    sortProperty: 'createdDate'
                };
            }
        },
        [getAttachments.rejected]: (state, action) => {
            const entityId = action.payload;
            state.loadPending[entityId] = false;
            state.attachments[entityId] = [];
            state.metadata[entityId] = null;
        },
        [getAttachments.fulfilled]: (state, action) => {
            const { entityId } = action.meta.arg;
            const { attachments, totalCount } = action.payload;
            state.attachments[entityId] = attachments || [];
            state.metadata[entityId].totalCount = totalCount;
            state.pendingAttachments[entityId] = [];
            state.loadPending[entityId] = false;
        },
        [addPendingAttachment.pending]: (state, action) => {
            const { entityId } = action.meta.arg;
            if (!state.pendingAttachments[entityId]) state.pendingAttachments[entityId] = [];
        },
        [addPendingAttachment.fulfilled]: (state, action) => {
            const { entityId, entityName } = action.meta.arg;
            const newAttachments = action.payload;

            if (state.attachmentTypes[entityName] && state.attachmentTypes[entityName].length === 1) {
                newAttachments.forEach(na => na.type = state.attachmentTypes[entityName][0]);
            }

            const pendingAttachments = state.pendingAttachments[entityId] || [];
            state.pendingAttachments = {
                ...state.pendingAttachments,
                [entityId]: pendingAttachments.concat(newAttachments)
            }
        },
        [addPendingAttachment.rejected]: (state, action) => {
            const { entityId } = action.meta.arg;
            state.pendingErrors[entityId] = action.error;
        },
        [uploadAttachment.pending]: (state, action) => {
            state.uploadPending = true;
        },
        [uploadAttachment.fulfilled]: (state, action) => {
            const { entityId } = action.meta.arg;
            state.pendingAttachments[entityId] = [];
            state.uploadPending = false;
            state.uploadDirty = false;
        },
        [uploadAttachment.rejected]: (state, action) => {
            const { entityId } = action.meta.arg;
            state.uploadErrors[entityId] = action.error;
            state.uploadPending = false;
        }
    }
});

export const { actions, reducer } = attachmentSlice;
export default reducer;