import React from 'react';
import {useSelector} from 'react-redux';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import { DataTable } from 'common';
import { Attachments } from 'attachment/Attachments';

const DETAIL_COLUMNS = [
    { 
        Header: 'Item', 
        minWidth: 250, 
        Cell: cell => {
            const {description, hcpcs} = cell.row.original;
            return hcpcs + ": " + description;
        }
    },{
        Header: 'DOS', 
        Cell: cell => {
            const {dateOfService} = cell.row.original;
            return moment(dateOfService).format('L');
        }
    },{ 
        Header: 'Qty', 
        width: 70,
        accessor: 'quantity' 
    },{
        Header: 'Billed', 
        Cell: cell => {
            const {costTotal} = cell.row.original;
            return '$' + costTotal.toFixed(2);
        }
    },{
        Header: 'Paid', 
        Cell: cell => {
            const {paidTotal} = cell.row.original;
            return paidTotal ? '$' + paidTotal.toFixed(2) : '';
        }
    },{
        Header: 'Reason', 
        minWidth: 100, 
        accessor: 'reason'
    },{
        Header: 'Check', 
        accessor: 'checkNumber'
    },{
        Header: 'Date', 
        Cell: cell => {
            const {checkDate} = cell.row.original;
            return checkDate ? moment(checkDate).format('L') : '';
        }
    },{
        Header: 'Amount', 
        minWidth: 70,
        Cell: cell => {
            const {checkTotal} = cell.row.original;
            return checkTotal ? '$' + checkTotal.toFixed(2) : '';
        }
    }
]

export const VendorInvoice = ({ invoiceId, invoiceDetails }) => {
    const detailsLoading = useSelector(state => state.invoices.invoiceDetail.detailsLoading);

    const attachmentsLoading = useSelector(state => state.attachment.loadPending[invoiceId]);

    return (
        <>
            {detailsLoading &&
            <Grid container style={{justifyContent: 'center'}}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>}
            {!detailsLoading && invoiceDetails &&
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DataTable
                        loading={false}
                        columns={DETAIL_COLUMNS}
                        data={invoiceDetails}
                    />
                </Grid>
                <Grid container item alignItems="center" justify="space-between">
                    <Attachments entityName='VendorBill' entityId={invoiceId} hidePagingToolbar={true} />
                </Grid>
                
                {!attachmentsLoading && 
                    <Grid container item xs={12} md={9}>
                        <Typography variant="body2" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                            Reconsideration for payment of the above mentioned lines will require an invoice AND submission of any missing information/notes.
                            Submitting only missing information or notes will not suffice for invoice reconsideration. 
                            It must be accompanied by an invoice.
                        </Typography>
                    </Grid>}
                
            </Grid>
            }
        </>
    )
}