import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { 
    Checkbox, Grid, Typography, TextField, Button, Container, FormControlLabel, 
    Accordion, AccordionSummary, AccordionDetails, RadioGroup, Radio, InputAdornment, 
    IconButton, InputLabel, RootRef, Paper, CircularProgress, Backdrop, Collapse, Link,
    Dialog, DialogActions, DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InputMask from 'react-input-mask';
import { Autocomplete, Alert } from '@material-ui/lab';
import {useDropzone} from 'react-dropzone'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { 
    isName, isEmail, isLength, stripMask, isEitherLength, isValueBetween
} from '../common/validate';
import states from 'common/states';
import { getPlacePredictions, getPlaceDetails } from 'common/placeService';
import { HeroPanel } from './HeroPanel';
import { ConfirmNewReferral } from './ConfirmNewReferral';

import { 
    attachFile, createReferral, loadAttachmentTypes, actions as referralActions 
} from './referralSlice';
import { actions as appActions } from 'appSlice';
import { findServices } from './serviceSlice';
import { findLocations } from '../vendor/vendorSlice';

const { resetForm, removeFile } = referralActions;
const { clientErrorMessage, clearMessage } = appActions;

const DME_LABEL = 'DME, Prosthetics, Home & Vehicle Modifications';

const useStyles = makeStyles(theme => ({
    foundation: {
        fontFamily: 'Scandia,Arial,sans-serif',
        fontSize: '15px',
        color: '#243746',
        minWidth: '515px',
        margin: '0 0 95px 0',
        '& .MuiPaper-root': {
            color: '#243746',
            margin: '0 0 30px 0',
        }
    },
    paper: {
        padding: '50px 80px 40px 80px',
    },
    alert: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    progress: {
        marginTop: '40px',
        marginLeft: '10px'
    },
    textField: {
        width: '100%',
        '& .MuiInputLabel-root': {
            fontSize: '1.3rem',
            color: '#243746',
        },
        '& .MuiFormHelperText-root' : {
            color:'#e04403',
            textTransform: 'capitalize',
            fontSize: '0.85rem',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #8dc8e8',
        },
        '& .MuiInput-underline.Mui-error:before': {
            borderBottom: '2px solid #e04403',
        },
        '& label.MuiFormLabel-filled.Mui-error + div.MuiInput-underline:before' : {
            borderBottom: '2px solid #e04403',
        },
        '& label.MuiFormLabel-filled:not(.Mui-error) + div.MuiInput-underline:before' : {
            borderBottom: '2px solid #8dc8e8',
        },
    },
    textFieldRequired: {
        width: '100%',
        '& .MuiInputLabel-root': {
            fontSize: '1.3rem',
            color: '#243746',
        },
       '& .MuiFormHelperText-root' : {
            color:'#e04403',
            textTransform: 'capitalize',
            fontSize: '0.85rem',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #e04403',
        },
        '& label.MuiFormLabel-filled.Mui-error + div.MuiInput-underline:before' : {
            borderBottom: '2px solid #e04403',
        },
        '& label.MuiFormLabel-filled:not(.Mui-error) + div.MuiInput-underline:before' : {
            borderBottom: '2px solid #8dc8e8',
        },
    },
    autoComplete: {
        '& .MuiInputLabel-root': {
            fontSize: '1.3rem',
            color: '#243746',
        },
        '& .MuiFormHelperText-root' : {
            textTransform: 'capitalize',
            fontSize: '0.85rem',
        }  ,     
    },
    autoCompleteRequired: {
        '& .MuiInputLabel-root': {
            fontSize: '1.3rem',
            color: '#243746',
        },
        '& .MuiFormHelperText-root' : {
            textTransform: 'capitalize',
            fontSize: '0.85rem',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #e04403',
        },     
        '& label.MuiFormLabel-filled.Mui-error + div.MuiInput-underline:before' : {
            borderBottom: '2px solid #e04403',
        },
        '& label.MuiFormLabel-filled:not(.Mui-error) + div.MuiInput-underline:before' : {
            borderBottom: '2px solid #8dc8e8',
        },
    },
    btn: {
        color: 'white !important',
        padding: '2px 40px',
        borderRadius: '25px',
        textTransform: 'none',
        fontSize: '20px',
        '& span.MuiButton-label': {
            color: 'white',
            lineHeight: '1.75',
            fontWeight: '600'
        }
    },
    btnDisabled: {
        backgroundColor: '#cccccc !important',
    },
    cardTitle: {
        backgroundColor: theme.colors.white,
        color: '#243746',
        margin: 0,
        '& h6': {
            fontWeight: '600',
            color: '#243746'
        }
    },
    expandIcon: {
        color: '#243746',
    },
    accordion: {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    },
    radioGroup: {
        borderColor: '#8dc8e8',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        position: 'relative',
        '& label.MuiFormLabel-root': {
            color: '#243746',
            position: 'absolute',
            top: '0',
            left: '0',
            margin: '0',
        },
        '& label.MuiFormControlLabel-root': {
            color: '#243746',
            margin: '16px 0 0 0',
            '& span.MuiFormControlLabel-label': {
                fontSize: 'unset',
            }
        },
    },
    radio: {
        padding: '6px',
        '& .MuiIconButton-label': {
            color: '#8dc8e8',
        },
    },
    checkbox: {
        color: '#76787A'
    },
    upload: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '80px',
        color: '#505050',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#F8F8F8',
        borderStyle: 'dashed'
    },
    logo: {
        width: '260px',
        marginTop: '40px',
        marginBottom: '20px'
    },
    modalBold: {
        fontWeight: '500',
        margin: '30px 30px 15px 40px',
    },
    modalText: {
        fontWeight: '400',
        margin: '0px 30px 15px 40px',
     },
     modalFinalText: {
        fontWeight: '400',
        margin: '10px 30px 10px 40px',
     },
     modalList: {
         margin: '-10px 30px 15px 50px',
         listStyleType: 'none',
     },
     modalListItem: {
         margin: '1px 0 5px 0',
     },
}));

function RequestorCard({ claim, register, control, errors, setValue }) {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const hasErrors = Object.keys(errors).length > 0;

    return (
        <Accordion className={classes.accordion} expanded={hasErrors || expanded}>
            <AccordionSummary 
                tabIndex = "-1"
                onClick = {() => setExpanded(!expanded)} 
                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                className = {classes.cardTitle}
            >
                <Typography variant="h6">
                    Requestor
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textFieldRequired} 
                            name = "requestorFirstName"
                            label = "First Name*"
                            error = { !!errors.requestorFirstName }
                            helperText = { errors.requestorFirstName ? errors.requestorFirstName.message : 'required' }
                            onChange = {({target: {value}}) => {
                                if (value && value.replace(/[^A-Z .-]/gi,'') !== value) {
                                    setValue('requestorFirstName', value.replace(/[^A-Z .-]/gi,''));
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: 'required',
                                validate: isName
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textFieldRequired} 
                            name = "requestorLastName"
                            label = "Last Name*"
                            error = { !!errors.requestorLastName }
                            helperText = { errors.requestorLastName ? errors.requestorLastName.message : 'required' }
                            onChange = {({target: {value}}) => {
                                if (value && value.replace(/[^A-Z .-]/gi,'') !== value) {
                                    setValue('requestorLastName', value.replace(/[^A-Z .-]/gi,''));
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: 'required',
                                validate: isName
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            className = {classes.textFieldRequired} 
                            name = "requestorEmail"
                            label = "Email*" 
                            error = { !!errors.requestorEmail }
                            helperText = { errors.requestorEmail ? errors.requestorEmail.message : 'required' }
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: 'required',
                                validate: isEmail
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "requestorPhone"
                            defaultValue = ""
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Phone"
                                            error = {!!errors.requestorPhone}
                                            helperText = {errors.requestorPhone && errors.requestorPhone.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

function InjuredWorkerCard({ claim, reqMark, patientAddr, register, control, errors, setValue }) {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const hasErrors = Object.keys(errors).length > 0;

    const [addressValue, setAddressValue] = useState((patientAddr && patientAddr.length > 0) ? patientAddr : '');
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [sessionToken, setSessionToken] = useState(null);

    const addressOnChange = (e, value, reason) => {
        if (reason === 'input') {
            setAddressValue(value);
            const myToken = getPlacePredictions(value, sessionToken, (predictions) => {
                setAddressPredictions(predictions);
            });
            setSessionToken(myToken);
        }
    };

    const addressOnSelect = (e, selection) => {
        if (selection) {
            getPlaceDetails(selection, sessionToken, ({street, city, state, zip, lat, lng}) => {
                setAddressValue(street);
                setValue('patientCity', city);
                setValue('patientState', states.find(({abbreviation}) => abbreviation === state));
                setValue('patientZip', zip);
                setValue('patientLat', lat);
                setValue('patientLng', lng);
            });
            setAddressPredictions([]);
            setSessionToken(null);
        }
    };

    return (
        <Accordion className={classes.accordion} disabled={!!claim} expanded={!claim && (hasErrors || expanded)}>
            <AccordionSummary 
                tabIndex = "-1"
                onClick = {() => setExpanded(!expanded)} 
                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                className = {classes.cardTitle}
            >
                <Typography variant="h6">
                    { !!claim ? `Injured Worker - ${claim.patientFirstName} ${claim.patientLastName}` : 'Injured Worker'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className = {claim ? classes.textField : classes.textFieldRequired} 
                            name = "patientFirstName"
                            label = {"First Name" + reqMark}
                            disabled = {!!claim}
                            error = { !!errors.patientFirstName }
                            helperText = { errors.patientFirstName ? errors.patientFirstName.message : !claim && 'required' }
                            onChange = {({target: {value}}) => {
                                if (value && value.replace(/[^A-Z .-]/gi,'') !== value) {
                                    setValue('patientFirstName', value.replace(/[^A-Z .-]/gi,''));
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: !!claim ? false : 'required',
                                validate: isName
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className =  {claim ? classes.textField : classes.textFieldRequired}
                            name = "patientLastName"
                            label = {"Last Name" + reqMark}
                            disabled = {!!claim}
                            error = { !!errors.patientLastName }
                            helperText = { errors.patientLastName ? errors.patientLastName.message : !claim && 'required' }
                            onChange = {({target: {value}}) => {
                                if (value && value.replace(/[^A-Z .-]/gi,'') !== value) {
                                    setValue('patientLastName', value.replace(/[^A-Z .-]/gi,''));
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: !!claim ? false : 'required',
                                validate: isName
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className = {classes.textField}
                            name = "patientBirthDate"
                            label = "Birth Date"
                            type = "date"
                            InputLabelProps = {{shrink: true}}
                            inputRef = {register}
                            error = {!!errors.patientBirthDate}
                            helperText = {errors.patientBirthDate && errors.patientBirthDate.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "patientSSN"
                            defaultValue = ""
                            as = {
                                <InputMask mask="xxx-xx-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "SSN"
                                            error = {!!errors.patientSSN}
                                            helperText = {errors.patientSSN && errors.patientSSN.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ x-]/g)(isLength(4))
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} >
                        <TextField 
                            className = {classes.textField} 
                            name = "patientHeightFt"
                            label = "Height"
                            type = "number"
                            InputProps = {{
                                endAdornment: <InputAdornment position="end">ft.</InputAdornment>,
                            }}
                            InputLabelProps = {{ shrink: true }}
                            inputRef = {register({
                                validate: isValueBetween(2,7)
                            })}
                            error = {!!errors.patientHeightFt}
                            helperText = {errors.patientHeightFt && errors.patientHeightFt.message}
                        />
                    </Grid>
                    <Grid item xs={3} >
                        <TextField 
                            className = {classes.textField} 
                            name = "patientHeightIn"
                            label = " "
                            type = "number"
                            InputProps = {{
                                endAdornment: <InputAdornment position="end">in.</InputAdornment>,
                            }}
                            InputLabelProps = {{ shrink: true }}
                            inputRef = {register({
                                validate: isValueBetween(0,11)
                            })}
                            error = {!!errors.patientHeightIn}
                            helperText = {errors.patientHeightIn && errors.patientHeightIn.message}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <TextField 
                            className = {classes.textField} 
                            name = "patientWeight"
                            label = "Weight"
                            type = "number"
                            InputProps = {{
                                endAdornment: <InputAdornment position="end">lbs.</InputAdornment>,
                            }}
                            InputLabelProps = {{ shrink: true }}
                            inputRef = {register({
                                validate: isValueBetween(10,999)
                            })}
                            error = {!!errors.patientWeight}
                            helperText = {errors.patientWeight && errors.patientWeight.message}
                        />
                    </Grid>
                    <Grid item xs={12} style={{display:'flex', alignItems: 'flex-end'}}>
                        <Controller
                            control = {control}
                            name = "patientGender"
                            defaultValue = ""
                            as = {
                                <RadioGroup row className={classes.radioGroup} aria-label="gender" name="patientGender">
                                    <InputLabel>Gender</InputLabel>
                                    <FormControlLabel value="F" control={<Radio className={classes.radio}/>} label="Female" />
                                    <FormControlLabel value="M" control={<Radio className={classes.radio}/>} label="Male" />
                                    <FormControlLabel value="" control={<Radio className={classes.radio}/>} label="Unspecified" />
                                </RadioGroup>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            className={classes.autoComplete}
                            onChange = {addressOnSelect}
                            onInputChange = {addressOnChange}
                            freeSolo = {true}
                            filterOptions = {(x) => x}
                            options = {addressPredictions}
                            autoComplete = {true}
                            autoHighlight = {true}
                            blurOnSelect = {true}
                            disableClearable = {true}
                            getOptionLabel = { ({description}) => description}
                            inputValue = {addressValue}
                            renderInput = {params => (
                                <TextField 
                                    {...params} 
                                    label = "Address"
                                    InputLabelProps={{shrink: true}}
                                    inputRef = {register}
                                    name = "patientAddress"
                                />
                            )}
                        />
                        <input type="hidden" {...register("patientLat")}/>
                        <input type="hidden" {...register("patientLng")}/>
                        <div style={{marginTop:'5px', display:'flex'}}>
                            <div style={{flexGrow:1}}></div>
                            <img height="18" width="144" alt="powered by Google" src="https://cdn.homecareconnect.com/images/powered_by_google_on_white_hdpi.png"/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <TextField 
                            className = {classes.textField} 
                            name = "patientCity"
                            label = "City"
                            InputLabelProps = {{shrink: true}}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <Controller
                            control = {control}
                            defaultValue = {null}
                            render = { props => (
                                <Autocomplete
                                    {...props}
                                    className={classes.autoComplete}
                                    options = {states}
                                    autoComplete = {true}
                                    autoHighlight = {true}
                                    autoSelect = {true}
                                    popupIcon = {<ExpandMoreIcon />}
                                    getOptionLabel = {option => option.name}
                                    getOptionSelected = {(option, value) => option.name === value.name}
                                    renderInput = {params => (
                                        <TextField {...params} label="State" InputLabelProps={{shrink: true}} />
                                    )}
                                    onChange={(_, data) => props.onChange(data)}
                                />
                            )}
                            name = "patientState"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Controller
                            control = {control}
                            name = "patientZip"
                            defaultValue = ""
                            as = {
                                <InputMask mask="99999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Zip"
                                            error = {!!errors.patientZip}
                                            helperText = {errors.patientZip && errors.patientZip.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isEitherLength(5,9))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "patientHomePhone"
                            defaultValue = ""
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Home Phone"
                                            error = {!!errors.patientHomePhone}
                                            helperText = {errors.patientHomePhone && errors.patientHomePhone.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "patientCellPhone"
                            defaultValue = ""
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Cell Phone"
                                            error = {!!errors.patientCellPhone}
                                            helperText = {errors.patientCellPhone && errors.patientCellPhone.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            className = {classes.textField} 
                            name = "patientEmail"
                            label = "Email" 
                            error = { !!errors.patientEmail }
                            helperText = { errors.patientEmail && errors.patientEmail.message }
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                validate: isEmail
                            })}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

function PhysicianCard({ register, control, errors, setValue }) {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const hasErrors = Object.keys(errors).length > 0;

    return (
        <Accordion className={classes.accordion} expanded={hasErrors || expanded}>
            <AccordionSummary 
                tabIndex = "-1"
                onClick = {() => setExpanded(!expanded)} 
                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                className = {classes.cardTitle}
            >
                <Typography variant="h6" className={classes.cardTitle}>
                    Physician
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textField} 
                            name = "physicianFirstName"
                            label = "First Name"
                            error = { !!errors.physicianFirstName }
                            helperText = { errors.physicianFirstName && errors.physicianFirstName.message }
                            onChange = {({target: {value}}) => {
                                if (value && value.replace(/[^A-Z .-]/gi,'') !== value) {
                                    setValue('physicianFirstName', value.replace(/[^A-Z .-]/gi,''));
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textField} 
                            name = "physicianLastName"
                            label = "Last Name"
                            error = { !!errors.physicianLastName }
                            helperText = { errors.physicianLastName && errors.physicianLastName.message }
                            onChange = {({target: {value}}) => {
                                if (value && value.replace(/[^A-Z .-]/gi,'') !== value) {
                                    setValue('physicianLastName', value.replace(/[^A-Z .-]/gi,''));
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "physicianOfficePhone"
                            defaultValue = ""
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Office Phone"
                                            error = {!!errors.physicianOfficePhone}
                                            helperText = {errors.physicianOfficePhone && errors.physicianOfficePhone.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "physicianFax"
                            defaultValue = ""
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Fax"
                                            error = {!!errors.physicianFax}
                                            helperText = {errors.physicianFax && errors.physicianFax.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            className = {classes.textField} 
                            name = "physicianEmail"
                            label = "Email" 
                            error = { !!errors.physicianEmail }
                            helperText = { errors.physicianEmail && errors.physicianEmail.message }
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                validate: isEmail
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "physicianNPI"
                            defaultValue = ""
                            as = {
                                <InputMask mask="9999999999" maskChar="">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "NPI"
                                            error = {!!errors.physicianNPI}
                                            helperText = {errors.physicianNPI && errors.physicianNPI.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: isLength(10)
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

function ClaimCard({ claim, reqMark, register, control, errors, setValue }) {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const hasErrors = Object.keys(errors).length > 0;
 
    return (
        <Accordion className={classes.accordion} disabled={!!claim} expanded={!claim && (hasErrors || expanded)}>
            <AccordionSummary 
                tabIndex = "-1"
                onClick = {() => setExpanded(!expanded)} 
                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                className = {classes.cardTitle}
            >
                <Typography variant="h6" className={classes.cardTitle}>
                    { !!claim ? `Claim - ${claim.claimNumber}` : 'Claim' }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                        { !!claim && <input type="hidden" value={claim.id} name={"claimId"} ref={register}/> }
                        <TextField 
                            className = {claim ? classes.textField : classes.textFieldRequired}
                            name = "claimNumber"
                            label = {"Claim Number" + reqMark}
                            disabled = {!!claim}
                            error = { !!errors.claimNumber }
                            helperText = { errors.claimNumber ? errors.claimNumber.message : !claim && 'required' }
                            onChange = {({target: {value}}) => {
                                if (value && value.replace(/[^A-Z0-9 -]/g,'') !== value) {
                                    setValue('claimNumber', value.toUpperCase().replace(/[^A-Z0-9 -]/g,''));
                                }
                            }}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: !!claim ? false : 'required'
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                            className = {claim ? classes.textField : classes.textFieldRequired}
                            name = "injuryDate"
                            label = {"Injury Date" + reqMark}
                            type = "date"
                            InputLabelProps = {{shrink: true}}
                            inputRef = {register({
                                required: !!claim ? false : 'required'
                            })}
                            error = {!!errors.injuryDate}
                            helperText = {errors.injuryDate ? errors.injuryDate.message : !claim && 'required'}
                        />
                    </Grid> 
                    <Grid item xs={12} md={6}>
                        <TextField 
                            className = {claim ? classes.textField : classes.textFieldRequired} 
                            name = "employer"
                            label = {"Employer" + reqMark}
                            error = { !!errors.employer }
                            helperText = { errors.employer ? errors.employer.message : !claim && 'required' }
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: !!claim ? false : 'required'
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control = {control}
                            name = "employerPhone"
                            defaultValue = ""
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Phone"
                                            error = {!!errors.employerPhone}
                                            helperText = {errors.employerPhone && errors.employerPhone.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            className = {classes.textField} 
                            name = "injury"
                            label = "Injury Details"
                            InputLabelProps={{shrink: true}}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className = {classes.textField}
                            name = "surgeryDate"
                            label = "Surgery Date"
                            type = "date"
                            InputLabelProps = {{shrink: true}}                            
                            inputRef = {register}
                            error = {!!errors.surgeryDate}
                            helperText = {errors.surgeryDate && errors.surgeryDate.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className = {classes.textField}
                            name = "dischargeDate"
                            label = "Discharge Date"
                            type = "date"
                            InputLabelProps = {{shrink: true}}
                            inputRef = {register}
                            error = {!!errors.dischargeDate}
                            helperText = {errors.dischargeDate && errors.dischargeDate.message}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                            className = {classes.textField} 
                            name = "hospital"
                            label = "Hospital Name"
                            InputLabelProps={{shrink: true}}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control = {control}
                            name = "hospitalPhone"
                            defaultValue = ""
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Phone"
                                            error = {!!errors.hospitalPhone}
                                            helperText = {errors.hospitalPhone && errors.hospitalPhone.message}
                                            InputLabelProps={{shrink: true}}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className = {claim ? classes.textField : classes.textFieldRequired}
                            name = "customerName"
                            label = {"Customer Name" + reqMark}
                            error = {!!errors.customerName}
                            helperText = {errors.customerName ? errors.customerName.message : !claim && 'required'}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: !!claim ? false : 'required'
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className = {claim ? classes.textField : classes.textFieldRequired}
                            name = "adjusterName"
                            label = {"Adjuster Name" + reqMark}
                            error = {!!errors.adjusterName}
                            helperText = {errors.adjusterName ? errors.adjusterName.message : !claim && 'required'}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: !!claim ? false : 'required'
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "adjusterPhone"
                            defaultValue = ""
                            as = {
                                <InputMask mask = "(999) 999-9999" maskChar = " ">
                                    {
                                        (props) => (
                                            <TextField
                                                className = {classes.textField}
                                                label = "Phone"
                                                error = {!!errors.adjusterPhone}
                                                helperText={errors.adjusterPhone && errors.adjusterPhone.message}
                                                InputLabelProps={{shrink: true}}
                                                {...props}
                                            />
                                        )
                                    }
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className = {classes.textField}
                            name = "adjusterEmail"
                            label = "Email"
                            error = {!!errors.adjusterEmail}
                            helperText={errors.adjusterEmail && errors.adjusterEmail.message}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                validate: isEmail
                            })}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

function ReferralDetails({referralMetaData, detailIds, setDetailIds, register, control, errors}) {
    const { services, servicesPending } = useSelector(state => state.service);
    const classes = useStyles();
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(findServices());
    }, [dispatch]);

    const add = () => {
        referralMetaData.detailIdSeq++;
        setDetailIds(detailIds.concat({ id: ''+ referralMetaData.detailIdSeq }));
    };

    const remove = (id) => () => {
       setDetailIds( detailIds.filter(detail => detail.id !== id) );
    };

    return (
        <Grid container spacing={2}>
            {detailIds.map((detail) => (
                <Grid container item xs={12} spacing={2} key={detail.id} style={{marginBottom: '20px'}}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Controller
                            control = {control}
                            defaultValue = {null}
                            name = {`service-${detail.id}`}
                            rules = {{
                                required: 'required'
                            }}
                            render = { props => (
                                <Autocomplete
                                    {...props}
                                    className={classes.autoCompleteRequired}
                                    disabled = { servicesPending }
                                    options = {services}
                                    autoComplete = {true}
                                    autoHighlight = {true}
                                    autoSelect = {true}
                                    fullWidth
                                    disableClearable = {true}
                                    popupIcon = {<ExpandMoreIcon />}
                                    getOptionLabel = {service => service.type === 'DME' ? DME_LABEL : service.name}
                                    getOptionSelected={(option, value) => option.type === value.type}
                                    renderInput = {params => (
                                        <TextField 
                                            {...params}
                                            label = "Service*"
                                            error = {!!errors[`service-${detail.id}`]}
                                            helperText = {errors[`service-${detail.id}`] && errors[`service-${detail.id}`].message}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    )}
                                    renderOption = { service => {
                                        return (
                                            <Typography variant="body1">
                                                {service.type === 'DME' ? DME_LABEL : service.name}
                                            </Typography>);
                                    }}
                                    onChange={(_, data) => props.onChange(data) }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={7} sm={8} md={4}>
                        <TextField
                            className = {classes.textFieldRequired}
                            name = {`date-${detail.id}`}
                            label = "Date*"
                            type = "date"
                            InputLabelProps = {{shrink: true}}
                            inputRef = {register({
                                required: 'required'
                            })}
                            error = {!!errors[`date-${detail.id}`]}
                            helperText = {errors[`date-${detail.id}`] && errors[`date-${detail.id}`].message}
                        />
                    </Grid>
                    <Grid item xs={5} sm={4} md={2}>
                        <div style={{display: 'flex'}}>
                            <TextField 
                                className = {classes.textFieldRequired} 
                                name = {`quantity-${detail.id}`}
                                label = "Qty*"
                                type = "number"
                                defaultValue = "1"
                                InputLabelProps={{shrink: true}}
                                inputRef = {register({
                                    required: 'required',
                                    validate: isValueBetween(1,99)
                                })}
                                error = {!!errors[`quantity-${detail.id}`]}
                                helperText = {errors[`quantity-${detail.id}`] && errors[`quantity-${detail.id}`].message}
                            />
                            { detailIds.length > 1 && 
                            <IconButton tabIndex="-1" onClick={remove(detail.id)} style={{marginTop:'10px'}}>
                                <RemoveCircleIcon style={{color:'rgb(200,0,0)'}} />
                            </IconButton> }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={detailIds.length > 1 ? 11 : 12}>
                        <TextField 
                            className = {classes.textFieldRequired} 
                            name = {`desc-${detail.id}`}
                            label = "Description*"
                            multiline = {true}
                            error = {!!errors[`desc-${detail.id}`]}
                            helperText = {errors[`desc-${detail.id}`] && errors[`desc-${detail.id}`].message}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register({
                                required: 'required'
                            })}
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button onClick={add} variant="contained" color="secondary" className={classes.btn}>
                    Add Another Service
                </Button>
            </Grid>
        </Grid>
    );
}

function ReferralCard({referralMetaData, detailIds, setDetailIds, register, control, errors }) {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const hasErrors = Object.keys(errors).length > 0;

    return (
        <Accordion className={classes.accordion} expanded={hasErrors || expanded}>
            <AccordionSummary 
                tabIndex = "-1"
                onClick = {() => setExpanded(!expanded)} 
                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                className = {classes.cardTitle}
            >
                <Typography variant="h6" className={classes.cardTitle}>
                    Referral
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                            className = {classes.textField} 
                            name = 'instructions'
                            label = "Instructions"
                            multiline = {true}
                            InputLabelProps={{shrink: true}}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel label="Rush Order" 
                            control={
                                <Controller name="rush" control={control} inputRef={register}
                                    defaultValue={false}
                                    render={({onChange, value}) => (
                                        <Checkbox onChange={e => onChange(e.target.checked)}
                                            checked={value}
                                        />
                                    )}
                                />
                            }
                        />
                        <FormControlLabel label="Price Quote" 
                            control={
                                <Controller name="quote" control={control} inputRef={register}
                                    defaultValue={false}
                                    render={({onChange, value}) => (
                                        <Checkbox onChange={e => onChange(e.target.checked)}
                                            checked={value}
                                        />
                                    )}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>       
                        <ReferralDetails referralMetaData={referralMetaData} 
                            detailIds={detailIds} setDetailIds={setDetailIds}
                            register={register} control={control} errors={errors}/>                 
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

function ProviderCard({ register, control, errors }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(true);
    const hasErrors = Object.keys(errors).length > 0;
    
    useEffect(() => {
        dispatch(findLocations());
    }, [dispatch]);

    const [locationMissing, setLocationMissing] = useState(false);
    const { locations, pending } = useSelector(state => state.vendor);

    return (
        <Accordion className={classes.accordion} expanded={hasErrors || expanded}>
            <AccordionSummary 
                tabIndex = "-1"
                onClick = {() => setExpanded(!expanded)} 
                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                className = {classes.cardTitle}>
                <Typography variant="h6" className={classes.cardTitle}>
                    Provider
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            control = {control}
                            defaultValue = {null}
                            name = "vendorLocation"
                            rules = {{
                                required: 'required'
                            }}
                            render = { props => (
                                <Autocomplete
                                    {...props}
                                    className={classes.autoComplete}
                                    loading = {pending}
                                    options = {locations}
                                    autoComplete = {true}
                                    autoHighlight = {true}
                                    autoSelect = {true} 
                                    fullWidth
                                    disableClearable = {true}
                                    popupIcon = {<ExpandMoreIcon />}
                                    getOptionLabel = {option => option.name}
                                    renderOption = {location => (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    {location.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {location.street1}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {location.city}, {location.state} {location.zip}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    renderInput = {params => (
                                        <TextField
                                          {...params}
                                          label="Service Location"
                                          error = {!!errors.vendorLocation}
                                          helperText = {errors.vendorLocation && errors.vendorLocation.message}
                                          inputProps={{ ...params.inputProps }}
                                        />)}
                                    onChange={(_, data) => {
                                        props.onChange(data)
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="secondary"
                            onClick={() => setLocationMissing(true)}>
                            Can't find your location?
                        </Button>
                        <Collapse in={locationMissing} >
                            <Typography variant="body1">
                                Please call&nbsp;
                                <Link href="tel:8552232228">855-223-2228</Link>
                                &nbsp;to speak with our Network Development team
                            </Typography>
                        </Collapse>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

function FileDropZone({ control, errors }) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(true);
    const hasErrors = Object.keys(errors).length > 0;
    const { files, fileErrors, filesPending } = useSelector(state => state.referral.forms.create);
    const { attachmentTypes } = useSelector(state => state.referral);
    
    useEffect(() => {
        dispatch(loadAttachmentTypes());
    }, [dispatch]);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: dropFiles => {
            dropFiles.forEach(file => dispatch(attachFile(file)));
        }
    });
    const {ref, ...rootProps} = getRootProps();

    return (
        <Accordion className={classes.accordion} expanded={hasErrors || expanded}>
            <AccordionSummary 
                tabIndex = "-1"
                onClick = {() => setExpanded(!expanded)} 
                expandIcon = {<ExpandMoreIcon className={classes.expandIcon}/>} 
                className = {classes.cardTitle}
            >
                <Typography variant="h6" className={classes.cardTitle}>
                    Attachments
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'block'}}>
                    <RootRef rootRef={ref}>
                        <Paper {...rootProps} variant="outlined" className={classes.upload}>
                            { filesPending > 0 ? 
                                <CircularProgress/> 
                            : 
                                <div>
                                    <input {...getInputProps()} />
                                    <Typography variant="h6" style={{padding: '10px'}}>
                                        Drag files here, or click to select
                                    </Typography>
                                </div>
                            }
                        </Paper>
                    </RootRef>
                    {fileErrors.map((error) => (
                        <div style={{width: '100%', marginBottom: '5px'}} key={error}>
                            <Alert severity="warning">{error}</Alert>
                        </div>
                    ))}
                    {files.map((file) => (
                        <div style={{marginTop: '20px'}} key={file.id}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <IconButton tabIndex="-1" onClick={() => dispatch(removeFile(file.name))}>
                                    <RemoveCircleIcon style={{color:'rgb(200,0,0)'}} />
                                </IconButton>
                                <Typography variant="subtitle1">
                                    {file.name} - {file.size}
                                </Typography>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '50px'}}/>
                                <Controller
                                    control = {control}
                                    defaultValue = {null}
                                    name = {`fileType-${file.id}`}
                                    rules = {{
                                        required: 'required'
                                    }}
                                    render = { props => (
                                        <Autocomplete
                                            {...props}
                                            className={classes.autoComplete}
                                            options = {attachmentTypes}
                                            autoComplete = {true}
                                            autoHighlight = {true}
                                            autoSelect = {true}
                                            disableClearable = {true}
                                            popupIcon = {<ExpandMoreIcon />}
                                            style = {{width: '80%'}}
                                            renderInput = {params => {
                                                return (
                                                    <TextField label="Type"
                                                        error = {!!errors[`fileType-${file.id}`]}
                                                        helperText = {errors[`fileType-${file.id}`] && errors[`fileType-${file.id}`].message}
                                                        inputProps={{ ...params.inputProps }}
                                                        {...params}
                                                        InputLabelProps={{shrink: true}}
                                                    />
                                                )}}
                                            onChange={(_, data) => props.onChange(data)}
                                            getOptionLabel={option => option.name}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    ))}
            </AccordionDetails>
        </Accordion>
    );
};


function ThankYouModal({handleClick, open, newReferralId, processTime, isLoading = false}) {
    //const newRef = 'Referral number: E' + newReferralId;
    const classes = useStyles();

    return (
        <Dialog open={open} maxWidth={"md"} className={classes.foundation} >
            {isLoading ? <DialogTitle>Loading...</DialogTitle> :
                <>
                <Typography className={classes.modalBold} >
                    We've received your referral – thank you!
                </Typography>
                <Typography className={classes.modalText} >
                    Thank you for your referral to Paradigm Care at Home.
                </Typography>
                <Typography className={classes.modalText} >
                    A Referral Care Coordinator will be back to you within {processTime}.
                </Typography>
                <Typography className={classes.modalText} >
                    Need immediate help? Feel free to call or email us:
                </Typography>
                <ul className={classes.modalList}>
                    <li className={classes.modalListItem} >(855) 223-2228</li>
                    <li className={classes.modalListItem} >
                        <Link href="mailto:referrals-careathome@paradigmcorp.com">referrals-careathome@paradigmcorp.com</Link>
                    </li>
                </ul>
                <Typography className={classes.modalText} >
                    We appreciate your business!!!
                </Typography>
                <Typography className={classes.modalFinalText} >
                    Click <Link href="https://portal.homecareconnect.com/web/referral">here</Link> to refer another Paradigm Care at Home case.
                </Typography>
                <DialogActions>
                    <Button onClick={handleClick} color="primary">OK</Button>
                </DialogActions>
                </>
            }
        </Dialog>
    )
}


function getInitialReferralMetaData() {
    return {
        detailIdSeq: 1,
        data: {}
    };
}

function NewReferral() {

    const referralMetaData = useRef(getInitialReferralMetaData());  //data to preserve referral across cancelled confirmation
    const [detailIds, setDetailIds] = useState([{ id: '1'}]);       //initial empty referral detail
    const classes = useStyles();
    const referralForm = useForm({
        mode: 'onChange'
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [showConfirmation, setShowConfirmation] = useState(false);    // show entry form or confirmation panel
    const { handleSubmit, setValue, reset, formState: { isValid }} = referralForm;
    const { pending, success, error, files, filesPending, rush, newReferralId } = useSelector(state => state.referral.forms.create);
    const { authorized, userType } = useSelector(state => state.auth);
    const { profile } = useSelector(state => state.user);
    const processTime = rush ? '4 business hours' : '48 hours';
    
    const { claim } = location.state || {};
    const REQSTAR = (!!claim ? "" : "*") ;

    useEffect(() => {
        if (claim) {
            setValue('claimId', claim.id);
            setValue('claimNumber', claim.claimNumber);
            setValue('patientFirstName', claim.patientFirstName);
            setValue('patientLastName', claim.patientLastName);
        }
    }, [claim, setValue]);

    const { errors } = referralForm;
    useEffect(() => {
        const hasErrors = Object.keys(errors).length > 0;
        if (hasErrors || !!error) {
            dispatch(clientErrorMessage(hasErrors ? 'Referral not submitted, please correct form errors' : error));
        } else {
            dispatch(clearMessage());
        }
    }, [dispatch, error, errors]);

    const handleThankYouClick = () => {
        if (!authorized) {
            window.location = 'https://www.paradigmcorp.com/care-at-home/';
        } else {
            dispatch(resetForm());
            history.push('/home');
        }
    }

    const showProviderCard = userType === 'Vendor';

    const submitConfirmed = values => {
        //submit the confirmed form data
        if (authorized) {
            //RequestorCard is never shown for an authorized user, so use that info
            if (profile.firstName) {
                values.requestorFirstName = profile.firstName;
            } else {
                values.requestorFirstName = "-";
            }
            if (profile.lastName) {
                values.requestorLastName = profile.lastName;                
            } else {
                values.requestorLastName = "-";
            }
            if (values.requestorFirstName === "-" && values.requestorLastName === "-") {
                values.requestorLastName = "Logged in user - name not provided";
            }
            if (profile.email) {
                values.requestorEmail = profile.email;               
            }
            if (profile.officePhone) {
                values.requestorPhone = profile.officePhone;
            } else if (profile.cellPhone) {
                values.requestorPhone = profile.cellPhone;
            }
        }
        dispatch(createReferral({files, ...values}));
    }
    const showConfirmationPanel = values => {
        //show data on confirmation screen prior to submission
        if (!values.claimNumber && claim?.claimNumber) {
            values.claimNumber = claim.claimNumber;
        }
        if (!values.patientFirstName && claim?.patientFirstName) {
            values.patientFirstName = claim.patientFirstName;
        }
        if (!values.patientLastName && claim?.patientLastName) {
            values.patientLastName = claim.patientLastName;
        }
        if (!values.employer && claim?.employerName) {
            values.employer = claim.employerName;
        }
        if (!values.patientBirthDate && claim?.patientBirthDate) {
            values.patientBirthDate = claim.patientBirthDate;
        }
        if (!values.injuryDate && claim?.injuryDate) {
            values.injuryDate = claim.injuryDate;
        }
        referralMetaData.current.data = values;
        setShowConfirmation(true);
    }
    const handleConfirmOK = (theRefForm) => {
        //user confirmed data, submit and hide confirmation
        reset({...referralMetaData.current.data});
        submitConfirmed(referralMetaData.current.data);
        referralMetaData.current = getInitialReferralMetaData();
        setShowConfirmation(false);
    }
    const handleConfirmCancel = (theRefForm) => {
        //user canceled submission, hide confirmation form and reshow entry form
        reset({...referralMetaData.current.data});
        setShowConfirmation(false);
    }

    return (
        <div className={classes.foundation}>{ 
            showConfirmation ? 
                <ConfirmNewReferral newReferral={referralMetaData.current.data} claim={claim}
                    showProvider={showProviderCard} showRequestor={!authorized}
                    detailHighCount={referralMetaData.current.detailIdSeq} files={files}
                    handleConfirm={handleConfirmOK} handleCancel={handleConfirmCancel} />
            :
                <Container maxWidth="md">
                    <ThankYouModal open={success} handleClick={handleThankYouClick} newReferralId={newReferralId} processTime={processTime} />
                    <HeroPanel showMessages={true} />
                    <Paper elevation={6} className={classes.paper}>
                    <form onSubmit={handleSubmit(showConfirmationPanel)}>
                        {showProviderCard && <ProviderCard {...referralForm}/>}
                        {!authorized && <RequestorCard {...referralForm}/>}
                        <InjuredWorkerCard claim={claim} reqMark={REQSTAR} 
                            {...referralForm} patientAddr={referralMetaData.current.data.patientAddress} />
                        <PhysicianCard {...referralForm}/>
                        <ClaimCard claim={claim} reqMark={REQSTAR} {...referralForm}/>
                        <ReferralCard  referralMetaData={referralMetaData.current} 
                            detailIds={detailIds} setDetailIds={setDetailIds} {...referralForm}/>
                        <FileDropZone {...referralForm}/>
                        <Grid container item direction="row" justify="flex-end" xs={12}>
                            <Button 
                                disabled = {!isValid || filesPending > 0 || pending}
                                type = "submit" 
                                className = {(!isValid || filesPending > 0 || pending) 
                                    ? `${classes.btn} ${classes.btnDisabled}` 
                                    : classes.btn } 
                                variant = "contained" 
                                color = "secondary"
                            >
                                {pending ? 'Submitting...' : 'Continue'}
                            </Button>
                        </Grid>
                    </form>
                    <Backdrop open={pending} style={{zIndex: 999, backgroundColor: 'rgba(0,0,0,0.3)'}}>
                        <CircularProgress color="primary"/>
                    </Backdrop>
                    </Paper>
                </Container>
        }</div>
    );
}

export { NewReferral }