import { configureStore } from '@reduxjs/toolkit'

import app from 'appSlice';
import auth from 'auth/authSlice';
import claims from 'claim/claimsSlice';
import referralDetails from 'referral/referralDetailsSlice';
import user from './user/userSlice';
import referral from 'referral/referralSlice';
import service from 'referral/serviceSlice';
import vendor from 'vendor/vendorSlice';
import order from 'claim/vendor/orderSlice';
import attachment from 'attachment/attachmentSlice';
import invoices from 'invoice/invoicesSlice';
import changeNote from './changeNote/changeNotesSlice';
import ach from './vendor/achSlice';

export default configureStore({
    reducer: {
        app,
        auth,
        claims,
        referralDetails,
        user,
        referral,
        service,
        vendor,
        order,
        attachment,
        invoices,
        changeNote,
        ach
    }
});