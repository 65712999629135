import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { doGet, doPost } from '../common/fetch';
import { actions as authAuctions } from '../auth/authSlice';
const { setRequirePrivacyPolicy } = authAuctions;

export const getProfile = createAsyncThunk(
    'profile/getProfile',
    async (_, { rejectWithValue }) => {
        const { response, data } = await doGet({
            path: 'user/profile'
        });
        if (response.ok) {
            return data;
        }
        return rejectWithValue(false);
    }
);

export const postProfile = createAsyncThunk(
    'profile/postProfile',
    async (profile, { rejectWithValue }) => {
        const { response, data } = await doPost({
            path: 'user/profile',
            params: profile
        });
        if (response.ok) {
            return data;
        } else if (response.status === 400) {
            const { message } = data;
            return rejectWithValue(message);
        } else {
            return rejectWithValue('Error saving profile');
        }
    }
);

export const viewUpdateHistory = createAsyncThunk(
    'user/viewUpdateHistory',
    async () => {
        await doPost({
            path: 'user/viewUpdateHistory'
        });
    }
)

export const agreeToPrivacyPolicy = createAsyncThunk(
    'user/agreeToPrivacyPolicy',
    async (_, { dispatch }) => {
        await doPost({
            path: 'user/agreeToPrivacyPolicy'
        });
        dispatch(setRequirePrivacyPolicy(false));
    }
)

export const postPassword = createAsyncThunk(
    'profile/postPassword',
    async (values, { rejectWithValue }) => {
        const { response, data } = await doPost({
            path: 'auth/password',
            params: values
        });
        if (response.ok) {
            return data;
        } else if (response.status === 400) {
            const { message } = data;
            return rejectWithValue(message);
        } else {
            return rejectWithValue('Error saving profile');
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        profile: false,
        getProfilePending: false,
        forms: {
            profile: {
                pending: false,
                success: false,
                error: false
            },
            password: {
                pending: false,
                success: false,
                error: false
            }
        }
    },
    reducers: {
    },
    extraReducers: {
        [getProfile.pending]: state => {
            state.getProfilePending = true;
        },
        [getProfile.fulfilled]: (state, action) => {
            state.getProfilePending = false;
            state.profile = action.payload;
            state.forms.profile.error = false;
        },
        [getProfile.rejected]: state => {
            state.getProfilePending = false;
            state.profile = false;
            state.forms.profile.error = 'Error loading profile';
        },
        [postProfile.pending]: state => {
            state.forms.profile = {
                pending: true,
                success: false,
                error: false
            }
        },
        [postProfile.fulfilled]: (state, action) => {
            state.profile = action.payload;
            state.forms.profile.pending = false;
            state.forms.profile.success = true;
        },
        [postProfile.rejected]: (state, action) => {
            state.forms.profile.pending = false;
            state.forms.profile.error = action.payload;
        },
        [postPassword.pending]: state => {
            state.forms.password = {
                pending: true,
                success: false,
                error: false
            }
        },
        [postPassword.fulfilled]: state => {
            state.forms.password.pending = false;
            state.forms.password.success = true;
        },
        [postPassword.rejected]: (state, action) => {
            state.forms.password.pending = false;
            state.forms.password.error = action.payload;
        }
    }
});

export const { actions, reducer } = userSlice;
export default reducer;