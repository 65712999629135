import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    btn: {
        '& span': {
            '& div': {
                color: theme.colors.white,
                width: '7% !important',
                height: '50% !important',
                position: 'absolute',
                right: '10%'
            }
        }
    }
}));


function LoadingButton({ loading, children, ...rest }) {
    const classes = useStyles();
    return (
        <Button {...rest} className = {classes.btn}>
            {children}
            {/* {loading && <CircularProgress />} */}
        </Button>
    )
}

export { LoadingButton };