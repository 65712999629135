import React from 'react'
import { CircularProgress } from '@material-ui/core';

function CenterCircularProgress({ fullScreen }) {
    const style = { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' };
    if (fullScreen) {
        style.minHeight = '100vh';
    }
    return (
        <div style={style}>
            <CircularProgress />
        </div>);
}

export {
    CenterCircularProgress
};