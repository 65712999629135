import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { loadClaim, loadReferrals, actions } from '../claimsSlice';
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Container,
    Button,
    TextField,
    Typography,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';

import { ClaimReferrals } from './ClaimReferrals';
import { ClaimInfo } from '../ClaimInfo';
import {useForm} from "react-hook-form";

const  { clearClaim } = actions;

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    text: {
        color: theme.typography.color
    },
    whiteText: {
        color: theme.colors.white
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold
    },
    cardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white,
    },
    dateFilter: {
        width: '150px'
    },
    formMargin: {
        marginTop: '15px'
    }
}));



function CustomerClaimDetail() {

    const { claimId } = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const belowSm = useMediaQuery(theme.breakpoints.down('sm'));
    // const claimCollapsible = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    history.listen(_ => dispatch(clearClaim()));

    const { 
        claimLoading, claim, referrals, details, patientPhones, referralsLoading, referralFilterDate
    } = useSelector(state => state.claims.claimDetail);
    
    const { register, handleSubmit, reset, setValue } = useForm();

    useEffect(() => {
        dispatch(loadClaim({ claimId, userType: 'Customer' }));
    }, [dispatch, claimId]);

    useEffect(() => {
        setValue('startDate', referralFilterDate);
    }, [referralFilterDate, setValue]);

    const submitReferral = () => {
        history.push({
            pathname: '/referral/create',
            state: {
                claim
            }
        });
    };

    const onSubmit = values => {
        const { startDate, endDate } = values;
        dispatch(loadReferrals({ claimId, fromDate: startDate, toDate: endDate }));
    }

    const handleResetDateFilter = () => {
        reset();
    }

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <ClaimInfo loading={claimLoading} claim={claim} patientPhones={patientPhones}/>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Card variant="outlined">
                        <CardHeader title="Referrals" className={classes.header}>
                        </CardHeader>
                        <CardContent>
                            <Grid container justify={"space-between"}>
                                <Button onClick={submitReferral} className={classes.button} startIcon={<SendIcon/>} disabled={claimLoading} variant="contained" color="secondary">
                                    Submit New Referral
                                </Button>
                                <form onSubmit={handleSubmit(onSubmit)} className={belowSm ? classes.formMargin : null}>
                                    <Grid container item spacing={2}>
                                        <Grid item sm={4} xs={6}>
                                            <TextField
                                                name="startDate"
                                                label="Last Activity"
                                                type="date"
                                                InputLabelProps={{shrink: true}}
                                                inputRef={register}
                                                className={classes.dateFilter}
                                            />
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <TextField
                                                name="endDate"
                                                label="To Date"
                                                type="date"
                                                InputLabelProps={{shrink:true}}
                                                inputRef={register}
                                                className={classes.dateFilter}
                                            />
                                        </Grid>
                                        <Grid item sm={2}>
                                            <Button type="submit" variant="contained" color="secondary" disabled={referralsLoading}>
                                                Filter
                                            </Button>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <Button variant="contained" color="inherit" onClick={handleResetDateFilter}>
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>

                            <br/>
                            <ClaimReferrals referrals={referrals} details={details} />
                            <br/>
                            {referrals && referrals.length === 0 && !referralsLoading && <Typography align={'center'}>
                                No referrals found
                            </Typography>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export { CustomerClaimDetail };