import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doGet } from 'common/fetch';

export const searchInvoices = createAsyncThunk(
    'invoices/search',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { invoices: { criteria, page, limit, sort } } = getState();
            const { data, response, error } = await doGet({
                path: "vendor/invoiceSearch",
                params: {
                    ...criteria,
                    page, 
                    limit,
                    sortDirection: sort.direction,
                    sortProperty: sort.property
                }
            });

            if (response.ok) {
                return {
                    invoices: data.data,
                    totalInvoices: data.total
                };
            }

            return rejectWithValue(error);
        }
        catch (error) {
            rejectWithValue(error);
        }
    }
)

export const loadInvoice = createAsyncThunk(
    'invoices/loadInvoice',
    async ({ invoiceId }, { rejectWithValue }) => {
        try {
            const { data, response, error } = await doGet({
                path: "vendor/loadInvoice",
                params: { invoiceId }
            });

            if (response.ok) {
                return data;
            }

            return rejectWithValue(error);
        } catch (error) {
            rejectWithValue(error);
        }
    }
)

export const loadInvoiceAttachments = createAsyncThunk(
    'invoices/loadInvoiceAttachments',
    async ({ invoiceId }, { rejectWithValue }) => {
        try {
            const { data, response, error } = await doGet({
                path: "attachment/loadInvoiceAttachments",
                params: { invoiceId }
            });

            if (response.ok) {
                const attachments = data;
                return { attachments };
            }

            return rejectWithValue(error);
        } catch (error) {
            rejectWithValue(error);
        }
    }
)

export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: {
        loaded: false,
        loading: false,
        invoiceDetail: {},
        invoices: [],
        totalInvoices: 0,
        error: false,
        criteria: {},
        page: 1,
        limit: 25,
        sort: { 
            property: 'invoiceDate', 
            direction: 'desc' 
        }
    },
    reducers: {
        clearInvoice: state => {
            state.invoiceDetail = undefined
        },
        setCriteria: (state, action) => {
            state.criteria = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload;
        },
        setSort: (state, action) => {
            state.sort = action.payload;
        }
    },
    extraReducers: {
        [searchInvoices.pending]: state => {
            state.loaded = true;
            state.loading = true;
        },
        [searchInvoices.fulfilled]: (state, action) => {
            state.loading = false;
            state.invoices = action.payload.invoices;
            state.totalInvoices = action.payload.totalInvoices;
        },
        [searchInvoices.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
        [loadInvoice.pending]: state => {
            state.invoiceDetail.invoiceLoading = true;
            state.invoiceDetail.detailsLoading = true;
            state.invoiceDetail.invoice = undefined;
            state.invoiceDetail.details = undefined;
        },
        [loadInvoice.fulfilled]: (state, action) => {
            const { invoice, details, paymentDate, paymentDateLabel } = action.payload;
            state.invoiceDetail.invoiceLoading = false;
            state.invoiceDetail.detailsLoading = false;
            state.invoiceDetail.invoice = {
                ...invoice,
                paymentDate,
                paymentDateLabel
            }
            state.invoiceDetail.details = details;

            const usePayTo = !!invoice.payToStreet1;
            const useCorpPayTo = !usePayTo && !!invoice.corpPayToStreet1;
            
            const street1 = usePayTo ? 
                invoice.payToStreet1 : 
                    (useCorpPayTo ? 
                        invoice.corpPayToStreet1 :
                        invoice.vendorStreet1);
            const street2 = usePayTo ?
                (invoice.payToStreet2 || '') :
                (useCorpPayTo ?
                    (invoice.corpPayToStreet2 || '') :
                    (invoice.vendorStreet2 || ''));
            const city = usePayTo ?
                invoice.payToCity :
                (useCorpPayTo ?
                    invoice.corpPayToCity :
                    invoice.vendorCity);
            const payToState = usePayTo ?
                invoice.payToState :
                (useCorpPayTo ?
                    invoice.corpPayToState :
                    invoice.vendorState);
            const zip = usePayTo ?
                invoice.payToZip :
                (useCorpPayTo ?
                    invoice.corpPayToZip :
                    invoice.vendorZip);
            const zip4 = usePayTo ?
                invoice.payToZip4 :
                (useCorpPayTo ?
                    invoice.corpPayToZip4 :
                    invoice.vendorZip4);

            state.invoiceDetail.invoice.payToAddressLine1 = `${street1} ${street2}`;
            state.invoiceDetail.invoice.payToAddressLine2 = `${city}, ${payToState} ${zip}${zip4 ? `-${zip4}` : ''}`;
        },
        [loadInvoice.rejected]: (state, action) => {
            state.invoiceDetail.invoiceLoading = false;
            state.invoiceDetail.detailsLoading = false;
            state.invoiceDetail.invoice = undefined;
            state.invoiceDetail.details = undefined;
            state.error = action.error;
        },
        [loadInvoiceAttachments.pending]: state => {
            state.invoiceDetail.attachmentsLoading = true;
            state.invoiceDetail.attachments = undefined;
        },
        [loadInvoiceAttachments.fulfilled]: (state, action) => {
            state.invoiceDetail.attachmentsLoading = false;
            state.invoiceDetail.attachments = action.payload.attachments;
        },
        [loadInvoiceAttachments.rejected]: (state, action) => {
            state.invoiceDetail.attachmentsLoading = false;
            state.invoiceDetail.attachments = undefined;
            state.error = action.error;
        }
    }
});

export const { actions, reducer } = invoicesSlice;

export default reducer;