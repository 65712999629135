import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    TextField,
    Button,
    Container,
    Link
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { DialogWindow as ViewPrivacyPolicyModal } from "../common/DialogWindow";
import { login, ssoCheck } from './authSlice';

const useStyles = makeStyles(theme => ({
    alert: {
        marginTop: '40px'
    },
    card: {
        width: '100%'
    },
    content: {
        padding: '5px',
        backgroundColor: theme.palette.primary.main,
        paddingBottom: '5px !important'
    },
    actions: {
        marginTop: '5px'
    },
    header: {
        color: theme.colors.white,
        marginLeft: '5px',
        paddingTop: '5px',
        paddingBottom: '10px'
    },
    textField: {
        width: '100%',
        marginBottom: '10px'
    },
    logo: {
        width: '300px',
        marginTop: '80px',
        marginBottom: '80px'
    },
    logoDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    form: {
        backgroundColor: theme.colors.white,
        padding: '10px'
    },
    link: {
        cursor: "pointer"
    },
    linkContainer: {
        marginTop: '10px'
    },
    pipe: {
        color: theme.palette.grey['500']
    }
}));

function getRedirectFromQueryString(query) {
    const segments = query.replace('?', '').split('=');
    const redirectIndex = segments.indexOf('redirect');
    if (redirectIndex === -1 || segments.length < redirectIndex + 2) { // +1 for next element and +1 for 0-indexing
        return '/';
    }
    else {
        // guard against malformed redirect paths
        try {
            return decodeURIComponent(segments[redirectIndex + 1]);
        }
        catch(error) {
            return '/';
        }
    }
}

function LoginPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { handleSubmit, register, errors, getValues } = useForm();
    const { 
        pending, 
        error: authError,
        authorized, 
        verificationCodeRequired, 
        verificationCodeToken, 
        passwordRequired,
        privacyPolicyLoading,
        privacyPolicyDate,
        privacyPolicyContent,
    } = useSelector(state => state.auth);

    const privacyPolicyTitle = `Version: ${privacyPolicyDate}`;

    const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);

    const handleClickPrivacyPolicy = () => {
        setOpenPrivacyPolicyModal(true);
    }

    const handleClosePrivacyPolicyModal = () => {
        setOpenPrivacyPolicyModal(false);
    }

    const location = useLocation();
    const history = useHistory();

    const handleClickResetAccount = () => {
        history.push("/auth/forgot");
    }

    const { from } = !!location.state
        ? location.state
        : { from: { pathname: getRedirectFromQueryString(window.location.search) }};

    useEffect(() => {
        if (authorized) {
            history.replace(from);
        } 
    }, [authorized, from, history]);
    
    const onSubmit = (values) => {           
        values['verificationCode'] = verificationCodeRequired ? values.verificationCode : null;
        values['vctoken'] = verificationCodeRequired ? verificationCodeToken : null;
        dispatch(passwordRequired ? login(values) : ssoCheck(values));
    }

    return (
        <>      
        <Container maxWidth="xs" className={classes.container}>
            <ViewPrivacyPolicyModal content={privacyPolicyContent} title={privacyPolicyTitle} buttonText={'CLOSE'} open={openPrivacyPolicyModal} handleClick={handleClosePrivacyPolicyModal} isLoading={privacyPolicyLoading}/>
            <div className={classes.logoDiv}>
                <img alt="Logo" className={classes.logo} src="https://cdn.homecareconnect.com/images/paradigm_orange_logo.svg"/>
            </div>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Typography className={classes.header} variant="h5">
                        CareLink
                    </Typography>                                   
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>                     
                        <TextField 
                            className = {classes.textField} 
                            name = "username" 
                            label = "Email" 
                            inputRef = {register({
                                required: "required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            error = { !!errors.username }
                            helperText = { errors.username && errors.username.message }
                        />                   
                        <br/>
                        { passwordRequired && <TextField 
                            className = {classes.textField} 
                            type = "password" 
                            name = "password" 
                            label = "Password" 
                            inputRef = {register({
                                required: "required"
                            })}
                            error = { !!errors.password }
                            helperText = { errors.password && errors.password.message }
                            autoFocus = { !verificationCodeRequired }
                        /> }                  
                        { verificationCodeRequired && <TextField 
                            className = {classes.textField} 
                            type = "text"
                            name = "verificationCode" 
                            label = "Verification Code" 
                            inputRef = {register({
                                required: "required"
                            })}
                            autoFocus
                        /> }
                        <CardActions className={classes.actions}>
                            <Grid container direction="row-reverse" justify="space-between" alignItems="center">
                                <Button 
                                    disabled = { pending }
                                    type = "submit" 
                                    variant = "contained" 
                                    color = "secondary"
                                >
                                    {pending ? 'Loading...' : passwordRequired ? 'Login' : 'Next'}
                                </Button>
                                { passwordRequired && !verificationCodeRequired && <Button 
                                    disabled = { pending }
                                    onClick = {handleClickResetAccount}
                                    size = "small"
                                    color = "default"
                                >
                                    Forgot Password
                                </Button>}
                            </Grid>
                        </CardActions>
                    </form>         
                </CardContent>
            </Card>
            <br></br>
            {!verificationCodeRequired &&
                <Grid container direction="row" justify="space-around" alignItems="center" className={classes.linkContainer}>
                    <Typography align={'center'} className={classes.link}>
                        <Link onClick={handleClickPrivacyPolicy}>Privacy Policy</Link>
                    </Typography>
                    <Typography>
                        <span className={classes.pipe}>|</span>
                    </Typography>
                    <Typography align={'center'} className={classes.link}>
                        <Link onClick={() => history.push({
                            pathname: '/auth/signup',
                            state: { username: getValues('username') }
                        })}>Request Account</Link>
                    </Typography>
                </Grid>
            }

            { authError && <Alert className={classes.alert} severity="error">{authError}</Alert> }
        </Container>        
        </>
    );
}


export { LoginPage }