import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doGet } from 'common/fetch';

export const findServices = createAsyncThunk(
    'referral/findServices',
    async (_, { rejectWithValue }) => {
        const { data, response } = await doGet({
            path: "referral/findServices"
        });
        if (response.ok) {
            return data;
        }
        return rejectWithValue(false);
    }
);

export const serviceSlice = createSlice({
    name: 'service',
    initialState: {
        servicesPending: true,
        services: [],
        servicesError: false
    },
    reducers: { },
    extraReducers: {
        [findServices.fulfilled]: (state, action) => {
            state.servicesPending = false
            state.services = action.payload;
        },
        [findServices.rejected]: state => {
            state.servicesPending = false;
            state.servicesError = true;
        }
    }
});

export const { actions, reducer } = serviceSlice;
export default reducer;