import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Typography, TextField, Button, Container, Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import InputMask from 'react-input-mask';

import { postProfile, postPassword } from './userSlice';
import {  stripMask, isLength, isLengthBetween } from '../common/validate';

const useStyles = makeStyles(({
    alert: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    progress: {
        marginLeft: '10px'
    },
    textField: {
        width: '100%'
    },
    header: {
        marginLeft: '10px',
        marginTop: '20px',
        marginBottom: '20px'
    },
    btn: {
        backgroundColor: '#3892d3',
        '&:hover': {
            backgroundColor: '#91c2e6'
        }
    },
    cardTitle: {
        marginTop: '10px',
        marginLeft: '15px',
        marginBottom: '5px',
        color: 'rgb(80,80,80)'
    },
    card: {
        padding: '15px',
        marginBottom: '20px'
    }
}));

function ProfileForm() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { profile } = useSelector(state => state.user);
    const { firstName, lastName, company, email, officePhone, cellPhone } = profile;
    const { pending, success, error } = useSelector(state => state.user.forms.profile);
    const {handleSubmit, register, control, errors} = useForm();

    return (
        <Card className={classes.card} elevation={2}>
        { !profile ? (
            !error && <CircularProgress className={classes.progress} />
        ) : (
            <form onSubmit={handleSubmit(values => dispatch(postProfile(values)))}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textField} 
                            label = "First Name" 
                            name = "firstName"
                            defaultValue = {firstName}
                            error = { !!errors.firstName }
                            helperText = { errors.firstName && errors.firstName.message }
                            inputRef = {register({
                                required: 'required'
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textField} 
                            label = "Last Name" 
                            name = "lastName"
                            defaultValue = {lastName}
                            error = { !!errors.lastName }
                            helperText = { errors.lastName && errors.lastName.message }
                            inputRef = {register({
                                required: 'required'
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            className = {classes.textField} 
                            name = "company"
                            label = "Company" 
                            defaultValue = {company}
                            InputProps = {{ readOnly: true }}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            className = {classes.textField} 
                            name = "email"
                            label = "Email" 
                            defaultValue = {email}
                            InputProps = {{ readOnly: true }}
                            inputRef = {register}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "officePhone"
                            defaultValue = {officePhone}
                            as = {
                                <InputMask mask="(999) 999-9999 x99999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Office Phone"
                                            error = {!!errors.officePhone}
                                            helperText = {errors.officePhone && errors.officePhone.message}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLengthBetween(10,15))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control = {control}
                            name = "cellPhone"
                            defaultValue = {cellPhone}
                            as = {
                                <InputMask mask="(999) 999-9999" maskChar=" ">
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            label = "Cell Phone"
                                            error = {!!errors.cellPhone}
                                            helperText = {errors.cellPhone && errors.cellPhone.message}
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ ()-]/g)(isLength(10))
                            }}
                        />
                    </Grid>
                    <Grid container item direction="row" justify="flex-end" xs={12}>
                        <Button 
                            disabled = {pending}
                            type = "submit" 
                            className = {classes.btn} 
                            variant = "contained" 
                            color = "primary"
                        >
                            { pending ? 'Updating...' : 'Update' }
                        </Button>
                    </Grid>
                </Grid>
                { error && <Alert className={classes.alert} severity="warning">{error}</Alert> }
                { success && <Alert className={classes.alert}>Profile updated</Alert> }
            </form>
        )}
        </Card>
    )
}

function PasswordForm() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { pending, success, error } = useSelector(state => state.user.forms.password);
    const {handleSubmit, register, errors, watch} = useForm();
    const newPassword = watch('newPassword');

    return (
        <Card className={classes.card} elevation={2}>
            <form onSubmit={handleSubmit(values => dispatch(postPassword(values)))}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textField} 
                            type = "password"
                            name = "newPassword"
                            label = "New Password" 
                            inputRef = {register({
                                required: 'required'
                            })}
                            error = { !!errors.newPassword }
                            helperText = { errors.newPassword && errors.newPassword.message }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textField} 
                            type = "password"
                            name = "confirmNewPassword"
                            disabled = {!newPassword}
                            label = "Confirm New Password"
                            inputRef = {register({
                                required: 'required'
                            })}
                            error = { !!errors.confirmNewPassword }
                            helperText = { errors.confirmNewPassword && errors.confirmNewPassword.message }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            className = {classes.textField} 
                            type = "password"
                            name = "password"
                            disabled = {!newPassword}
                            label = "Current Password" 
                            inputRef = {register({
                                required: 'required'
                            })}
                            error = { !!errors.password }
                            helperText = { errors.password && errors.password.message }
                        />
                    </Grid>
                    <Grid container item direction="row" justify="flex-end" xs={12}>
                        <Button 
                            disabled = {!newPassword || pending}
                            type = "submit" 
                            className = {classes.btn} 
                            variant = "contained" 
                            color = "primary"
                        >
                            { pending ? 'Please Wait...' : 'Change Password' }
                        </Button>
                    </Grid>
                </Grid>
                { error && <Alert className={classes.alert} severity="warning">{error}</Alert> }
                { success && <Alert className={classes.alert}>Password changed</Alert> }
            </form>
        </Card>
    )
}

function ProfilePage() {
    const classes = useStyles();

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" className={classes.header} color="primary">
                My Profile
            </Typography>
            <ProfileForm/>  
            <Typography variant="subtitle1" className={classes.cardTitle}>
                Change Password
            </Typography>
            <PasswordForm/>
        </Container>
    );
}

export { ProfilePage }