import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { doGet } from '../common/fetch';

export const findLocations = createAsyncThunk(
    'vendor/findLocations',
    async (_, { rejectWithValue }) => {
        const { data, response } = await doGet({
            path: 'vendor/findLocations'
        });
        if (response.ok) {
            return { 
                locations: data
            };
        }
        return rejectWithValue(false);
    }
);

export const vendorSlice = createSlice({
    name: 'vendor',
    initialState: {
        locations: [],
        forms: {
            vendor: {}
        }
    },
    reducers: {
    },
    extraReducers: {
        [findLocations.pending]: state => {
            state.pending = true;
        },
        [findLocations.fulfilled]: (state, action) => {
            state.pending = false;
            state.locations = action.payload.locations;
        },
        [findLocations.rejected]: state => {
            state.pending = false;
            state.locations = [];
            state.forms.vendor.error = 'Error loading provider locations';
        },
    }
});

export const { actions, reducer } = vendorSlice;
export default reducer;