import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, Card, CardContent, CardActions, Typography, TextField, Button, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { reset } from './authSlice';

const useStyles = makeStyles(theme => ({
    alert: {
        marginTop: '40px'
    },
    card: {
        width: '100%'
    },
    content: {
        padding: '5px',
        backgroundColor: theme.palette.primary.main,
        paddingBottom: '5px !important'
    },
    actions: {
        marginTop: '5px'
    },
    header: {
        color: theme.colors.white,
        marginLeft: '5px',
        paddingTop: '5px',
        paddingBottom: '10px'
    },
    textField: {
        width: '100%',
        marginBottom: '10px'
    },
    logo: {
        width: '300px',
        marginTop: '80px',
        marginBottom: '80px'
    },
    logoDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    form: {
        backgroundColor: theme.colors.white,
        padding: '10px'
    }
}));

function ResetPage() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { handleSubmit, register, watch, errors } = useForm();
    const loading = useSelector(state => state.auth.pending);
    const error = useSelector(state => state.auth.error);
    const authorized = useSelector(state => state.auth.authorized);
    const { token } = useParams();
    const history = useHistory();
    
    const newPassword = watch('newPassword');
    const onSubmit = values => {
        dispatch(reset(values));
    }

    useEffect(() => {
        if (authorized) {
            history.replace("/home");
        }
    }, [authorized, history]);

    return (
        <Container maxWidth="xs" className={classes.container}>
            <div className={classes.logoDiv}>
                <img alt="Logo" className={classes.logo} src="https://cdn.homecareconnect.com/images/paradigm_orange_logo.svg"/>
            </div>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Typography className={classes.header} variant="h5">
                        CareLink
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <input type="hidden" value={token} name="token" ref={register}/>
                        <TextField 
                            className = {classes.textField} 
                            name = "username" 
                            label = "Confirm Email" 
                            inputRef = {register({
                                required: "required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            error = { !!errors.username }
                            helperText = { errors.username && errors.username.message }
                        />
                        <br/>
                        <TextField 
                            className = {classes.textField} 
                            name = "securityCode" 
                            label = "Security Code" 
                            inputRef = {register({
                                required: "required"
                            })}
                            error = { !!errors.securityCode }
                            helperText = { errors.securityCode && errors.securityCode.message }
                        />
                        <br/>
                        <TextField 
                            className = {classes.textField} 
                            name = "newPassword"
                            type = "password" 
                            label = "Create Password" 
                            inputRef = {register({
                                required: "required"
                            })}
                            error = { !!errors.newPassword }
                            helperText = { errors.newPassword && errors.newPassword.message }
                        />
                        <br/>
                        <TextField 
                            className = {classes.textField} 
                            name = "confirmNewPassword"
                            type = "password" 
                            label = "Confirm Password" 
                            inputRef = {register({
                                required: "required",
                                validate: value => value !== newPassword ? 'Does not match' : undefined
                            })}
                            error = { !!errors.confirmNewPassword }
                            helperText = { errors.confirmNewPassword && errors.confirmNewPassword.message }
                        />
                        <CardActions className={classes.actions}>
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                <Button 
                                    disabled = { loading }
                                    type = "submit" 
                                    variant = "contained" 
                                    color = "secondary"
                                >
                                    {loading ? 'Loading...' : 'Login'}
                                </Button>
                            </Grid>
                        </CardActions>
                    </form>
                </CardContent>
            </Card>
            { error && <Alert className={classes.alert} severity="error">{error}</Alert> }
        </Container>
    );
}


export { ResetPage }