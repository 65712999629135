import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardHeader, CardContent, Grid, Typography, TextField, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Tooltip, InputAdornment } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { DataTable, LoadingButton } from 'common';
import { stripMask, isDate } from 'common/validate';
import { searchClaims, actions } from './claimsSlice';

const { setCriteria, setPage, setLimit, setSort } = actions;

const CLAIM_COLUMNS = [
    { Header: 'Claim Number', accessor: 'claimNumber' },
    { Header: 'Patient Name', accessor: 'patientName', Cell: cell => {
        return `${cell.row.original.patientLastName}, ${cell.row.original.patientFirstName}`; }
    },
    { Header: 'Date of Injury', accessor: 'injuryDate', Cell: cell => cell.value && moment(cell.value).format('L') },
    { Header: 'Date of Birth', accessor: 'patientBirthDate', Cell: cell => cell.value && moment(cell.value).format('L') }
];

const useStyles = makeStyles(theme => ({
    submit: {
        margin: '5px 5px 5px 10px',
        '& button': { 
            marginTop: '10px'
        }
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        color: theme.colors.white
    },
    expandIcon: {
        color: theme.colors.white
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

function ClaimSearchForm({loading}) {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const dispatch = useDispatch();
    const { criteria } = useSelector(state => state.claims);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);

    const { 
        handleSubmit, register, control, errors, setValue, getValues,
    } = useForm({
        defaultValues: criteria
    });

    const onSubmit = (data, e) => {
        dispatch(setCriteria(data));
        dispatch(searchClaims());
    }
    const onError = (errors, e) => console.log(errors);

    const validateSearch = () => {
        const { patientFirstName, patientLastName, claimNumber, referralNumber } = getValues();
        if (!patientFirstName && !patientLastName && !claimNumber && !referralNumber) {
            return 'Provide claim number, referral number, or patient name';
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Search by referral number
                        <span style={{float: 'right'}}>
                            <HtmlTooltip title={
                                <Typography variant="body1">
                                    Searching by referral number disables other search criteria
                                </Typography>}>
                                <InfoIcon />
                            </HtmlTooltip>
                        </span>
                    </Typography>
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField 
                        className={classes.textField}
                        fullWidth
                        name = "referralNumber"
                        label = "Referral Number"
                        error = { !!errors.referralNumber }
                        helperText = { errors.referralNumber && errors.referralNumber.message }
                        onChange = {({target: {value}}) => {
                            if (value && value.replace(/[^0-9]/g,'') !== value) {
                                setValue('referralNumber', value.replace(/[^0-9]/g,''));
                            }
                            setFieldsDisabled(!!value);
                        }}
                        inputRef = {register({
                            validate: validateSearch
                        })}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R</InputAdornment>,
                        }}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Search by other criteria
                    </Typography>
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField
                        className={classes.textField}
                        disabled = {fieldsDisabled}
                        fullWidth
                        name = "claimNumber"
                        label = "Claim Number"
                        error = { !!errors.claimNumber }
                        helperText = { errors.claimNumber && errors.claimNumber.message }
                        inputRef = {register({
                            validate: validateSearch
                        })}
                        variant="outlined"
                        margin="none" />
                </Grid>
                
                <Grid item md={12} sm={6} xs={12}>
                    <TextField 
                        className={classes.textField}
                        disabled = {fieldsDisabled}
                        fullWidth
                        name = "patientFirstName"
                        label = "Patient First Name"
                        error = { !!errors.patientFirstName }
                        helperText = { errors.patientFirstName && errors.patientFirstName.message }
                        inputRef = {register({
                            validate: validateSearch
                        })}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField 
                        className={classes.textField}
                        disabled = {fieldsDisabled}
                        fullWidth
                        name = "patientLastName"
                        label = "Patient Last Name"
                        error = { !!errors.patientLastName }
                        helperText = { errors.patientLastName && errors.patientLastName.message }
                        inputRef = {register({
                            validate: validateSearch
                        })}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <Controller
                        control = {control}
                        name = "injuryDate"
                        defaultValue = ""
                        as = {
                            <InputMask mask="99/99/9999" maskChar=" " disabled = {fieldsDisabled}>
                                {(props) => (
                                    <TextField
                                        className = {classes.textField}
                                        disabled = {fieldsDisabled}
                                        fullWidth
                                        label = "Injury Date"
                                        error = {!!errors.injuryDate}
                                        helperText = {errors.injuryDate && errors.injuryDate.message}
                                        variant="outlined"
                                        margin="none"
                                        {...props}
                                    />
                                )}
                            </InputMask>
                        }
                        rules = {{
                            validate: stripMask(/[ /]/g)(isDate)
                        }}
                    />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <Controller
                            control = {control}
                            name = "patientBirthDate"
                            defaultValue = ""
                            as = {
                                <InputMask mask="99/99/9999" maskChar=" " disabled={fieldsDisabled} >
                                    {(props) => (
                                        <TextField
                                            className = {classes.textField}
                                            disabled = {fieldsDisabled}
                                            fullWidth
                                            label = "Birth Date"
                                            error = {!!errors.patientBirthDate}
                                            helperText = {errors.patientBirthDate && errors.patientBirthDate.message}
                                            variant="outlined"
                                            margin="none"
                                            {...props}
                                        />
                                    )}
                                </InputMask>
                            }
                            rules = {{
                                validate: stripMask(/[ /]/g)(isDate)
                            }}
                        />
                </Grid>
                <Grid container direction="row" justify = "flex-end" className={classes.submit}>
                    <LoadingButton
                        onClick={validateSearch}
                        startIcon = {<SearchIcon />}
                        loading = {loading} 
                        fullWidth={!isSmall}
                        type = "submit"
                        variant = "contained"
                        color = "secondary"
                        value = "Search">
                            Search
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
}

function Claims() {

    // Hooks
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const showAccordion = useMediaQuery(theme.breakpoints.down('sm'));

    // Store values
    const { 
        loaded, loading, claims, totalClaims, page, limit, sort 
    } = useSelector(state => state.claims);

    // Search once on initial load
    useEffect(() => {
        if (!loaded) {
            dispatch(searchClaims());
        }
    }, [dispatch, loaded]);
    
    // Event handlers
    const onRowsPerPageChange = (event) => {
        const rowsPerPage = parseInt(event.target.value);
        dispatch(setLimit(rowsPerPage));
        dispatch(setPage(1));
        dispatch(searchClaims());
    };

    const onPageChange = (page) => {        
        dispatch(setPage(page + 1));
        dispatch(searchClaims());
    }

    const onSortChange = (property) => {
        const direction = property === sort.property 
            ? sort.direction === 'asc' 
                ? 'desc'
                : 'asc' 
            :'asc';
        const newSort = { property, direction };
        dispatch(setSort(newSort));
        dispatch(setPage(1));
        dispatch(searchClaims());
    };

    const onRowClick = (selectedIndex) => {
        if (selectedIndex >= 0) {
            const claim = claims[selectedIndex];
            history.push(`/claim/${claim.claimId}`);
        }
    }

    return (
        
        <Grid container spacing={2} style={{ maxWidth: '9999px' }}>
            <Grid item xs={12} md={3}> 
                { showAccordion && 
                    <Accordion defaultExpanded = {true}>
                        <AccordionSummary style={{ height: '50px', minHeight: '50px !important' }} expandIcon={<ExpandMoreIcon className={classes.expandIcon} />} className={classes.header} >
                            <Typography variant="h6">
                                    Search Claims
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ClaimSearchForm loading = {loading} />
                        </AccordionDetails>
                    </Accordion>
                }
                { !showAccordion &&
                    <Card>
                        <CardHeader className={classes.header} style={{ padding: '13px' }} title="Search Claims" titleTypographyProps={{ variant: "h6" }} />
                        <CardContent>
                            <ClaimSearchForm loading = {loading} />
                        </CardContent>
                    </Card>
                }
            </Grid>
            <Grid item xs={12} md={9}>
                <DataTable 
                    loading = {loading} 
                    columns = {CLAIM_COLUMNS} 
                    data = {claims}
                    totalCount = {totalClaims}
                    currentPage={page}
                    onPageChange={onPageChange}
                    rowsPerPage = {limit}
                    sort = {sort}
                    onSortChange = {onSortChange}
                    sortBy = {sort.property}
                    sortDirection = {sort.direction}
                    labelRowsPerPage = "Claims per page"
                    onRowsPerPageChange = {onRowsPerPageChange}
                    onRowClick = {onRowClick}
                />
            </Grid>
        </Grid>
    )
}

export { Claims };