let autoCompleteService, placeService;

export const getPlacePredictions = (input, sessionToken, callback) => {
    if (!window.google) {
        return null;
    } 
    if (!autoCompleteService) {
        autoCompleteService = new window.google.maps.places.AutocompleteService();
    }
    if (!sessionToken) {
        sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    }
    if (!input || input.trim() === '') {
        callback([]);
    } else {
        autoCompleteService.getPlacePredictions({
            input,
            sessionToken,
            types: ['address']
        }, (predictions) => {
            callback(predictions || []);
        });
    }
    return sessionToken;
};

export const getPlaceDetails = (selection, sessionToken, callback) => {
    if (!window.google) {
        return;
    }
    if (!placeService) {
        placeService = new window.google.maps.places.PlacesService(document.createElement('div'));
    }
    const {place_id: placeId} = selection;
    placeService.getDetails({
        placeId,
        sessionToken,
        fields: ['address_components','geometry']
    }, ({address_components: components, geometry}) => {
        let street = '', city, state, zip = '';
        components.forEach(({short_name: value, types}) => {
            switch (types[0]) {
                case 'street_number':
                    street = `${value}${street}`;
                    break;
                case 'route':
                    street = `${street} ${value}`;
                    break;
                case 'locality':
                    city = value;
                    break;
                case 'administrative_area_level_1':
                    state = value;
                    break;
                case 'postal_code':
                    zip = `${value}${zip}`;
                    break;
                case 'postal_code_suffix':
                    zip = `${zip}${value}`;
                    break;
                default:
                    break;
            }
        });
        callback({
            street,
            city,
            state,
            zip,
            lat: geometry.location.lat(),
            lng: geometry.location.lng()
        });
    });
}