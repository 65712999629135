import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, useMediaQuery, Card, CardHeader, TextField, CardContent } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ErrorSharp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

import { DataTable, LoadingButton } from 'common';
import { searchInvoices, actions } from './invoicesSlice';

const { setCriteria, setPage, setLimit, setSort } = actions;

const INVOICE_COLUMNS = [
    { Header: 'Invoice Date', width: '15%', accessor: 'invoiceDate', Cell: cell => cell.value && moment(cell.value).format('L')},
    { Header: 'Invoice Number', width: '25%', accessor: 'invoiceNumber' },
    { Header: 'Total', width: '15%', accessor: 'invoiceTotal', Cell: cell => cell.value && "$ " + cell.value.toFixed(2)},
    { Header: 'Insured ID', width: '25%', accessor: 'claimNumber' },
    { Header: 'Patient Name', width: '20%', accessor: 'patientName' }
];

const useStyles = makeStyles(theme => ({
    submit: {
        margin: '5px 5px 5px 10px',
        '& button': { 
            marginTop: '10px'
        }
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        color: theme.colors.white
    },
    expandIcon: {
        color: theme.colors.white
    }
}));

function InvoiceSearchForm({ loading }) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const { criteria } = useSelector(state => state.invoices);

    const { 
        handleSubmit, register, errors 
    } = useForm({
        defaultValues: criteria
    });

    const onSubmit = (data, e) => {
        dispatch(setCriteria(data));
        dispatch(searchInvoices());
    }
    const onError = (errors, e) => console.log(errors);

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container item spacing={2}>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        name = "invoiceNumber"
                        label = "Invoice Number"
                        error = { !!errors.invoiceNumber }
                        helperText = { errors.invoiceNumber && errors.invoiceNumber.message }
                        inputRef = {register}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField
                        fullWidth
                        className = {classes.textField}
                        name = "invoiceDate"
                        label = "Invoice Date"
                        type = "date"
                        InputLabelProps = {{shrink: true}}
                        error = {!!errors.invoiceDate}
                        helperText = {errors.invoiceDate && errors.invoiceDate.message}
                        inputRef = {register}
                        variant="outlined"
                        margin="none"
                    />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        name = "checkNumber"
                        label = "Check Number"
                        error = { !!errors.checkNumber }
                        helperText = { errors.checkNumber && errors.checkNumber.message }
                        inputRef = {register}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField
                        className={classes.textField} 
                        fullWidth
                        name = "claimNumber"
                        label = "Insured ID"
                        error = { !!ErrorSharp.claimNumber }
                        helperText = { errors.claimNumber && errors.claimNumber.message }
                        inputRef = {register}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField 
                        className={classes.textField}
                        fullWidth
                        name = "patientFirstName"
                        label = "Patient First Name"
                        error = { !!errors.patientFirstName }
                        helperText = { errors.patientFirstName && errors.patientFirstName.message }
                        inputRef = {register}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField 
                        className={classes.textField}
                        fullWidth
                        name = "patientLastName"
                        label = "Patient Last Name"
                        error = { !!errors.patientLastName }
                        helperText = { errors.patientLastName && errors.patientLastName.message }
                        inputRef = {register}
                        variant="outlined"
                        margin="none" />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField
                        fullWidth
                        className = {classes.textField}
                        name = "patientBirthDate"
                        label = "Birth Date"
                        type = "date"
                        InputLabelProps = {{shrink: true}}
                        error = {!!errors.patientBirthDate}
                        helperText = {errors.patientBirthDate && errors.patientBirthDate.message}
                        inputRef = {register}
                        variant="outlined"
                        margin="none"
                    />
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                    <TextField
                        fullWidth
                        className = {classes.textField}
                        name = "dateOfService"
                        label = "Date of Service"
                        type = "date"
                        InputLabelProps = {{shrink: true}}
                        error = {!!errors.dateOfService}
                        helperText = {errors.dateOfService && errors.dateOfService.message}
                        inputRef = {register}
                        variant="outlined"
                        margin="none"
                    />
                </Grid>
                <Grid container direction="row" justify="flex-end" className={classes.submit}>
                    <LoadingButton
                        startIcon = {<SearchIcon />}
                        loading = {loading}
                        fullWidth={!isSmall}
                        type = "submit"
                        variant = "contained"
                        color = "secondary"
                        value = "Search">
                            Search
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}

function Invoices() {
    // Hooks
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const showAccordion = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    // Store values
    const { 
        loaded, loading, invoices, totalInvoices, page, limit, sort 
    } = useSelector(state => state.invoices);

    // Search once on initial load
    useEffect(() => {
        if (!loaded) {
            dispatch(searchInvoices());
        }
    }, [dispatch, loaded]);

    // Event hanlders
    const onPageChange = (page) => {
        dispatch(setPage(page + 1));
        dispatch(searchInvoices());
    }

    const onSortChange = (property) => {
        const direction = property === sort.property
            ? sort.direction === 'asc'
                ? 'desc'
                : 'asc'
            : 'asc';
        const newSort = { property, direction };
        dispatch(setSort(newSort));
        dispatch(setPage(1));
        dispatch(searchInvoices());
    }

    const onRowsPerPageChange = (event) => {
        const rowsPerPage = parseInt(event.target.value);
        dispatch(setLimit(rowsPerPage));
        dispatch(setPage(1));
        dispatch(searchInvoices());
    }

    const onRowClick = (selectedIndex) => {
        if (selectedIndex >= 0) {
            const invoice = invoices[selectedIndex];
            history.push(`/invoice/${invoice.id}`);
        }
    }

    return (
        <Grid container spacing={2} style={{ maxWidth: '9999px' }}>
            <Grid item xs={12} md={3}>
                { showAccordion &&
                    <Accordion defaultExpanded = {true}>
                        <AccordionSummary style={{ height: '50px', minHeight: '50px !important' }} expandIcon={<ExpandMoreIcon className={classes.expandIcon} />} className={classes.header}>
                            <Typography variant="h6">
                                Search Invoices
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <InvoiceSearchForm loading={loading} />
                        </AccordionDetails>
                    </Accordion>
                }
                { !showAccordion &&
                    <Card>
                        <CardHeader className={classes.header} style={{ padding: '13px' }} title="Search Invoices" titleTypographyProps={{ variant: "h6" }} />
                        <CardContent>
                            <InvoiceSearchForm loading={loading} />
                        </CardContent>
                    </Card>
                }
            </Grid>
            <Grid item xs={12} md={9}>
                <DataTable
                    loading = {loading}
                    columns = {INVOICE_COLUMNS}
                    data = {invoices}
                    totalCount = {totalInvoices}
                    currentPage = {page}
                    onPageChange = {onPageChange}
                    rowsPerPage = {limit}
                    sort = {sort}
                    onSortChange = {onSortChange}
                    sortBy = {sort.property}
                    sortDirection = {sort.direction}
                    labelRowsPerPage = "Invoices per page"
                    onRowsPerPageChange = {onRowsPerPageChange}
                    onRowClick = {onRowClick}
                />
            </Grid>
        </Grid>
    )
}

export { Invoices };