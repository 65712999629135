import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { usePermissions } from 'auth/permissions';

const useStyles = makeStyles(theme => ({
    logo: {
        width: '300px',
        marginTop: '80px',
        marginBottom: '80px'
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    }
}));

const PrivateRoute = ({ requiredPermission, children, ...rest }) => {

    const classes = useStyles();
    const authPending = useSelector(state => state.auth.pending);
    const authorized = useSelector(state => state.auth.authorized);
    const hasPermission = usePermissions();

    if (requiredPermission && !hasPermission(requiredPermission)) {
        return <Redirect to="/home" />;
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                authorized ? (
                    children
                ) : authPending ? (
                    <div>
                        <div className={classes.flex}>
                            <img alt="Logo" className={classes.logo} src="https://cdn.homecareconnect.com/images/paradigm_orange_logo.svg"/>
                        </div>
                        <div className={classes.flex}>
                            <CircularProgress />
                        </div>
                    </div>
                ) : (
                    <Redirect to = {{
                        pathname: "/auth/login",
                        state: { from: location }
                    }}/>
                )
            }
        />
    );
};
export { PrivateRoute };