import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {doGet} from "../common/fetch";

export const loadReferralDetails = createAsyncThunk(
    'referralDetails/loadReferralsDetails',
    async (referralId, { getState, rejectWithValue }) => {
        try {
            const { referralDetails: {detailsByReferral} } = getState();
            const { page, limit, sort: { property: sortProperty, direction: sortDirection } } = detailsByReferral[referralId];
            const { data, response, error } = await doGet({
                path: `referral/loadReferralDetails`,
                params: {
                    referralId,
                    page,
                    limit,
                    sortProperty,
                    sortDirection
                }
            });

            const { data: details, total } = data;

            if (response.ok) {
                return {
                    details: details,
                    total: total,
                    referralId
                }
            }

            return rejectWithValue({ error, referralId });
        }
        catch (error) {
            rejectWithValue({ error, referralId });
        }
    }
)

export const referralDetailsSlice = createSlice({
    name: 'referralDetails',
    initialState: {
        detailsByReferral: {}
    },
    reducers: {
        setPage: (state, action) => {
            const { referralId, page } = action.payload;
            state.detailsByReferral[referralId].page = page;
        },
        setLimit: (state, action) => {
            const { referralId, limit } = action.payload;
            state.detailsByReferral[referralId].limit = limit;
        },
        setSort: (state, action) => {
            const { referralId, sort } = action.payload;
            state.detailsByReferral[referralId].sort = sort;
        }
    },
    extraReducers: {
        [loadReferralDetails.pending]: (state, action) => {
            const referralId = action.meta.arg;
            if (state.detailsByReferral[referralId]) {
                state.detailsByReferral[referralId].loading = true;
            } else {
                state.detailsByReferral[referralId] = {
                    loading: true,
                    details: [],
                    page: 1,
                    limit: 10,
                    sort: {
                        property: 'sortDate',
                        direction: 'desc'
                    }
                };
            }
        },
        [loadReferralDetails.fulfilled]: (state, action) => {
            const { referralId, details, total } = action.payload;
            state.detailsByReferral[referralId].loading = false;
            state.detailsByReferral[referralId].details = details;
            state.detailsByReferral[referralId].total = total;
        },
        [loadReferralDetails.rejected]: (state, action) => {
            const referralId = action.meta.arg;
            state.detailsByReferral[referralId].loading = false;
        }
    }
});

export const { actions, reducer } = referralDetailsSlice;

export default reducer;