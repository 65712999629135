import React, {useEffect} from 'react';
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Container,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';

import { loadChangeNotes } from "./changeNotesSlice";
import {useDispatch, useSelector} from "react-redux";
import {viewUpdateHistory} from "../user/userSlice";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(({
    header: {
        marginLeft: '10px',
        marginTop: '20px',
        marginBottom: '20px'
    },
    warning: {
        color: 'red'
    },
    loading: {
        margin: 'auto',
        display: 'block',
        marginTop: '10px'
    },
    containerRoot: {
        width: '100%',
        maxWidth: 1000
    },
    container: {
        marginBottom: '200px'
    }
}))

function ChangeNote() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.changeNote.loading);
    const { changeNotes } = useSelector(state => state.changeNote);
    const viewedUpdateHistoryOn = useSelector(state => state.auth.viewedUpdateHistoryOn);

    useEffect(() => {
        dispatch(loadChangeNotes());
        dispatch(viewUpdateHistory());
    }, [dispatch]);

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Typography variant="h4" className={classes.header} color="primary">
                Update History
            </Typography>
            {isLoading ? <CircularProgress className={classes.loading}/> :
                <>
                   <div className={classes.containerRoot}>
                       {!isLoading && changeNotes && changeNotes.map(changeNote => {
                           return (
                               <Accordion key={changeNote.id} defaultExpanded={true}>
                                   <AccordionSummary
                                       expandIcon={<ExpandMoreIcon />}
                                       id={changeNote.id}
                                   >
                                        <Typography variant="h6">
                                            {new Date(changeNote.effectiveDate).toDateString().substring(3) + " "}
                                            <span className={classes.warning}>
                                                {new Date(viewedUpdateHistoryOn) < new Date(changeNote.effectiveDate) ? "NEW" : ""}
                                            </span>
                                        </Typography>
                                   </AccordionSummary>
                                   <AccordionDetails style={{display:'block'}}>
                                        {changeNote.content.split("\n").map(line => {
                                            return (
                                                <Typography key={uuidv4()}>
                                                    &bull;&nbsp;{line}
                                                </Typography>
                                            )
                                        })}
                                   </AccordionDetails>
                               </Accordion>
                           )
                       })}
                   </div>
                </>}
        </Container>
    )
};

export { ChangeNote }