import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { doGet, doPost } from 'common/fetch';
import { getReadableSize, readFileAsync } from 'common/fileUtilities';

export const findReferrals = createAsyncThunk(
    'referrals/findRecent',
    async (_, { rejectWithValue }) => {
        try {
            const { data, response, error } = await doGet({
                path: "referral/findReferrals"
            });

            if (response.ok) {
                return {
                    referrals: data.data
                };
            }

            return rejectWithValue(error);
        }
        catch (error) {
            rejectWithValue(error);
        }
    }
)

export const createReferral = createAsyncThunk(
    'referral/create',
    async (referral, { rejectWithValue }) => {

        const phoneRegex = /[ ()-]/g; // get phone digits only
        referral.physicianOfficePhone = referral.physicianOfficePhone.replace(phoneRegex, '');
        referral.physicianFax = referral.physicianFax.replace(phoneRegex, '');
        referral.patientHomePhone = referral.patientHomePhone.replace(phoneRegex, '');
        referral.patientCellPhone = referral.patientCellPhone.replace(phoneRegex, '');
        referral.hospitalPhone = referral.hospitalPhone.replace(phoneRegex, '');
        referral.adjusterPhone = referral.adjusterPhone.replace(phoneRegex, '');
        // optionally displayed fields
        referral.requestorPhone = !referral.requestorPhone ? '' : referral.requestorPhone.replace(phoneRegex, '');
        
        if (referral.vendorLocation) {
            referral.vendorId = referral.vendorLocation.id
        }

        const { response, data } = await doPost({
            path: 'referral/create',
            params: referral,
            showErrorMsg: false
        });
        
        if (response.ok) {
            return {
                referral,
                ...data
            };
        } else if (data && data.exception === 'com.aiproject.core.ValidationException') {
            return rejectWithValue(data.message);
        } else {
            return rejectWithValue("We're sorry, there was a problem submitting your referral. Please call (855) 223-2228 for assistance.");
        }
    }
);

export const attachFile = createAsyncThunk(
    'referral/attach',
    async (file, { getState, rejectWithValue }) => {
        const { files } = getState().referral.forms.create;
        const {name, size, type} = file;
        if (files.find(f => f.name === name)) {
            return rejectWithValue(`${name} already attached`);
        } else if (size > (1024*1024*20)) {
            return rejectWithValue(`${name} over max size (20 MB)`);
        } else {
            return {
                id: uuidv4().replace(/-/g,''),
                name,
                length: size,
                contentType: type,
                size: getReadableSize(size),
                data: await readFileAsync(file)
            }
        }
    }
);

export const loadAttachmentTypes = createAsyncThunk(
    'referral/loadAttachmentTypes',
    async (_, { getState, rejectWithValue }) => {
        //console.log('loadAttachmentTypes');
        const { referral: { attachmentTypes }} = getState();
        if (attachmentTypes.length === 0) {
            const { data: types, response } = await doGet({
                path: 'referral/loadAttachmentTypes'
            });

            if (response.ok) {
                return types;
            }
            return rejectWithValue(false);
        }
        return Promise.resolve(attachmentTypes);
    }
);

export const referralSlice = createSlice({
    name: 'referral',
    initialState: {
        loading: true,
        referrals: [],
        error: false,
        attachmentTypes: [],
        attachmentTypesPending: false,
        forms: {
            upload: {
                missingTypes: []
            },
            create: {
                pending: false,
                success: false,
                error: false,
                files: [],
                fileErrors: [],
                filesPending: 0,
                newReferralId: '',
                rush: false
            }
        }
    },
    reducers: {
        resetForm: state => {
            state.forms.create = {
                pending: false,
                success: false,
                error: false,
                files: [],
                fileErrors: [],
                filesPending: 0
            }
        },
        removeFile: (state, action) => {
            const { files } = state.forms.create;
            const name = action.payload;
            state.forms.create.files = files.filter(f => f.name !== name);
        }
    },
    extraReducers: {
        [findReferrals.pending]: state => {
            state.loading = true;
        },
        [findReferrals.fulfilled]: (state, action) => {
            state.loading = false;
            state.referrals = action.payload.referrals;
        },
        [findReferrals.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error;
        },
        [createReferral.pending]: state => {
            state.forms.create.pending = true;
            state.forms.create.success = false;
            state.forms.create.error = false;
        },
        [createReferral.fulfilled]: (state, action) => {
            state.forms.create.pending = false;
            state.forms.create.success = true;
            state.forms.create.rush = action.payload.referral.rush;
            state.forms.create.newReferralId = action.payload.newReferralId;
        },
        [createReferral.rejected]: (state, action) => {
            state.forms.create.pending = false;
            state.forms.create.error = action.payload;
        },
        [attachFile.pending]: state => {
            ++state.forms.create.filesPending;
            state.forms.create.fileErrors = [];
        },
        [attachFile.fulfilled]: (state, action) => {
            --state.forms.create.filesPending;
            state.forms.create.files.push(action.payload);
        },
        [attachFile.rejected]: (state, action) => {
            --state.forms.create.filesPending;
            state.forms.create.fileErrors.push(action.payload);
        },
        [loadAttachmentTypes.pending]: state => {
            state.attachmentTypesPending = true;
        },
        [loadAttachmentTypes.fulfilled]: (state, action) => {
            state.attachmentTypesPending = false;
            state.attachmentTypes = action.payload;
        },
        [loadAttachmentTypes.rejected]: (state, action) => {
            state.attachmentTypesPending = false;
            state.attachmentTypes = [];
        },
    }
});

export const { actions, reducer } = referralSlice;
export default reducer;