import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { loadClaim } from '../claimsSlice';
import { Grid, Card, CardHeader, CardContent, Container, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';

import { ClaimOrders } from './ClaimOrders';
import { ClaimInfo } from '../ClaimInfo';

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white
    },
    text: {
        color: theme.typography.color
    },
    whiteText: {
        color: theme.colors.white
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold
    },
    cardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.colors.white,
    }
}));

function VendorClaimDetail() {
    const { claimId } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadClaim({ claimId, userType: 'Vendor' }));
    }, [dispatch, claimId]);

    const classes = useStyles();
    // const theme = useTheme();
    // const claimCollapsible = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    // history.listen(_ => dispatch(clearClaim()));
    const { claim, patientPhones, claimLoading } = useSelector(state => state.claims.claimDetail);
    const { orders, detailsByOrder, pending } = useSelector(state => state.order);
    
    const submitReferral = () => {
        history.push({
            pathname: '/referral/create',
            state: {
                claim
            }
        });
    };

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                {!claim && !claimLoading && 
                    <Grid item xs={12}>
                        <Alert severity="warning">Unable to load order information. Please call (855) 223-2228 for assistance.</Alert>
                    </Grid>
                }
                <Grid item md={3} xs={12}>
                    <ClaimInfo loading={claimLoading} claim={claim} patientPhones={patientPhones} />
                </Grid>
                <Grid item md={9} xs={12}>
                    <Card variant="outlined">
                        <CardHeader title="Orders" className={classes.header}>
                        </CardHeader>
                        <CardContent>
                            <Button style={{marginBottom: '10px'}} onClick={submitReferral} className={classes.button} startIcon={<SendIcon/>} disabled={claimLoading} variant="contained" color="secondary">
                                Submit New Referral
                            </Button>
                            <ClaimOrders orders={orders} pending={pending} detailsByOrder={detailsByOrder} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export { VendorClaimDetail };