import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ParadigmTopSearch } from './ParadigmTopSearch';

import './ParadigmNavbar.css';

// map between HTML IDs of menu headers and the keys used between this and
//    the ParadigmWideDetailedMenu component (as showFlag).
const menuMap = new Map([
    ['catastrophic_header_trigger', 'cat'],
    ['complex_header_trigger', 'case'],
    ['specialty_header_trigger', 'specialty'],
    ['care_at_home_trigger','care']
]);


function ParadigmSideMenu({show, showDetailsFor}) {
    const open = show ? 'open' : '' ;
    const [activeMenu, SetActiveMenu] = useState('');
    const theme = useTheme();
    const isBelow768 = useMediaQuery(theme.breakpoints.down(767));
    const is1200AndUp = useMediaQuery(theme.breakpoints.up(1200));

    const toggleMenuItemVis = (el, mode) => {
        const dropDown = el.closest('.dropdown');
        const menu = dropDown.querySelector('.dropdown-menu');
        if (mode === 'show' ) {
            dropDown.classList.add('show');
            menu.classList.add('show');
        } else {
            menu.classList.remove('show');
            dropDown.classList.remove('show');
        }
    };

    const handleMouseEnterSideMenu =(e) => {
        toggleMenuItemVis(e.target, 'show');
    };
    const handleMouseLeaveSideMenu = (e) => {
        toggleMenuItemVis(e.target, 'hide');
    };
    
    const removeActive = (el) => {
        const activeEl = el.closest('#division_header_menu').querySelector('.division_link.active');
        if (activeEl) {
            activeEl.classList.remove('active');
        }
    };

    const handleMouseEnterDivisionMenu = (e) => {
        const el = e.target;
        
        removeActive(el);
        if (el) {
            const dl = el.closest('.division_link');
            const id = dl.id;
            
            if (id && id.length > 1) {
                showDetailsFor(menuMap.get(id));
                dl.classList.add('active');
            }
        }
        
    }
    const handleMouseLeaveDivisionMenu = (e) => {
        removeActive(e.target);
   }

    return (
<>
    
    <div className={`no-padding navbar-collapse offcanvas-collapse ${open}`} id="navbarsExampleDefault">
            {isBelow768 &&
            <div  className="side-search-container navbar-nav col-md-12">
                    <ParadigmTopSearch orderFor="side" />
                </div>
            }
        <ul id="mobile_division_menus_old" className="navbar-nav" >
            <li className="nav-item dropdown" 
                    onMouseEnter={handleMouseEnterSideMenu} onMouseLeave={handleMouseLeaveSideMenu} >
                <a className="nav-link dropdown-toggle" id="navbarDropdown" 
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >Solutions</a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item mobile_division_header" href="https://www.paradigmcorp.com/catastrophic-care-management">
                        Catastrophic &amp; Risk Solution
                    </a>
                    <div className="mobile_division_description">
                        Care management for a wide range of injuries, delivering guaranteed outcomes at a fixed price.
                    </div>
                    <a className="dropdown-item mobile_division_header" href="https://www.paradigmcorp.com/complex-care-management">
                        Case Management Solutions
                    </a>
                    <div className="mobile_division_description">
                        Nationwide quality case management solutions.
                    </div>
                    <a className="dropdown-item mobile_division_header" href="https://www.paradigmcorp.com/specialty-networks">
                        Specialty Networks
                    </a>
                    <div className="mobile_division_description">
                        Specialty-focused network and cost containment solutions for high-acuity injuries.
                    </div>
                    <a className="dropdown-item mobile_division_header" href="https://www.paradigmcorp.com/care-at-home">
                        Care at Home
                    </a>
                    <div className="mobile_division_description">
                        Clinically driven home health services to ensure continuity of care and achieve better outcomes.
                    </div>
                </div>
            </li>
        </ul>

        <ul id="menu-top-header-menu" className="navbar-nav col-md-12">
            <li className="menu-item-has-children nav-item dropdown" onMouseEnter={handleMouseEnterSideMenu} onMouseLeave={handleMouseLeaveSideMenu} >
              <a className="nav-link dropdown-toggle" id="navbar-dropdown-menu-link-390" 
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About</a>
              <div className="sub-menu dropdown-menu" aria-labelledby="navbar-dropdown-menu-link-390">
                <a href="https://www.paradigmcorp.com/about" className="dropdown-item" id="menu-item-442">Overview</a>
                <a href="https://www.paradigmcorp.com/clinical-partnerships/" className="dropdown-item" id="menu-item-5896">Clinical Partnerships</a>
                <a href="https://www.paradigmcorp.com/about/industries/" className="dropdown-item" id="menu-item-6550">Industries</a>
                <a href="https://www.paradigmcorp.com/leadership/" className="dropdown-item" id="menu-item-662">Leadership</a>
                <a href="https://www.paradigmcorp.com/community" className="dropdown-item" id="menu-item-663">Community</a>
                <a href="https://www.paradigmcorp.com/pressreleases/" className="dropdown-item" id="menu-item-4275">Press Releases</a>
                <a href="https://www.paradigmcorp.com/news/" className="dropdown-item" id="menu-item-4274">In the News</a>
              </div>
            </li>
            <li className="menu-item-has-children nav-item dropdown" onMouseEnter={handleMouseEnterSideMenu} onMouseLeave={handleMouseLeaveSideMenu} >
              <a className="nav-link dropdown-toggle" id="navbar-dropdown-menu-link-391"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Resources</a>
              <div className="sub-menu dropdown-menu" aria-labelledby="navbar-dropdown-menu-link-391">
                <a href="https://www.paradigmcorp.com/covid-19-resources/" className="dropdown-item" id="menu-item-5465">COVID Resources</a>
                <a href="https://www.paradigmcorp.com/resources" className="dropdown-item" id="menu-item-666">Featured</a>
                <a href="https://www.paradigmcorp.com/webinars" className="dropdown-item" id="menu-item-667">Webinars</a>
                <a href="https://www.paradigmcorp.com/insights" className="dropdown-item" id="menu-item-669">Insights</a>
                <a href="https://www.paradigmcorp.com/casestudies" className="dropdown-item" id="menu-item-668">Case Studies</a>
                <a href="https://www.paradigmcorp.com/position-papers" className="dropdown-item" id="menu-item-2919">Position Papers</a>
              </div>
            </li>
            <li className="nav-item show">
              <a href="https://www.paradigmcorp.com/careers" className="nav-link">Careers</a>
            </li>
            <li className="nav-item show">
              <a href="https://www.paradigmcorp.com/specialty-networks/providers" className="nav-link">Providers</a>
            </li>
            {is1200AndUp &&
                <li className="nav-item show">
                    <div className='top-wide-search-container'><ParadigmTopSearch orderFor="wide" /></div>
                </li>
            }
        </ul>

        <ul id="division_header_menu" className="navbar-nav col-md-12 no-padding"
                onMouseOver={handleMouseEnterDivisionMenu} onMouseLeave={handleMouseLeaveDivisionMenu} >
            <li id="catastrophic_header_trigger" className="division_link">
              <a className="nav-link">Catastrophic &amp; Risk<br />Solutions</a>
            </li>
            <li id="complex_header_trigger" className="division_link">
              <a className="nav-link">Case Management<br />Solutions</a>
            </li>
            <li id="specialty_header_trigger" className="division_link">
              <a className="nav-link">Specialty <br />Networks</a>
            </li>
            <li id="care_at_home_trigger" className="division_link">
              <a className="nav-link">Care <br />at Home</a>
            </li>
        </ul>
    </div>
</>

        
    );
}

export { ParadigmSideMenu } ;