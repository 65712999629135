import React from 'react';
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    footer: {
        display: 'block',
        width: '100%',
        margin: '0',
        padding: '45px 0',
        color: '#212529',
        backgroundColor: '#dcdcdc',
        fontFamily: 'Scandia, Arial, sans-serif',
        '@media print': {
            display: 'none',
        }
    },
    footerContainer: {
        margin: '0 auto 0 auto',
    },
    footerNav: {
        
    },
    footerTitle: {
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '500',
    },
    footerList: {
        listStyle: 'none',
        padding: '0',
    },
    navItem: {
        fontSize: '18px',
        color: '#7f7f7f',
        letterSpacing: '0',
        padding: '0',
        fontFamily: 'Scandia Regular, Scandia, Arial, sans-serfif',
        lineHeight: '1.8em',
    },
    navLink: {
        display: 'block',
        textDecoration: 'none',
        fontSize: '14px',
        color: '#7f7f7f',
        letterSpacing: '0',
        padding: '0',
        '&:hover': {
            color: '#0a58ca',
        },
        '&:focus': {
            color: '#0a58ca',
        },
    },
    mlAuto: {
        marginLeft: 'auto !important',
    },
    copyright: {
        color: '#999',
        fontSize: '12px',
        fontWeight: '500',
        margin: '0 0 20px 0',
    },
    legal: {
        color: '#999',
        textTransform: 'uppercase',
    },
    year: {

    },
    socialIcons: {
        textDecoration: 'none',
    },
    footerMediaLink: {
        marginLeft: '15px',
    },
    footerMediaIcon: {
        height: '25px',
    }
}));

function ParadigmFooter() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="md">
            <Grid container className={classes.footerContainer} >
                <Grid item xs={12} sm={4} className={classes.footerNav} >
                    <div className={classes.footerTitle} >
                        Solutions
                    </div>
                    <ul className={classes.footerList} >
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/catastrophic-care-management/" className={classes.navLink} >Catastrophic &amp; Risk Solutions</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/complex-care-solutions/" className={classes.navLink} >Case Management Solutions</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/specialty-networks/" className={classes.navLink} >Specialty Networks</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/care-at-home/" className={classes.navLink} >Care at Home</a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.footerNav} >
                    <div className={classes.footerTitle} >
                        About
                    </div>
                    <ul id="footer-menu-2" className={classes.footerList} >
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/about" className={classes.navLink} >Overview</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/clinical-partnerships/" className={classes.navLink} >Clinical Partnerships</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/about/industries/" className={classes.navLink} >Industries</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/leadership/" className={classes.navLink} >Leadership</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/community" className={classes.navLink} >Community</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/pressreleases" className={classes.navLink} >Press Releases</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/news" className={classes.navLink} >In the News </a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.footerNav} >
                    <div className={classes.footerTitle} >
                        Resources
                    </div>
                    <ul id="footer-menu-3" className={classes.footerList} >
                        <li id="menu-item-385" className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/covid-19-resources/" className={classes.navLink} >COVID Resources</a>
                        </li>
                        <li id="menu-item-385" className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/resources" className={classes.navLink} >Featured</a>
                        </li>
                        <li id="menu-item-680" className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/webinars" className={classes.navLink} >Webinars</a>
                        </li>
                        <li id="menu-item-383" className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/insights" className={classes.navLink} >Insights</a>
                        </li>
                        <li id="menu-item-384" className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/casestudies" className={classes.navLink} >Case Studies</a>
                        </li>
                        <li id="menu-item-384" className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/position-papers" className={classes.navLink} >Position Papers</a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={10} sm={2} className={classes.footerNav} >
                    <div className={classes.footerTitle} >
                        Information
                    </div>
                    <ul id="footer-menu-4" className={classes.footerList} >
                        <li className={classes.navItem} >
                        <a href="/" className={classes.navLink} >Refer a Case</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/careers" className={classes.navLink} >Careers</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/specialty-networks/providers" className={classes.navLink} >Providers</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/contact" className={classes.navLink} >Contact</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://www.paradigmcorp.com/faq/" className={classes.navLink} >FAQs</a>
                        </li>
                        <li className={classes.navItem} >
                        <a href="https://info.paradigmcorp.com/email-signup" className={classes.navLink} >Email Signup</a>
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Grid container className={classes.footerContainer} >
                <Grid id="copyright" item xs={9} className={classes.copyright} >
                    <a id="legal" className={classes.legal} href="https://www.paradigmcorp.com/legal-privacy-policy">Legal &amp; Privacy</a>
                    <p id="copyright-text">© <span id="year" className={classes.year} >2023</span> Paradigm. All rights reserved</p>
                </Grid>
                <Grid item xs={9} id="social_icons" className={classes.socialIcons} >
                    <div>
                        <a target="_blank" rel="noopener" href="https://www.facebook.com/ParadigmCorporate/">
                            <img alt="Paradigm Corp Facebook" className={classes.footerMediaIcon} 
                                src="/fb-icon.png" />
                        </a>
                        <a target="_blank" rel="noopener" href="https://twitter.com/paradigmsays" className={classes.footerMediaLink} >
                            <img alt="Paradigm Corp Twitter" className={classes.footerMediaIcon}  
                                src="/twitter-icon.png" />
                        </a>
                        <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/paradigmcorp/" className={classes.footerMediaLink} >
                            <img alt="Paradigm Corp LinkedIn" className={classes.footerMediaIcon}  
                                src="/linkedin-icon.png" />
                        </a>
                        <a target="_blank" rel="noopener" href="https://www.youtube.com/user/ParadigmOutcomes" className={classes.footerMediaLink} >
                            <img alt="Paradigm Corp YouTube" className={classes.footerMediaIcon}  
                                src="/youtube-icon.svg" />
                        </a>
                    </div>
                </Grid>
            </Grid>
            </Container>
        </footer>
    );
}
export { ParadigmFooter };
